import gql from 'graphql-tag';
import { Log } from 'qc-log_api';

import loginMutation from '../../queries/loginMutation';

import { normalizeActionResult, pushUnexpectedError } from './lib/ActionUtils';

// import { loadAccount } from './AccountActions';

// import {
//   EMAIL_VERIFICATION_START,
//   EMAIL_VERIFICATION_SUCCESS,
//   EMAIL_VERIFICATION_ERROR,
// } from './';

const ACTIONS_LOG = Log.Factory.get('users.anonymous.actions');

function login(email, password) {
  /**
   * @returns {Promise<ActionResult, undefined>}
   */
  return async (dispatch, getState, { graphqlClient }) => {
    const actionResult = {
      status: 'invalid',
    };

    try {
      const variables = { email, password };
      const { data } = await graphqlClient.mutate({
        mutation: loginMutation,
        variables,
      });
      if (data) {
        actionResult.status = data.login.status;
      }
    } catch (e) {
      ACTIONS_LOG.error(e);
      pushUnexpectedError(actionResult);
    }

    normalizeActionResult(actionResult);

    if (actionResult.status === 'success') {
      await graphqlClient.resetStore();
    }

    return actionResult;
  };
}

const registerMutation = gql`
  mutation register(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $dob: DateOnlyInput!
  ) {
    register(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      dob: $dob
    ) {
      emailToken
      status
    }
  }
`;

/**
 * @param {Object} registrationInfo
 * @param {Object} registrationInfo.dob
 * @param {number} registrationInfo.dob.day
 * @param {number} registrationInfo.dob.month
 * @param {number} registrationInfo.dob.year
 * @param {string} registrationInfo.email
 * @param {string} registrationInfo.firstName
 * @param {string} registrationInfo.lastName
 * @param {string} registrationInfo.password
 *
 * @returns {Object}
 * @property {Error[]|null} errors
 * @property {'failed'|} status
 */
function register(registrationInfo) {
  /**
   * @returns {Promise<ActionResult, undefined>}
   */
  return async (dispatch, getState, { graphqlClient }) => {
    const actionResult = {
      status: 'failed',
    };

    try {
      const { data } = await graphqlClient.mutate({
        mutation: registerMutation,
        variables: registrationInfo,
      });
      if (data) {
        actionResult.data = { emailToken: data.register.emailToken };
        actionResult.status = data.register.status;
      }
    } catch (e) {
      ACTIONS_LOG.error(e);
      pushUnexpectedError(actionResult);
    }

    normalizeActionResult(actionResult);

    return actionResult;
  };
}

const resendVerifyEmailMutation = gql`
  mutation resendVerifyEmail {
    resendVerifyEmail {
      status
    }
  }
`;

function resendVerifyEmail() {
  /**
   * @returns {Promise<ActionResult, undefined>}
   */
  return async (dispatch, getState, { graphqlClient }) => {
    const actionResult = {
      status: 'failed',
    };

    try {
      const { data } = await graphqlClient.mutate({
        mutation: resendVerifyEmailMutation,
      });
      if (data) {
        actionResult.status = data.resendVerifyEmail.status;
      }
    } catch (e) {
      ACTIONS_LOG.error(e);
      pushUnexpectedError(actionResult);
    }

    normalizeActionResult(actionResult);

    return actionResult;
  };
}

const resetPasswordMutation = gql`
  mutation resetPassword($token: String!) {
    resetPassword(token: $token) {
      newPassword
      status
    }
  }
`;

/**
 * @param {string} token
 */
function resetPassword(token) {
  /**
   * @returns {Promise<ActionResult, undefined>}
   * @property {ActionResult} result
   * @property {Error[]|null} result.errors
   * @property {null|string} result.newPassword
   * @property {'failed'|'invalid'|'success'} result.status
   */
  return async (dispatch, getState, { graphqlClient }) => {
    const actionResult = {
      status: 'failed',
    };

    if (token) {
      try {
        const { data } = await graphqlClient.mutate({
          mutation: resetPasswordMutation,
          variables: { token },
        });
        if (data) {
          const { newPassword, status } = data.resetPassword;
          actionResult.data = { newPassword };
          actionResult.status = status;
        }
      } catch (e) {
        ACTIONS_LOG.error(e);
        pushUnexpectedError(actionResult);
      }
    }

    normalizeActionResult(actionResult);

    return actionResult;
  };
}

const sendForgotPasswordEmailMutation = gql`
  mutation sendForgotPasswordEmail($email: String!) {
    sendForgotPasswordEmail(email: $email) {
      status
    }
  }
`;

/**
 * @param {Object} resetPasswordRequest
 * @param {string} resetPasswordRequest.email
 */
function sendForgotPasswordEmail(resetPasswordRequest) {
  /**
   * @returns {Promise<ActionResult, undefined>}
   * @property {ActionResult} result
   * @property {Error[]|null} result.errors
   * @property {'failed'|'success'|'unregistered'} result.status
   */
  return async (dispatch, getState, { graphqlClient }) => {
    const actionResult = {
      status: 'failed',
    };

    try {
      const { data } = await graphqlClient.mutate({
        mutation: sendForgotPasswordEmailMutation,
        variables: resetPasswordRequest,
      });
      if (data) {
        actionResult.status = data.sendForgotPasswordEmail.status;
      }
    } catch (e) {
      ACTIONS_LOG.error(e);
      pushUnexpectedError(actionResult);
    }

    normalizeActionResult(actionResult);

    return actionResult;
  };
}

// export function verifyEmail(token) {
//   return async (dispatch, getState, { graphqlClient }) => {
//     dispatch({ type: EMAIL_VERIFICATION_START });

//     const emailQuery = gql`
//       query verifyEmail($token: String!) {
//         verifyEmail(token: $token) {
//           status
//         }
//       }
//     `;

//     try {
//       const { data } = await graphqlClient.query({
//         query: emailQuery,
//         variables: { token },
//       });

//       if (data.verifyEmail.status === 'success') {
//         dispatch({ type: EMAIL_VERIFICATION_SUCCESS });
//         await dispatch(loadAccount());
//       }
//     } catch (error) {
//       dispatch({
//         type: EMAIL_VERIFICATION_ERROR,
//         payload: {
//           error,
//         },
//       });
//     }
//   };
// }

export {
  login,
  register,
  resendVerifyEmail,
  resetPassword,
  sendForgotPasswordEmail,
};

const AuthenticatedUserActions = {
  login,
  register,
  resendVerifyEmail,
  resetPassword,
  sendForgotPasswordEmail,
};

export default AuthenticatedUserActions;
