import { createPath } from 'history';
import queryString from 'query-string';
import React from 'react';
import ReactDOM from 'react-dom';

import App from '../universal/components/App';

import createApolloClient from '../universal/lib/core/createApolloClient';

import router from '../universal/router';

import configureStore from '../universal/state/configureStore';

import { clearFindShadowingsQueryCache } from '../universal/state/apollo/clearCache';
import { timestampCache } from '../universal/state/apollo/timestampCache';

import history from './history';
import { updateMeta } from './DOMUtils';

const apolloClient = createApolloClient();

(() => {
  setInterval(() => {
    timestampCache(apolloClient);
  }, 10 * 1000);

  setInterval(() => {
    // Clear out the cache of the shadowing query every so often so that we
    // refetch fresh information.
    clearFindShadowingsQueryCache(apolloClient);
  }, 63 * 1000); // Run about once a minute.
})();

if ('serviceWorker' in navigator) {
  // Use the window load event to keep the page load performant
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('service-worker.js');
  });
}

const store = configureStore(window.App.state, { apolloClient });

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const context = {
  // baseUrl: undefined, // Injected in by Universal Router.

  graphqlClient: apolloClient,

  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: (...styles) => {
    const removeCss = styles.map(x => x._insertCss());
    return () => {
      removeCss.forEach(f => f());
    };
  },

  // keys: undefined, // Injected in by Universal Router.

  locale: store.getState().intl.locale,

  // next: undefined, // Injected in by Universal Router.

  // params: {}, // Injected in by Universal Router.

  // path: undefined, // Injected in by Universal Router.

  // The current URL path name.  The same as `location.pathname`.  It is updated
  // on initial load and after each location change. This is updated after each
  // location change.
  pathname: '',

  // The URL query parameters as an object.  It is updated on initial load and
  // after each location change. This is updated after each location change.
  query: {},

  // route: undefined, // Injected in by Universal Router.

  // router: undefined, // Injected in by Universal Router.

  // The Redux store.
  store,
};

const scrollPositionsHistory = {};

function restoreScrollPosition(location) {
  let scrollX = 0;
  let scrollY = 0;
  const pos = scrollPositionsHistory[location.key];
  if (pos) {
    scrollX = pos.scrollX;
    scrollY = pos.scrollY;
  } else {
    const targetHash = location.hash.substr(1);
    if (targetHash) {
      const target = document.getElementById(targetHash);
      if (target) {
        scrollY = window.pageYOffset + target.getBoundingClientRect().top;
      }
    }
  }

  // Restore the scroll position if it was saved into the state
  // or scroll to the given #hash anchor
  // or scroll to top of the page
  window.scrollTo(scrollX, scrollY);
}

const container = document.getElementById('app');
let currentLocation = history.location;

// Re-render the app when window.location changes
async function onLocationChange(location, action) {
  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    delete scrollPositionsHistory[location.key];
  }
  currentLocation = location;

  const isInitialRender = !action;
  try {
    context.pathname = location.pathname;
    context.query = queryString.parse(location.search);

    // Traverses the list of routes in the order they are defined until it finds the
    // first route that matches the provided URL path string **and** whose `action`
    // method returns anything other than `null` or `undefined`.
    const actionSpec = await router.resolve(context);

    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      return;
    }

    if (actionSpec.redirect) {
      history.replace(actionSpec.redirect);
      return;
    }

    const renderReactApp = isInitialRender ? ReactDOM.hydrate : ReactDOM.render;
    if (!actionSpec.component) {
      console.warn('actionSpec.component is falsy');
      // eslint-disable-next-line no-console
      console.log('actionSpec:', actionSpec);
    }

    renderReactApp(
      <App context={context}>{actionSpec.component}</App>,
      container,
      () => {
        if (isInitialRender) {
          // Switch off the native scroll restoration behavior and handle it manually
          // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
          if (window.history && 'scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
          }

          const elem = document.getElementById('css');
          if (elem) elem.parentNode.removeChild(elem);
          return;
        }

        document.title = actionSpec.title;

        updateMeta('description', actionSpec.description);
        // Update necessary tags in <head> at runtime here, ie:
        // updateMeta('keywords', actionSpec.keywords);
        // updateCustomMeta('og:url', actionSpec.canonicalUrl);
        // updateCustomMeta('og:image', actionSpec.imageUrl);
        // updateLink('canonical', actionSpec.canonicalUrl);
        // etc.

        restoreScrollPosition(location);

        // Google Analytics tracking. Don't send 'pageview' event after
        // the initial rendering, as it was already sent
        if (window.ga) {
          window.ga('send', 'pageview', createPath(location));
        }
      },
    );
  } catch (error) {
    if (__DEV__) {
      throw error;
    }

    console.error(error);

    // Do a full page reload if error occurs during client-side navigation
    if (!isInitialRender && currentLocation.key === location.key) {
      console.error('RSK will reload your page after error');
      window.location.reload();
    }
  }
}

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
history.listen(onLocationChange);
onLocationChange(currentLocation);
