/*
Works in conjunction with timestampCache.js.
*/

const FindShadowingsQueryIdPattern = /\$ROOT_QUERY\.findExpertProfiles\({"order":\[{"dir":"asc","field":"name"}],"predicates":{.*}}\)/;

export const clearFindShadowingsQueryCache = apolloClient => {
  // console.log('clearFindShadowingsQueryCache');
  const { cache } = apolloClient;

  const { data } = cache.data;
  const rootQuery = data.ROOT_QUERY;
  if (rootQuery) {
    const ttl = 4 * 60 * 1000; // 4 minutes
    const now = Date.now();
    Object.entries(rootQuery).forEach(entry => {
      const queryKey = entry[0];
      const meta = entry[1];
      if (
        meta.generated &&
        meta.typename === 'FindExpertProfilesResult' &&
        // eslint-disable-next-line no-underscore-dangle
        now - meta.__timestamp > ttl &&
        FindShadowingsQueryIdPattern.test(meta.id)
      ) {
        Object.keys(data).forEach(dataKey => {
          if (dataKey.startsWith(meta.id)) {
            // console.log('Deleting:', meta.id);
            cache.data.delete(dataKey);
          }
        });
        delete rootQuery[queryKey];
      }
    });
  }
};
