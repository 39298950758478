import {
  LOGIN_MODAL__UPDATE,
  OPEN_NEW_MESSAGE_MODAL,
  CLOSE_NEW_MESSAGE_MODAL,
  OPEN_LOGIN_MODAL,
  CLOSE_LOGIN_MODAL,
  OPEN_SIGNUP_MODAL,
  CLOSE_SIGNUP_MODAL,
  OPEN_DELETE_PROFILE_MODAL,
  CLOSE_DELETE_PROFILE_MODAL,
} from '../actions';

/*::
type ModalsState = {
  login: {
    redirect: string
    show: boolean
  }
  isNewMessageModalOpen: boolean
  isSignupModalOpen: boolean
}
*/

const closeAll = {
  isNewMessageModalOpen: false,
  isSignupModalOpen: false,
};

const initState = {
  login: {
    message: /*: React.Element | number | string */ null,
    redirect: /*: string */ null,
    render: false,
    show: false,
  },
  ...closeAll,
};

export default function modals(state = initState, action) {
  switch (action.type) {
    case OPEN_NEW_MESSAGE_MODAL:
      return {
        ...state,
        ...closeAll,
        login: {
          ...state.login,
          show: false,
        },
        isNewMessageModalOpen: true,
      };

    case CLOSE_NEW_MESSAGE_MODAL:
      return {
        ...state,
        isNewMessageModalOpen: false,
      };

    case LOGIN_MODAL__UPDATE:
      return {
        ...state,
        login: {
          ...state.login,
          ...action.payload,
        },
      };

    case OPEN_LOGIN_MODAL:
      return {
        ...state,
        ...closeAll,
        login: {
          ...state.login,
          ...action.payload,
          render: true,
          show: true,
        },
      };

    case CLOSE_LOGIN_MODAL:
      return {
        ...state,
        login: {
          ...state.login,
          message: null,
          redirect: null,
          render: false,
          show: false,
        },
      };

    case OPEN_SIGNUP_MODAL:
      return {
        ...state,
        ...closeAll,
        login: {
          ...state.login,
          show: false,
        },
        isSignupModalOpen: true,
      };

    case CLOSE_SIGNUP_MODAL:
      return {
        ...state,
        isSignupModalOpen: false,
      };

    case OPEN_DELETE_PROFILE_MODAL:
      return {
        ...state,
        isDeleteProfileModalOpen: true,
      };

    case CLOSE_DELETE_PROFILE_MODAL:
      return {
        ...state,
        isDeleteProfileModalOpen: false,
      };

    default:
      return {
        ...state,
      };
  }
}
