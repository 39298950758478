exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1MSB2{color:inherit}._1crqp{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._1crqp div{width:100%}._1crqp div button{display:inline-block;position:relative;overflow:hidden;text-decoration:none;outline:none;color:var(--neutral--500);color:var(--neutral--500);width:100%;text-align:center;padding-top:10px}._1crqp div button span{width:100%;padding:20px 0;color:var(--primary--400);color:var(--primary--400)}._1crqp div button::after{content:\"Log in\";position:absolute;top:10px;left:0;width:100%;opacity:0;text-align:center;color:var(--primary--400);color:var(--primary--400)}._1crqp div:hover button{text-decoration:none}._1crqp div:hover button span{opacity:0}._1crqp div:hover button::after{opacity:1}.cVwnp{display:block;-webkit-box-sizing:border-box;box-sizing:border-box;margin:0;padding:20px 16px;width:100%;outline:0;border:1px solid #373277;border-radius:4px;background:#373277;color:#fff;text-align:center;text-decoration:none;font-size:16px;line-height:1.3333333;cursor:pointer;white-space:normal}.cVwnp:hover{color:#fff}._2yE11{text-align:center}._2yE11 ._31dJ8{border:0;background:rgba(0,0,0,0);display:block;margin:0 auto;color:var(--primary--400);color:var(--primary--400)}.vUu-J{border-color:var(--link--color);border-color:var(--link--color);background-color:var(--primary--400);background-color:var(--primary--400);color:#fff}._3DMWU{margin:0 auto;display:block}._3caTf{font-size:16px;color:var(--neutral--800);color:var(--neutral--800);text-align:center;margin:15px 0}", ""]);

// exports
exports.locals = {
	"modal": "_1MSB2",
	"formGroup": "_1crqp",
	"button": "cVwnp",
	"footerContent": "_2yE11",
	"logInButton": "_31dJ8",
	"btnPrimary": "vUu-J",
	"resendButton": "_3DMWU",
	"captionText": "_3caTf"
};