import PTs from 'prop-types';
import React from 'react';
import Alert from 'react-bootstrap/lib/Alert';

/**
 * Renders a localized success message.
 */
function SuccessBox(props) {
  /*
  Implementation Note: We are using a `react-bootstrap` `Alert` component to
  implement this success box. But this can be changed to whatever works best.
  */
  return <Alert bsStyle="success">{props.children}</Alert>;
}

SuccessBox.propTypes = {
  children: PTs.node.isRequired,
};

export default SuccessBox;
