import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import {
  hasLenInRange,
  isDate,
  isDateOnlyBetween,
  isEmail,
  isInteger,
  isNumBetween,
  isMarkdown,
  isNumber,
  isRequired,
  isSafeText,
  isStrongPassword,
  isUsPhone10,
  isZipCode,
  isZipCode5,
  isZipCodePlus4,
  matches,
} from '../../lib/validators';

import { getDateFormat, SUPPORTED_LOCALES } from '../../locale';

import InputFieldGroup from './InputFieldGroup';
import { VALID_FIELD_TYPES } from './Types';

import s from './FieldGroup.scss';

/**
 * This is basically a wrapper around the `redux-form` `Field` component that
 * automatically adds reasonable validators based off the specified `type` and
 * properties including `max`, `maxLength`, `min`, `minLength`, `pattern`, and
 * `required`.
 */
function FieldGroup(props) {
  // console.log('FieldGroup');
  // console.log('props:', props);
  const {
    className,
    customErrId,
    currency,
    locale,
    max,
    maxLength,
    min,
    minLength,
    pattern,
    required,
    type,
    validators,
    ...otherFieldProps
  } = props;

  let dateFormat;
  if (type === 'date') {
    dateFormat = getDateFormat(locale || SUPPORTED_LOCALES[0]);
  }
  const validate = [];
  if (required) {
    validate.push(isRequired);
  }
  validate.concat(validators || []);
  switch (type) {
    case 'checkbox':
      break;
    case 'color':
      break;
    case 'date':
      validate.push(isDate(dateFormat));
      validate.push(isDateOnlyBetween(min, max, dateFormat));
      break;
    case 'email':
      validate.push(isEmail);
      break;
    case 'file':
      break;
    case 'hidden':
      break;
    case 'rater':
    case 'integer':
      validate.push(isInteger);
      validate.push(isNumBetween(min, max));
      break;
    case 'markdown':
      validate.push(hasLenInRange(minLength, maxLength));
      validate.push(isMarkdown);
      validate.push(isSafeText);
      break;
    case 'month':
      break;
    case 'number':
      validate.push(isNumber);
      validate.push(isNumBetween(min, max));
      break;
    case 'password':
      validate.push(hasLenInRange(minLength, maxLength));
      validate.push(isStrongPassword);
      break;
    case 'radio':
      break;
    case 'range':
      break;
    case 'search':
      validate.push(hasLenInRange(minLength, maxLength));
      validate.push(isSafeText);
      break;
    case 'select':
      break;
    case 'tel':
      break;
    case 'text':
    case 'textarea':
      validate.push(hasLenInRange(minLength, maxLength));
      validate.push(isSafeText);
      break;
    case 'time':
      break;
    case 'url':
      break;
    case 'us-phone-10':
      validate.push(isUsPhone10);
      break;
    case 'week':
      break;
    case 'zip-code':
      validate.push(isZipCode);
      break;
    case 'zip-code-5':
      validate.push(isZipCode5);
      break;
    case 'zip-code-plus-4':
      validate.push(isZipCodePlus4);
      break;
    default:
  }
  validate.push(matches(pattern, customErrId));

  return (
    <Field
      className={cx(className, s.root, currency && s.currencySymbol)}
      max={max}
      maxLength={maxLength}
      min={min}
      minLength={minLength}
      pattern={pattern}
      required={required}
      type={type}
      validate={validate}
      {...otherFieldProps}
    />
  );
}

FieldGroup.propTypes = {
  className: PTs.string,
  component: PTs.oneOfType([
    PTs.oneOf(['input', 'select', 'textarea']).isRequired,
    PTs.func.isRequired,
  ]),
  currency: PTs.bool, // bool?
  customErrId: PTs.string,
  locale: PTs.oneOf(SUPPORTED_LOCALES),
  max: PTs.number,
  maxLength: PTs.number,
  min: PTs.number,
  minLength: PTs.number,
  pattern: PTs.string,
  required: PTs.bool,
  type: PTs.oneOf(VALID_FIELD_TYPES).isRequired,
  validators: PTs.arrayOf(PTs.func.isRequired),
};

FieldGroup.defaultProps = {
  className: undefined,
  component: InputFieldGroup,
  currency: undefined,
  customErrId: undefined,
  locale: SUPPORTED_LOCALES[0],
  max: undefined,
  maxLength: undefined,
  min: undefined,
  minLength: undefined,
  pattern: undefined,
  required: false,
  validators: undefined,
};

export default withStyles(s)(FieldGroup);
