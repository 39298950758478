import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import PropTypes, { withPropDocs } from 'prop-types-docs';
import React from 'react';
import { components as ReactSelect } from 'react-select';

import GoogleGeocodeSelect from '../GoogleGeocode/GoogleGeocodeSelect';

import s from './GoogleGeocodeInput.scss';

const Control = withPropDocs({
  props: {
    inputClassName: {
      type: PropTypes.string,
      description: 'My inputClassName description',
    },
  },
})(props => {
  const { inputClassName, ...restProps } = props;
  return (
    <ReactSelect.Control
      className={cx(inputClassName, 'form-control')}
      {...restProps}
    />
  );
});

class GoogleGeocodeInput extends React.Component {
  static propTypes = {
    disabled: PTs.bool.isRequired,
    name: PTs.string.isRequired,
    onChange: PTs.func.isRequired,
  };

  handleSelect = googleGeocodeResult => {
    // console.log('GoogleGeocodeInput#handleSelect');
    // console.log('googleGeocodeResult:', googleGeocodeResult);

    this.props.onChange(googleGeocodeResult);
  };

  render() {
    // console.log('GoogleGeocodeInput#render');
    const { disabled, name, ...restProps } = this.props;
    delete restProps.onChange;
    delete restProps.onDragStart;
    delete restProps.onDrop;

    // console.log('restProps:', restProps);
    return (
      <React.Fragment>
        {/*
        Using our own input element. I forget why this was better than just passing
        `name` to `GoogleGeocodeSelect`.
        */}
        <input name={name} type="hidden" />
        <GoogleGeocodeSelect
          cacheOptions
          className={cx(s.root)}
          components={{ Control }}
          isDisabled={disabled}
          {...restProps}
          onSelect={this.handleSelect}
          // menuIsOpen // Enable this prop to see menu in dev tools.
        />
      </React.Fragment>
    );
  }
}

export default withStyles(s)(GoogleGeocodeInput);
