import { SET_PERSIST_VAL, TOGGLE_PERSIST_MISC } from '../actions';

/*::
type MiscPersist = {
}
*/

export default function misc(state = {}, action) {
  switch (action.type) {
    case SET_PERSIST_VAL:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case TOGGLE_PERSIST_MISC:
      return {
        ...state,
        [action.payload]: !state[action.payload],
      };
    default:
      break;
  }
  return state;
}
