import cx from 'classnames';
import PTs from 'prop-types';
import React from 'react';

import history from '../../../client/history';

import config from '../../config/client-config';

const appRoot = config.app.root;

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

function isPrePath(currPath, toPath) {
  const isPre = currPath.startsWith(toPath);
  return isPre;
}

class Link extends React.Component {
  static propTypes = {
    children: PTs.node.isRequired,
    className: PTs.string,
    isActive: PTs.func,
    onClick: PTs.func,
    to: PTs.string.isRequired,
  };

  static contextTypes = {
    pathname: PTs.string.isRequired,
  };

  static defaultProps = {
    className: '',
    isActive: isPrePath,
    onClick: null,
  };

  handleClick = event => {
    const { onClick, to } = this.props;

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    if (onClick) {
      onClick(event);
    }

    event.preventDefault();
    const href = this.normalize(to);
    history.push(href);
  };

  normalize = inTo => {
    let outTo = inTo;
    // '' => {appRoot}
    // '/' => '/'
    // 'foo' => {appRoot}foo
    // '/foo' => '/foo'
    if (inTo[0] !== '/') {
      outTo = appRoot + inTo;
    }
    return outTo;
  };

  render() {
    const { children, className, isActive, to, ...rest } = this.props;
    const { pathname } = this.context;
    const href = this.normalize(to);
    const currPath = `${pathname}/`.replace('//', '/');
    const toPath = `${href}/`.replace('//', '/');
    const active = isActive(currPath, toPath);

    const clsName = cx(className, 'link', { active });
    return (
      <a {...rest} className={clsName} href={href} onClick={this.handleClick}>
        <span className="inner">{children}</span>
      </a>
    );
  }
}

export default Link;
