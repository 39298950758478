import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import { toIntOrNull } from 'qc-to_int';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarHollow } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';

import s from './RaterInput.scss';

const BTN_SELECTOR = `.${s.btn}`;

// Note: redux-form listens for the blur event of the input component.  It appears
// to then use the target of the event to extract the input value. This is not
// what we want when the rating buttons blur. So, we stop the propagation of the
// blur event before it gets to redux-form.
const stopBlur = evt => {
  evt.stopPropagation();
};

class RaterInput extends React.Component {
  static propTypes = {
    disabled: PTs.bool.isRequired,
    inputClassName: PTs.string,
    inputId: PTs.string,
    max: PTs.number.isRequired,
    min: PTs.number.isRequired,
    name: PTs.string,
    onChange: PTs.func.isRequired,
    value: PTs.number,
  };

  static defaultProps = {
    inputClassName: undefined,
    inputId: undefined,
    name: '',
    value: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      hover: null,
    };
  }

  handleClick = evt => {
    evt.stopPropagation();

    const btnEl = evt.target.closest(BTN_SELECTOR);
    if (btnEl) {
      const { dataset } = btnEl;
      if (`${this.props.value}` !== dataset.ratingScore) {
        this.props.onChange(dataset.ratingScore);
      }
    }
  };

  // Note: Although using mouseenter and mouseleave is CPU intensive, it appears to
  // be ever so slightly less intense than using mouseout and mouseover. The latter
  // solution also required more complexity. So this mouseenter/mouseleave solution
  // has been chosen. Please keep an eye out for a better solution if available.
  handleMouseEnter = evt => {
    this.setState({ hover: evt.target.dataset.ratingScore });
  };

  handleMouseLeave = evt_unused => {
    this.setState({ hover: null });
  };

  render() {
    const {
      disabled,
      inputClassName,
      inputId,
      max,
      min,
      name,
      value,
      ...restProps
    } = this.props;
    delete restProps.onChange;
    const { hover } = this.state;
    const ratingScore = toIntOrNull(value);

    const stars = [];

    for (let i = 1, iLen = max; i <= iLen; ++i) {
      const iconDisabled = i < min;
      stars.push(
        <button
          key={i}
          className={cx(s.btn, {
            disabled: iconDisabled,
            hover: i <= hover,
          })}
          data-rating-score={i}
          onBlur={stopBlur}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          type="button"
        >
          <FontAwesomeIcon
            icon={i <= hover || i <= ratingScore ? faStarSolid : faStarHollow}
          />
        </button>,
      );
    }

    return (
      <React.Fragment>
        <input
          disabled={disabled}
          id={inputId}
          name={name}
          type="hidden"
          value={value}
        />
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/mouse-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <span
          className={cx(s.root, inputClassName, 'form-control')}
          disabled={disabled}
          onClick={this.handleClick}
          {...restProps}
        >
          {stars}
        </span>
      </React.Fragment>
    );
  }
}

export default withStyles(s)(RaterInput);
