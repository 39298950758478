import addDays from 'date-fns/add_days';
import _parseDate from 'date-fns/parse';
import { toDate as _toDate } from 'qc-to_date';

import { round } from '@qc/date-round';

export function isValidDate(year, month, day) {
  const d = new Date(year, month, day);
  if (
    d.getFullYear().toString() === year.toString() &&
    d.getMonth().toString() === month.toString() &&
    d.getDate().toString() === day.toString()
  ) {
    return true;
  }
  return false;
}

/**
 * @param {number} n - The number of sunday's ago. -1 => last Sunday, 0 => today
 *   or the upcoming Sunday (aka next Sunday), 1 => The Sunday after "next".
 */
export function nSundays(n = 0) {
  const now = new Date();
  // 0 => Sun
  const nowDay = now.getDay();
  const date = addDays(now, nowDay + 7 * n);
  return date;
}

/**
 * In local time, returns a date of last Sunday.
 */
export function lastSunday() {
  const date = nSundays(-1);
  return date;
}

export function toDate(dateLike, defVal) {
  let date = null;
  if (
    dateLike &&
    typeof dateLike === 'object' &&
    'year' in dateLike &&
    'month' in dateLike
  ) {
    const { year } = dateLike;
    const month = dateLike.month - 1;
    const day = dateLike.day || 1;
    const hours = dateLike.hours || 0;
    const minutes = dateLike.minutes || 0;
    const seconds = dateLike.seconds || 0;
    date = new Date(year, month, day, hours, minutes, seconds);
  } else if (typeof dateLike === 'string') {
    date = _parseDate(dateLike);
  } else {
    date = _toDate(dateLike, defVal);
  }
  return date;
}

const QUARTER_HOUR_IN_MS = 15 * 60 * 1000;

export function toNearestQuarterHour(dateIn) {
  const dateOut = round(dateIn, QUARTER_HOUR_IN_MS);
  return dateOut;
}

const DateUtils = {
  isValidDate,
  lastSunday,
  nSundays,
  toDate,
  toNearestQuarterHour,
};

export default DateUtils;
