/* eslint-disable class-methods-use-this, react/destructuring-assignment */
import PTs from 'prop-types';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { IntlProvider } from 'react-intl';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import deepForceUpdate from 'react-deep-force-update';
import { MediaQueryProvider } from 'react-media-query-hoc';
import PageVisibility from 'react-page-visibility';

import AppContextShape from '../lib/AppContextShape';
import appContextTypes from '../lib/appContextTypes';

import { setVal } from '../state/actions/misc';

import Modals from './Modals';

// https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries/Using_media_queries
// Note: Please keep the shape of this in sync with MediaStateShape.js.
const mediaQueries = {
  // browser: '(display-mode: browser)',
  // fullscreen: '(display-mode: fullscreen)',
  // minimalUi: '(display-mode: minimal-ui)',
  // standalone: '(display-mode: standalone)',
  // anyHover: '(any-hover: hover)',
  // True when the pointer is coarse (e.g., finger is the pointer). May want to make
  // UI elements large enough for use with coarse pointers.
  coarsePointer: '(pointer: coarse)',
  finePointer: '(pointer: fine)',
  // True if a pointer is available.
  pointer: '(pointer: none)',
  hover: '(hover: hover)',
  // landscape: '(orientation: landscape)',
  // portrait: '(orientation: portrait)',
  xs: '(max-width: 575px)',
  sm: '(min-width: 576px) and (max-width: 767px)',
  md: '(min-width: 768px) and (max-width: 991px)',
  lg: '(min-width: 992px) and (max-width: 1199px)',
  xl: '(min-width: 1200px)',
};

/**
 * The top-level React component setting context (global) variables
 * that can be accessed from all the child components.
 *
 * https://facebook.github.io/react/docs/context.html
 *
 * Usage example:
 *
 *   const context = {
 *     history: createBrowserHistory(),
 *     store: createStore(),
 *   };
 *
 *   ReactDOM.render(
 *     <App context={context}>
 *       <Layout>
 *         <LandingPage />
 *       </Layout>
 *     </App>,
 *     container,
 *   );
 */
class App extends React.PureComponent {
  static propTypes = {
    children: PTs.element.isRequired,
    context: AppContextShape.isRequired,
    mqSsrValues: PTs.shape({
      hover: PTs.string.isRequired,
      pointer: PTs.oneOf(['coarse', 'fine', 'none']).isRequired,
      type: PTs.oneOf(['screen']).isRequired,
      width: PTs.number.isRequired,
    }),
  };

  static defaultProps = {
    mqSsrValues: null,
  };

  static childContextTypes = appContextTypes;

  // constructor(props) {
  //   console.log('App#constructor');
  //   super(props);
  // }

  getChildContext() {
    return this.props.context;
  }

  componentDidMount() {
    // console.log('App#componentDidMount');
    const store = this.props.context && this.props.context.store;
    if (store) {
      this.unsubscribe = store.subscribe(() => {
        const state = store.getState();
        const newIntl = state.intl;
        if (this.intl !== newIntl) {
          this.intl = newIntl;
          if (__DEV__) {
            // eslint-disable-next-line no-console
            console.log('Intl changed — Force rendering');
          }
          deepForceUpdate(this);
        }
      });
    } else {
      // Note: I added this warning to see if this branch of code ever gets reached. If
      // we never see it, then it is likely that we don't need `if (store)` above.
      console.warn('No Redux store available on the app context.');
    }
  }

  componentDidUpdate() {
    // console.log('App#componentDidUpdate');
  }

  componentWillUnmount() {
    // console.log('App#componentWillUnmount');
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
  }

  handlePageVisibilityChange = isVisible => {
    // console.log('App#handlePageVisibilityChange');
    // console.log('isVisible:', isVisible);
    const { store } = this.props.context;

    store.dispatch(setVal('isPageVisible', isVisible));
  };

  componentDidCatch(err, info) {
    // console.log('App#componentDidCatch');
    // TODO: Handle errors better.  Since error boundaries are relatively new and I
    // don't have much experience with them, I'd like to see first what is being
    // thrown before making any decisions.  I do believe we should create an error-
    // boundary component to be reused throughout the code base.  The one here at
    // the root of the app is just a catchall.
    console.error(err, info);
  }

  render() {
    // console.log('App#render');
    const { context, mqSsrValues } = this.props;
    const { graphqlClient, store } = context;

    const state = store.getState();
    this.intl = (state && state.intl) || {};
    const { initialNow, locale, messages } = this.intl;
    const localeMessages = (messages && messages[locale]) || {};

    return (
      <PageVisibility onChange={this.handlePageVisibilityChange}>
        <IntlProvider
          defaultLocale="en-US"
          initialNow={initialNow}
          locale={locale}
          messages={localeMessages}
        >
          <MediaQueryProvider queries={mediaQueries} values={mqSsrValues}>
            <ApolloProvider client={graphqlClient}>
              <Modals />
              {React.Children.only(this.props.children)}
            </ApolloProvider>
          </MediaQueryProvider>
        </IntlProvider>
      </PageVisibility>
    );
  }
}

export default App;
