import cx from 'classnames';
import PTs from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from '../../locale/messages';

import GoogleGeocodeInput from './GoogleGeocodeInput';
import RaterInput from './RaterInput';
import { VALID_FIELD_TYPES } from './Types';

function InputFieldGroup(props) {
  // if (props.input.name === 'googleGeocode') {
  //   console.log('InputFieldGroup');
  //   console.log('props:', props);
  // }
  const {
    bsSize,
    children,
    className,
    desc,
    descClassName,
    disabled,
    errorClassName,
    errorFeedbackClassName,
    extraMessageValues,
    feedback,
    feedbackClassName,
    input,
    inputClassName,
    inputColClassName,
    inputId,
    label,
    labelClassName,
    labelColClassName,
    labelHidden,
    list,
    max,
    maxLength,
    meta,
    min,
    minLength,
    pattern,
    placeholder,
    required,
    successFeedbackClassName,
    type,
    warningClassName,
    warningFeedbackClassName,
    ...inputProps
  } = props;
  // const {
  //   checked,
  //   name,
  //   onBlur,
  //   onChange,
  //   onDragStart,
  //   onDrop,
  //   onFocus,
  //   value,
  // } = input;
  const {
    active,
    // asyncValidating,
    autofilled,
    dirty, // Opposite of pristine.
    // dispatch,
    error,
    // form: formName,
    // initial,
    // invalid, // Opposite of valid.
    pristine,
    // submitFailed,
    submitting,
    // Note: All submitted fields get marked at touched when a submit fails.
    // See https://redux-form.com/7.4.2/docs/api/actioncreators.md/#-code-setsubmitfailed-form-string-fields-string-code-.
    touched,
    valid,
    visited,
    warning,
  } = meta;
  const disableInput = disabled || false || submitting;
  const hasError = !!error;
  const hasSuccess = valid;
  const hasWarning = !!warning;
  const renderGoogleGeocode = type === 'google-geocode';
  const renderRater = type === 'rater';
  const renderSelect = type === 'select';
  const renderTextarea = type === 'markdown' || type === 'textarea';
  const renderDefault =
    !renderGoogleGeocode && !renderRater && !renderSelect && !renderTextarea;
  const showError =
    hasError &&
    (touched ||
      (visited &&
        renderTextarea &&
        error !== 'validators.value.is_required' &&
        error !== 'validators.value.has_length_lt'));
  const showWarning =
    hasWarning &&
    (touched ||
      (visited &&
        renderTextarea &&
        warning !== 'validators.value.is_required' &&
        warning !== 'validators.value.has_length_lt'));

  const showSuccess = touched && hasSuccess && !hasError && !hasWarning;
  const withFeedback =
    (feedback === undefined ? true : feedback) &&
    (showError || showSuccess || showWarning);
  const inputPlaceholder =
    placeholder === '' ? undefined : placeholder || label;

  const messageValues = {
    label,
    max,
    maxLength,
    min,
    minLength,
    pattern,
    ...extraMessageValues,
  };

  let errorMsg = null;
  let warningMsg = null;
  if (showError) {
    const errMsgDesc = messages[error];
    if (errMsgDesc) {
      errorMsg = <FormattedMessage {...errMsgDesc} values={messageValues} />;
    } else {
      errorMsg = error;
    }
  }
  if (showWarning) {
    const warnMsgDesc = messages[warning];
    if (warnMsgDesc) {
      warningMsg = <FormattedMessage {...warnMsgDesc} values={messageValues} />;
    } else {
      warningMsg = warning;
    }
  }
  return (
    <div
      className={cx(className, 'form-group', {
        active,
        autofilled,
        dirty,
        disabled: disableInput,
        [`form-group-${bsSize}`]: bsSize,
        'has-error': showError,
        'has-feedback': withFeedback,
        'has-no-selection': renderSelect && !input.value,
        'has-success': showSuccess,
        'has-warning': showWarning,
        pristine,
        submitting,
        touched,
        valid,
        visited,
      })}
    >
      {!!label && (
        <label
          className={cx(labelClassName, labelColClassName, 'control-label', {
            'sr-only': labelHidden,
          })}
          htmlFor={inputId}
        >
          {label}
          {required && <span className="required">{/* * */}</span>}
        </label>
      )}
      <span className={inputColClassName}>
        {renderGoogleGeocode && (
          <GoogleGeocodeInput
            disabled={disableInput}
            inputId={inputId}
            placeholder={inputPlaceholder}
            {...input}
            {...inputProps}
          />
        )}
        {renderRater && (
          <RaterInput
            disabled={disableInput}
            inputId={inputId}
            max={max}
            min={min}
            {...input}
            {...inputProps}
          />
        )}
        {renderSelect && (
          <select
            aria-invalid={valid ? undefined : 'true'}
            className={cx(inputClassName, 'form-control')}
            disabled={disableInput}
            id={inputId}
            pattern={pattern}
            required={required}
            {...input}
            {...inputProps}
          >
            {children}
          </select>
        )}
        {renderTextarea && (
          <React.Fragment>
            <textarea
              aria-invalid={valid ? undefined : 'true'}
              className={cx(inputClassName, 'form-control')}
              disabled={disableInput}
              id={inputId}
              maxLength={maxLength}
              minLength={minLength}
              pattern={pattern}
              placeholder={inputPlaceholder}
              required={required}
              {...input}
              {...inputProps}
            />
            {/* TODO: Add character counter. */}
          </React.Fragment>
        )}
        {renderDefault && (
          <input
            aria-invalid={valid ? undefined : 'true'}
            className={cx(inputClassName, 'form-control')}
            disabled={disableInput}
            id={inputId}
            list={list}
            max={max}
            maxLength={maxLength}
            min={min}
            minLength={minLength}
            pattern={pattern}
            placeholder={inputPlaceholder}
            required={required}
            type={type}
            {...input}
            {...inputProps}
          />
        )}
      </span>
      {withFeedback && (
        <span
          aria-hidden="true"
          className={cx(feedbackClassName, 'form-control-feedback', {
            [errorFeedbackClassName]: hasError,
            [successFeedbackClassName]: hasSuccess,
            [warningFeedbackClassName]: hasWarning,
          })}
        />
      )}
      {!renderSelect && !renderTextarea && !!list && children}
      {!!desc && <p className={cx(descClassName, 'desc-block')}>{desc}</p>}
      {showError && (
        <p className={cx(errorClassName, 'help-block', 'text-danger')}>
          {errorMsg}
        </p>
      )}
      {showWarning && (
        <p className={cx(warningClassName, 'help-block', 'text-warning')}>
          {warningMsg}
        </p>
      )}
    </div>
  );
}

InputFieldGroup.propTypes = {
  bsSize: PTs.oneOf(['lg', 'sm']),
  children: PTs.node,
  className: PTs.string,
  desc: PTs.node,
  descClassName: PTs.string,
  disabled: PTs.bool,
  errorClassName: PTs.string,
  errorFeedbackClassName: PTs.string,
  extraMessageValues: PTs.shape({}),
  feedback: PTs.bool,
  feedbackClassName: PTs.string,
  input: PTs.shape({}),
  inputClassName: PTs.string,
  inputColClassName: PTs.string,
  inputId: PTs.string,
  label: PTs.string,
  labelClassName: PTs.string,
  labelColClassName: PTs.string,
  labelHidden: PTs.bool,
  list: PTs.string,
  max: PTs.number,
  maxLength: PTs.number,
  meta: PTs.shape({
    // See code for details.
  }),
  min: PTs.number,
  minLength: PTs.number,
  pattern: PTs.string,
  placeholder: PTs.string,
  required: PTs.bool,
  successFeedbackClassName: PTs.string,
  type: PTs.oneOf(VALID_FIELD_TYPES).isRequired,
  warningClassName: PTs.string,
  warningFeedbackClassName: PTs.string,
};

InputFieldGroup.defaultProps = {
  bsSize: undefined,
  children: null,
  className: '',
  desc: '',
  descClassName: '',
  disabled: false,
  errorClassName: '',
  errorFeedbackClassName: 'glyphicon glyphicon-remove',
  extraMessageValues: undefined,
  feedback: true,
  feedbackClassName: '',
  input: {},
  inputClassName: '',
  inputColClassName: '',
  inputId: undefined,
  label: '',
  labelClassName: '',
  labelColClassName: '',
  labelHidden: false,
  list: undefined,
  max: undefined,
  maxLength: undefined,
  // `meta` is usually supplied by Redux Form's `Field` component. So, in order to
  // use this component without going through Redux Form, we are supplying a default
  // value.
  meta: {},
  min: undefined,
  minLength: undefined,
  pattern: undefined,
  placeholder: undefined,
  required: false,
  successFeedbackClassName: 'glyphicon glyphicon-ok',
  warningClassName: '',
  warningFeedbackClassName: 'glyphicon glyphicon-warning-sign',
};

export default InputFieldGroup;
