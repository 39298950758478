exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BO3xa{display:block !important;margin:0 auto !important}", ""]);

// exports
exports.locals = {
	"resetButton": "BO3xa"
};