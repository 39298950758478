import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import account from './account';
import connections from './connections';
import currency from './currency';
import intl from './intl';
import misc from './misc';
import miscPersist from './miscPersist';
import modals from './modals';
import runtime from './runtime';
// import siteSettings from './siteSettings';
import sessionLocations from './sessionLocations';

// External Reducers

export default function createRootReducer() {
  const reducers = combineReducers({
    account,
    connections,
    currency,
    form: formReducer,
    intl,
    misc,
    miscPersist,
    modals,
    runtime,
    sessionLocations,
    // siteSettings,
  });

  const mainReducer = (state = {}, action) => {
    if (action.type === 'HYDRATE_STATE') {
      return {
        ...state,
        ...action.payload,
        miscPersist: {
          hydrated: true,
        },
      };
    }
    return reducers(state, action);
  };

  return mainReducer;
}
