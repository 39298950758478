exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".wsBUr, ._2X0qJ, ._1sNeG, ._3pBX0{padding:10px 20px;font-size:16px;font-size:1rem;border-radius:30px;font-weight:300;border:0;margin:0;min-width:130px;text-align:center}.wsBUr{background:var(--primary--400);background:var(--primary--400);color:#fff;border:1px solid var(--primary--400);border:1px solid var(--primary--400)}.wsBUr:hover{color:#fff;opacity:.8}._2X0qJ{background:#fff;color:var(--primary--400);color:var(--primary--400);-webkit-box-shadow:var(--base--box-shadow);box-shadow:var(--base--box-shadow);box-shadow:var(--base--box-shadow)}._1sNeG{background:rgba(0,0,0,0);color:var(--primary--400);color:var(--primary--400);border:1px solid var(--primary--400);border:1px solid var(--primary--400)}._1sNeG:hover{opacity:.8}._3pBX0{background:rgba(0,0,0,0);color:var(--neutral--700);color:var(--neutral--700)}._3pBX0:hover{opacity:.8;color:var(--neutral--700);color:var(--neutral--700)}", ""]);

// exports
exports.locals = {
	"defaultLook": "wsBUr",
	"inverseLook": "_2X0qJ",
	"outlineLook": "_1sNeG",
	"cancelLook": "_3pBX0"
};