import {
  LOGIN_MODAL__UPDATE,
  OPEN_NEW_MESSAGE_MODAL,
  CLOSE_NEW_MESSAGE_MODAL,
  OPEN_LOGIN_MODAL,
  CLOSE_LOGIN_MODAL,
  OPEN_SIGNUP_MODAL,
  CLOSE_SIGNUP_MODAL,
  OPEN_DELETE_PROFILE_MODAL,
  CLOSE_DELETE_PROFILE_MODAL,
} from './';

export function openNewMessageModal() {
  return {
    type: OPEN_NEW_MESSAGE_MODAL,
  };
}

export function closeNewMessageModal() {
  return {
    type: CLOSE_NEW_MESSAGE_MODAL,
  };
}

export function openLoginModal(props = {}) {
  const { message = null, redirect = null } = props;
  return {
    type: OPEN_LOGIN_MODAL,
    payload: { message, redirect },
  };
}

export function closeLoginModal() {
  return {
    type: CLOSE_LOGIN_MODAL,
  };
}

export function showLoginModal() {
  return {
    type: LOGIN_MODAL__UPDATE,
    payload: {
      show: true,
    },
  };
}

export function openSignupModal() {
  return {
    type: OPEN_SIGNUP_MODAL,
  };
}

export function closeSignupModal() {
  return {
    type: CLOSE_SIGNUP_MODAL,
  };
}

export function openDeleteProfileModal() {
  return {
    type: OPEN_DELETE_PROFILE_MODAL,
  };
}

export function closeDeleteProfileModal() {
  return {
    type: CLOSE_DELETE_PROFILE_MODAL,
  };
}
