import geocoding from 'geocoding';
import Geocode from 'react-geocode';

import config from '../config/client-config';

Geocode.setApiKey(config.thirdParty.google.maps.apiKey);

const ObjectFreeze = Object.freeze;

const NO_RESULTS_RESPONSE_RE = /No results/i;

const OK_NO_RESULTS = ObjectFreeze([]);

async function geocode(options) {
  let results = OK_NO_RESULTS;

  try {
    results = await geocoding({
      key: config.thirdParty.google.maps.apiKey,
      ...options,
    });
  } catch (e) {
    // Ignore the "No results" error.
    if (!NO_RESULTS_RESPONSE_RE.test(e.message)) {
      throw e;
    }
  }

  return results;
}

async function getGeneralLocationFromLatLng(lat, lng) {
  const response = await Geocode.fromLatLng(lat, lng);
  return response.results[response.results.length - 4].formatted_address;
}

const GeocodeUtils = {
  geocode,
  getGeneralLocationFromLatLng,
};

export { geocode, getGeneralLocationFromLatLng };

export default GeocodeUtils;
