import PTs from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import LoginModal from '../LoginModal';
import SignupModal from '../SignupModal';

class Modals extends React.PureComponent {
  // constructor(props) {
  //   console.log('Modals#constructor');
  //   super(props);
  // }

  // componentDidUpdate() {
  //   console.log('Modals#componentDidUpdate');
  // }

  // componentWillUnmount() {
  //   console.log('Modals#componentWillUnmount');
  // }

  render() {
    // console.log('Modals#render');
    const { modals } = this.props;
    return (
      <>
        {modals.login.render && <LoginModal />}
        {modals.isSignupModalOpen && <SignupModal />}
      </>
    );
  }
}

Modals.propTypes = {
  modals: PTs.shape({}).isRequired,
};

const mapStateToProps = state => ({
  // _console: console.log('Modals#mapStateToProps'),
  modals: state.modals,
});

const mapDispatchToProps = null;

const ReduxAwareModals = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modals);

export default ReduxAwareModals;
