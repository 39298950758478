import {
  CURRENCY_RATES_FETCH_SUCCESS,
  CHOSE_TO_CURRENCY_SUCCESS,
} from '../actions';

/*::
type CurrencyRateAmount = number

type CurrencyRatesLut {
  [CurrencySymbol]: CurrencyRateAmount
}

type CurrencyState = {
  rates: ?CurrencyRatesLut
  to: ?CurrencySymbol
}
*/

const initialState = {
  rates: null,
  to: null,
};

export default function currency(state = initialState, action) {
  switch (action.type) {
    case CURRENCY_RATES_FETCH_SUCCESS:
      return {
        ...state,
        to: action.payload.to,
        rates: action.payload.rates,
      };

    case CHOSE_TO_CURRENCY_SUCCESS:
      return {
        ...state,
        to: action.payload.to,
      };

    default:
      return state;
  }
}
