/*
Timestamp query entries in the Apollo Client cache.

This is necessary because Apollo Client doesn't automatically do it, and we
need to know how long cache entries have been around.

Running this about once every 10 seconds seems reasonable.
*/
export const timestampCache = apolloClient => {
  // console.log('timestampCache');
  const { cache } = apolloClient;

  // console.log('cache:', cache);

  const rootQuery = cache.data.data && cache.data.data.ROOT_QUERY;

  if (rootQuery) {
    const now = Date.now();
    Object.values(rootQuery).forEach(meta => {
      if (meta.generated && !('__timestamp' in meta)) {
        // eslint-disable-next-line no-param-reassign, no-underscore-dangle
        meta.__timestamp = now;
      }
    });
  }
};
