import config from '../../config/client-config';

const appRoot = config.app.root;

// The top-level (parent) route.
// Note: This may also be an array of Route objects.
const routes = {
  path: '',

  async action({ next }) {
    // Execute each child route until one of them return the result
    const actionSpec = await next();

    // Provide default values for title, description etc.
    actionSpec.title = `${actionSpec.title || 'Untitled Page'} - Shadower`;
    actionSpec.description = actionSpec.description || '';

    return actionSpec;
  },

  // **Known Forbidden Paths**
  //
  // - /api
  // - /assets
  // - /graphql

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '',
      load: () => import(/* webpackChunkName: "initial" */ './home'),
    },
    {
      path: '/test',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: "test" */ './test'),
        },
        {
          path: '/braintree',
          children: [
            {
              path: '',
              load: () =>
                import(/* webpackChunkName: "test" */ './test/braintree'),
            },
            {
              path: '/drop-in',
              load: () =>
                import(
                  /* webpackChunkName: "test" */ './test/braintree/drop-in'
                ),
            },
            {
              path: '/hosted-fields',
              load: () =>
                import(
                  /* webpackChunkName: "test" */ './test/braintree/hosted-fields'
                ),
            },
          ],
        },
        {
          path: '/form',
          load: () => import(/* webpackChunkName: "test" */ './test/form'),
        },
        {
          path: '/google',
          children: [
            {
              path: '',
              load: () =>
                import(/* webpackChunkName: "test" */ './test/google'),
            },
            {
              path: '/geocode',
              children: [
                {
                  path: '',
                  load: () =>
                    import(
                      /* webpackChunkName: "test" */ './test/google/geocode'
                    ),
                },
              ],
            },
          ],
        },
        {
          path: '/hyperwallet',
          children: [
            {
              path: '',
              load: () =>
                import(/* webpackChunkName: "test" */ './test/hyperwallet'),
            },
            {
              path: '/user',
              children: [
                {
                  path: '',
                  load: () =>
                    import(
                      /* webpackChunkName: "test" */ './test/hyperwallet/user'
                    ),
                },
                {
                  path: '/add',
                  load: () =>
                    import(
                      /* webpackChunkName: "test" */ './test/hyperwallet/user/add'
                    ),
                },
              ],
            },
          ],
        },
        {
          path: '/paypal',
          children: [
            {
              path: '',
              load: () =>
                import(/* webpackChunkName: "test" */ './test/paypal'),
            },
            {
              path: '/checkout',
              children: [
                {
                  path: '',
                  load: () =>
                    import(
                      /* webpackChunkName: "test" */ './test/paypal/checkout'
                    ),
                },
                {
                  path: '/client',
                  load: () =>
                    import(
                      /* webpackChunkName: "test" */ './test/paypal/checkout/client'
                    ),
                },
              ],
            },
            {
              path: '/link',
              load: () =>
                import(/* webpackChunkName: "test" */ './test/paypal/link'),
            },
          ],
        },
        {
          path: '/react-select',
          load: () =>
            import(/* webpackChunkName: "test" */ './test/react-select'),
        },
        {
          path: '/rc-slider',
          load: () => import(/* webpackChunkName: "test" */ './test/rc-slider'),
        },
        {
          path: '/stripe',
          children: [
            {
              path: '',
              load: () =>
                import(/* webpackChunkName: "test" */ './test/stripe'),
            },
            {
              path: '/connect',
              load: () =>
                import(/* webpackChunkName: "test" */ './test/stripe/connect'),
            },
            {
              path: '/elements',
              load: () =>
                import(/* webpackChunkName: "test" */ './test/stripe/elements'),
            },
          ],
        },
      ],
    },
    {
      path: '/about',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: "about" */ './about'),
        },
        {
          path: '/how-it-works',
          load: () => import(/* webpackChunkName: "about" */ './about'),
        },
        {
          path: '/team',
          load: () => import(/* webpackChunkName: "about" */ './about/team'),
        },
        {
          path: '/careers',
          load: () => import(/* webpackChunkName: "about" */ './about/careers'),
        },
      ],
    },
    {
      path: '/handbook',
      children: [
        {
          path: '',
          load: () =>
            import(
              /* webpackChunkName: "handbook" */ './handbook/getting-started/welcome-to-shadower'
            ),
        },
        {
          path: '/getting-started',
          children: [
            {
              path: '',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/getting-started/welcome-to-shadower'
                ),
            },
            {
              path: '/welcome-to-shadower',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/getting-started/welcome-to-shadower'
                ),
            },
            {
              path: '/how-shadower-works',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/getting-started/how-shadower-works'
                ),
            },
            {
              path: '/working-with-businesses',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/getting-started/working-with-businesses'
                ),
            },
            {
              path: '/shadowing-guidelines',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/getting-started/shadowing-guidelines'
                ),
            },
          ],
        },
        {
          path: '/planning-a-session',
          children: [
            {
              path: '',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/planning-a-session/choosing-a-topic'
                ),
            },
            {
              path: '/choosing-a-topic',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/planning-a-session/choosing-a-topic'
                ),
            },
            {
              path: '/interactivity-level',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/planning-a-session/interactivity-level'
                ),
            },
            {
              path: '/session-type',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/planning-a-session/session-type'
                ),
            },
            {
              path: '/providing-resources',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/planning-a-session/providing-resources'
                ),
            },
          ],
        },
        {
          path: '/managing-a-session',
          children: [
            {
              path: '',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/managing-a-session/expectations'
                ),
            },
            {
              path: '/expectations',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/managing-a-session/expectations'
                ),
            },
            {
              path: '/session-flow',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/managing-a-session/session-flow'
                ),
            },
            {
              path: '/maximizing-your-time',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/managing-a-session/maximizing-your-time'
                ),
            },
          ],
        },
        {
          path: '/promoting-your-profile',
          children: [
            {
              path: '',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/promoting-your-profile/how-the-platform-works'
                ),
            },
            {
              path: '/how-the-platform-works',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/promoting-your-profile/how-the-platform-works'
                ),
            },
            {
              path: '/optimizing-your-profile',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/promoting-your-profile/optimizing-your-profile'
                ),
            },
            {
              path: '/marketing-yourself',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/promoting-your-profile/marketing-yourself'
                ),
            },
            {
              path: '/how-to-build-a-following',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/promoting-your-profile/how-to-build-a-following'
                ),
            },
          ],
        },
        {
          path: '/earning-revenue',
          children: [
            {
              path: '',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/earning-revenue/how-payment-works'
                ),
            },
            {
              path: '/how-payment-works',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/earning-revenue/how-payment-works'
                ),
            },
            {
              path: '/connecting-with-the-community',
              load: () =>
                import(
                  /* webpackChunkName: "handbook" */ './handbook/earning-revenue/connecting-with-the-community'
                ),
            },
          ],
        },
      ],
    },
    {
      path: '/contact',
      load: () =>
        import(
          /* webpackChunkName: "auth-contact-login-register" */ './contact'
        ),
    },
    {
      path: '/calendar/:initDate?',
      action: context => {
        const { params } = context;
        const { initDate } = params;
        return {
          redirect: `${appRoot}user/calendar/shadower/${initDate || ''}`,
        };
      },
    },
    {
      path: '/dashboard',
      action: () => ({
        redirect: `${appRoot}user/dashboard`,
      }),
    },
    {
      path: '/inbox',
      action: () => ({
        redirect: `${appRoot}user/inbox`,
      }),
    },
    {
      path: '/help/',
      action: () => ({
        redirect: `${appRoot}help/the-basics`,
      }),
    },
    {
      path: '/help/:section?',
      load: () => import(/* webpackChunkName: "help" */ './help'),
    },
    {
      path: '/demographics',
      action: () => ({
        redirect: `${appRoot}demographics/high-school-students`,
      }),
    },
    {
      path: '/demographics/:section?',
      load: () => import(/* webpackChunkName: "help" */ './demographics'),
    },
    {
      path: '/policies',
      action: () => ({
        redirect: `${appRoot}policies/terms-of-service`,
      }),
    },
    {
      path: '/policies/:section?',
      load: () =>
        import(
          /* webpackChunkName: "policies-trust-and-safety" */ './policies'
        ),
    },
    {
      path: '/trust-and-safety',
      action: () => ({
        redirect: `${appRoot}trust-and-safety/safety-tips`,
      }),
    },
    {
      path: '/trust-and-safety/:section?',
      load: () =>
        import(
          /* webpackChunkName: "policies-trust-and-safety" */ './trust-and-safety'
        ),
    },
    {
      path: '/message',
      children: [
        {
          path: '/:threadId/:type',
          load: () =>
            import(/* webpackChunkName: "shadowings-users" */ './viewMessage'),
        },
      ],
    },
    {
      path: '/users',
      children: [
        {
          path: '',
          load: () =>
            import(/* webpackChunkName: "shadowings-users" */ './users'),
        },
        {
          path: '/~:userSlug',
          load: () =>
            import(
              /* webpackChunkName: "shadowings-users" */ './users/profile'
            ),
        },
      ],
    },
    {
      path: '/shadowings',
      children: [
        {
          path: '',
          load: () =>
            import(/* webpackChunkName: "shadowings-users" */ './shadowings'),
        },
        {
          path: '/~:slug',
          load: () =>
            import(
              /* webpackChunkName: "shadowings-users" */ './shadowings/profiles'
            ),
        },
        {
          path: '/areas-of-expertise/:catSlug?/:fldSlug?',
          load: () =>
            import(
              /* webpackChunkName: "shadowings-users" */ './shadowings/areas-of-expertise'
            ),
        },
      ],
    },
    {
      path: '/register',
      load: () =>
        import(
          /* webpackChunkName: "auth-contact-login-register" */ './register'
        ),
    },
    {
      path: '/login',
      children: [
        {
          path: '',
          load: () =>
            import(
              /* webpackChunkName: "auth-contact-login-register" */ './login'
            ),
        },
        {
          path: '/trouble',
          load: () =>
            import(
              /* webpackChunkName: "auth-contact-login-register" */ './login/trouble'
            ),
        },
      ],
    },
    {
      path: '/user',
      children: [
        {
          path: '/dashboard',
          action: () => ({
            redirect: `${appRoot}user/dashboard/favorites`,
          }),
        },
        {
          path: '/dashboard/:section?',
          load: () => import(/* webpackChunkName: "user" */ './user'),
        },
        {
          path: '/settings/',
          action: () => ({
            redirect: `${appRoot}user/settings/payout`,
          }),
        },
        {
          path: '/settings/:section?',
          load: () => import(/* webpackChunkName: "user" */ './user/settings'),
        },
        {
          path: '/calendar/:bookingFor?/:initDate?',
          load: () => import(/* webpackChunkName: "user" */ './user/calendar'),
        },
        {
          path: '/inbox',
          load: () => import(/* webpackChunkName: "user" */ './user/inbox'),
        },
        {
          path: '/profile/:mode?',
          load: () => import(/* webpackChunkName: "user" */ './user/profile'),
        },
        {
          path: '/profiles/:slug/:mode?',
          load: () => import(/* webpackChunkName: "user" */ './user/profiles'),
        },
        {
          path: '/profiles/:slug?',
          load: () => import(/* webpackChunkName: "user" */ './user/profiles'),
        },
      ],
    },
    {
      path: '/auth',
      children: [
        {
          path: '/reset',
          load: () =>
            import(
              /* webpackChunkName: "auth-contact-login-register" */ './auth/reset'
            ),
        },
      ],
    },

    // blog routes
    {
      path: '/blog',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: "blog" */ './blog'),
        },
        {
          path: '/:blogSlug',
          load: () => import(/* webpackChunkName: "blog-post" */ './blogPost'),
        },
      ],
    },

    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: "initial" */ './not-found'),
    },
  ],
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    /* eslint-disable-next-line global-require */
    action: require('./error').default,
  });
}

export default routes;
