import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import history from '../../../client/history';

import ErrorBox from '../../components/ErrorBox';
import SuccessBox from '../../components/SuccessBox';

import { closeSignupModal, openLoginModal } from '../../state/actions/modals';
import { loadAccount } from '../../state/actions/AccountActions';
import { resendVerifyEmail } from '../../state/actions/AnonymousUserActions';

import Link from '../Link';
import RegisterForm from '../RegisterForm';
import ShadowerButton from '../ShadowerButton';

import messages from '../../locale/messages';

import s from './SignupModal.scss';

class SignupModal extends React.Component {
  static propTypes = {
    closeModal: PTs.func.isRequired,
    loadAccount: PTs.func.isRequired,
    onOpenLoginModal: PTs.func.isRequired,
    onRegistrationSuccess: PTs.func.isRequired,
    // The client-side route/path to redirect after a successful registation.
    redirect: PTs.string,
    resendVerifyEmail: PTs.func.isRequired,
    show: PTs.bool.isRequired,
  };

  static defaultProps = {
    redirect: 'dashboard/',
  };

  state = {
    isFormOpen: true,
    flash: null,
    mode: 'new', // One of 'new', 'pending', 'success'.
    register: {
      registration: null,
      requesting: false,
      status: null,
    },
  };

  setRegisterState(registerState) {
    this.setState(state => ({
      register: {
        ...state.register,
        ...registerState,
      },
    }));
  }

  handleRegisterFormSubmitSuccess = async registrationResult => {
    const { onRegistrationSuccess, redirect } = this.props;
    const { registration } = registrationResult;
    if (registrationResult.status === 'success') {
      this.setRegisterState({ requesting: true });
      const loadResult = await this.props.loadAccount();
      if (loadResult.status === 'success') {
        this.setRegisterState({
          registration,
          requesting: false,
          status: 'success',
        });
        onRegistrationSuccess(redirect);
      } else {
        this.setRegisterState({ requesting: false, status: 'failed' });
      }
    } else if (registrationResult.status === 'pending') {
      const message = (
        <>
          <h2>Registration successful.</h2>
          <p>Email was sent to {registration.email}.</p>
          <p>To complete registration, please verify email address.</p>
        </>
      );
      this.setRegisterState({
        registration,
        requesting: false,
        status: 'pending',
      });
      this.setState({
        flash: message,
        mode: 'pending',
      });
    } else {
      this.setRegisterState({ requesting: false, status: 'failed' });
    }
  };

  handleResendVerifyEmail = async evt_unused => {
    await this.props.resendVerifyEmail();
    const { register } = this.state;
    const message = `Email was sent to ${register.registration.email}.`;
    this.setState({
      flash: message,
    });
  };

  openForm = () => {
    this.setState({ isFormOpen: true });
  };

  renderButtons = () => (
    <div>
      {/*
        TODO: Add in social login
        <SocialLogin />
        <strong className={s.lineThrough}>
          <FormattedMessage {...messages.or} />
        </strong>
      */}
      <FormGroup className={s.formGroup}>
        <button
          className={cx(s.button, s.btnPrimary)}
          onClick={this.openForm}
          type="button"
        >
          <FormattedMessage {...messages.signupWithEmail} />
        </button>
      </FormGroup>
    </div>
  );

  renderForm = (mode, flash, register) => (
    <div>
      {mode === 'new' && (
        <>
          {register.requesting && <p>Registering...</p>}
          {register.status === 'failed' && (
            <ErrorBox message="An unexpected error occurred." />
          )}
          {/*
            <div className={cx(s.formGroup, s.formSection)}>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={cx('padding-none', 'text-align-left')}
              >
                <p className={cx(s.noMargin, s.captionText, 'text-align-center')}>
                  <FormattedMessage {...messages.signupWith} />{' '}
                  <a
                    href="/login/facebook"
                    className={cx(s.modalCaptionLink, s.modalCaptionLinkLarge)}
                  >
                    <FormattedMessage {...messages.facebook} />
                  </a>{' '}
                  <FormattedMessage {...messages.or} />{' '}
                  <a
                    href="/login/google"
                    className={cx(s.modalCaptionLink, s.modalCaptionLinkLarge)}
                  >
                    <FormattedMessage {...messages.google} />
                  </a>
                </p>
              </Col>
            </div>

            <strong className={s.lineThrough}>
              <FormattedMessage {...messages.or} />
            </strong>
          */}

          <RegisterForm
            onSubmitSuccess={this.handleRegisterFormSubmitSuccess}
          />
        </>
      )}
      {mode === 'pending' && (
        <>
          {flash && <SuccessBox>{flash}</SuccessBox>}
          <ShadowerButton
            className={s.resendButton}
            onClick={this.handleResendVerifyEmail}
            type="button"
          >
            Resend Verification Email
          </ShadowerButton>
        </>
      )}
    </div>
  );

  render() {
    const { flash, isFormOpen, mode, register } = this.state;

    const { closeModal, show, onOpenLoginModal } = this.props;

    return (
      <div>
        <Modal
          animation={false}
          bsSize="sm"
          className={s.modal}
          onHide={closeModal}
          show={show}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage {...messages.signup} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isFormOpen && this.renderForm(mode, flash, register)}

            {!isFormOpen && this.renderButtons()}

            <p className={s.captionText}>
              <small>
                <FormattedMessage {...messages.terms1} />
                <span>
                  <Link onClick={closeModal} to="/policies/terms-of-service">
                    Terms of Service
                  </Link>
                  .
                </span>
              </small>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className={s.footerContent}>
              <FormattedMessage {...messages.alreadyHaveAccount} />

              <button
                className={s.logInButton}
                onClick={onOpenLoginModal}
                type="button"
              >
                Log in
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  show: state.modals.isSignupModalOpen,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeSignupModal()),
  loadAccount: () => dispatch(loadAccount()),
  onOpenLoginModal: () => dispatch(openLoginModal()),
  onRegistrationSuccess: redirect => {
    if (redirect) {
      // console.log('Redirecting to', redirect);
      history.push(redirect);
    }
    dispatch(closeSignupModal());
  },
  resendVerifyEmail: () => dispatch(resendVerifyEmail()),
});

export default withStyles(s)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SignupModal),
);
