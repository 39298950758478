import PTs from 'prop-types';
import React from 'react';
import MDSpinner from 'react-md-spinner';

class CircleLoader extends React.Component {
  static propTypes = {
    className: PTs.string,
    show: PTs.bool,
    singleColor: PTs.string,
    size: PTs.number,
  };

  static defaultProps = {
    className: '',
    show: false,
    singleColor: '#1e8ec2',
    size: 48,
  };

  render() {
    const { show, ...props } = this.props;

    if (!show) {
      return null;
    }

    return <MDSpinner {...props} />;
  }
}

export default CircleLoader;
