exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2rxKa hr{margin:0}._2EcZt{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;overflow:hidden}._2EcZt p{color:var(--neutral--900);color:var(--neutral--900);margin:0}._2EcZt ._1DlSA{display:block;margin:var(--block--spacing--sm) auto;margin:var(--block--spacing--sm) auto;background:rgba(0,0,0,0);border:0;color:var(--neutral--500);color:var(--neutral--500);text-align:center}._3sS_u{text-align:center}._3sS_u p{margin:0}._3sS_u ._3YfsC{display:block;background:rgba(0,0,0,0);border:0;text-align:center;color:var(--primary--400);color:var(--primary--400);margin:0 auto}._16r6-{margin-left:-8px;margin-right:8px;float:left;background:rgba(0,0,0,0);border:0;color:#fff;font-size:22.4px;font-size:1.4rem}", ""]);

// exports
exports.locals = {
	"modal": "_2rxKa",
	"loginFlexColumn": "_2EcZt",
	"cantLoginButton": "_1DlSA",
	"footerContent": "_3sS_u",
	"signupButton": "_3YfsC",
	"backBtn": "_16r6-"
};