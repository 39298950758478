/**
 * Determines whether the result is a city, town, etc. These are generally known
 * as a locality.
 *
 * @param {GoogleGeocodeResult} result -
 */
function isLocality(result) {
  const is =
    result && Array.isArray(result.types) && result.types.includes('locality');
  return is;
}

/**
 * Determines whether the result is a postal code (aka a zip code in the USA).
 *
 * @param {GoogleGeocodeResult} result -
 */
function isPostalCode(result) {
  const is =
    result &&
    Array.isArray(result.types) &&
    result.types.includes('postal_code');
  return is;
}

function toComponentPart(partName, component) {
  let componentPart = null;
  if (component.types.includes(partName)) {
    componentPart = {
      longName: component.long_name,
      shortName: component.short_name,
    };
  }
  return componentPart;
}

function toCountry(component) {
  const country = toComponentPart('country', component);
  return country;
}

function toLocality(component) {
  const locality = toComponentPart('locality', component);
  return locality;
}

function toPostalCode(component) {
  const postalCode = toComponentPart('postal_code', component);
  return postalCode;
}

function toRegion(component) {
  const region = toComponentPart('administrative_area_level_1', component);
  return region;
}

function toRoute(component) {
  const route = toComponentPart('route', component);
  return route;
}

function toStreetAddress(component) {
  const streetAddress = toComponentPart('street_address', component);
  return streetAddress;
}

function toStreetNumber(component) {
  const streetNumber = toComponentPart('street_number', component);
  return streetNumber;
}

function findComponentParts(mapFn, result) {
  let componentParts;

  if (result && Array.isArray(result.address_components)) {
    componentParts = result.address_components.map(mapFn).filter(Boolean);
  }
  return componentParts || [];
}

function findOneComponentPart(mapFn, partName, result) {
  const componentParts = findComponentParts(mapFn, result);

  let componentPart = null;
  if (componentParts.length === 1) {
    componentPart = componentParts[0];
  } else if (componentParts.length > 1) {
    console.warn(
      `Found more than one ${partName} component in following Google Geocode result:`,
      JSON.stringify(result),
    );
  }

  return componentPart;
}

/**
 * Finds the first country component in the result.
 *
 * A warning is emitted to the console if more than one country component is
 * found.
 *
 * @return {Object}
 */
function findOneCountry(result) {
  const country = findOneComponentPart(toCountry, 'country', result);
  return country;
}

/**
 * Finds the first locality component in the result.
 *
 * A warning is emitted to the console if more than one locality component is
 * found.
 *
 * @return {Object}
 */
function findOneLocality(result) {
  const locality = findOneComponentPart(toLocality, 'locality', result);
  return locality;
}

/**
 * Finds the first postal code component in the result.
 *
 * A warning is emitted to the console if more than one post code component is
 * found.
 *
 * @return {Object}
 */
function findOnePostalCode(result) {
  const postalCode = findOneComponentPart(toPostalCode, 'postal code', result);
  return postalCode;
}

/**
 * Finds the first "region" component in the result.
 *
 * A warning is emitted to the console if more than one "region" component is
 * found.
 *
 * @return {Object}
 */
function findOneRegion(result) {
  const region = findOneComponentPart(toRegion, 'region', result);
  return region;
}

/**
 * Finds the first "street name" component in the result.
 *
 * A warning is emitted to the console if more than one "street name" component is
 * found.
 *
 * @return {Object}
 */
function findOneStreetName(result) {
  let streetName = findOneComponentPart(
    toStreetAddress,
    'street address',
    result,
  );

  if (!streetName) {
    streetName = findOneComponentPart(toRoute, 'route', result);
  }

  return streetName;
}

/**
 * Finds the first street number component in the result.
 *
 * A warning is emitted to the console if more than one street number component is
 * found.
 *
 * @return {Object}
 */
function findOneStreetNumber(result) {
  const streetNumber = findOneComponentPart(
    toStreetNumber,
    'street number',
    result,
  );
  return streetNumber;
}

export {
  findOneCountry,
  findOneLocality,
  findOnePostalCode,
  findOneRegion,
  findOneStreetName,
  findOneStreetNumber,
  isLocality,
  isPostalCode,
};

export default {
  findOneCountry,
  findOneLocality,
  findOnePostalCode,
  findOneRegion,
  findOneStreetName,
  findOneStreetNumber,
  isLocality,
  isPostalCode,
};
