const MM_DD_YYYY = 'MM/DD/YYYY';
const DD_MM_YYYY = 'DD/MM/YYYY';

// TODO: See if there is a re-usable library that already has this information.
// TODO: Continue filling this out as needed.
const DATE_FORMAT_LUT = {
  'en-US': MM_DD_YYYY,
  'es-ES': DD_MM_YYYY,
};

export function getDateFormat(locale) {
  const dateFormat = DATE_FORMAT_LUT[locale] || MM_DD_YYYY;
  return dateFormat;
}

// Note: Default locale is the first one.
export const SUPPORTED_LOCALES = Object.freeze(['en-US', 'es-ES']);
