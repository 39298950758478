import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';

import s from './ShadowerButton.scss';

const CLASSNAME_LUT = {
  default: s.defaultLook,
  inverse: s.inverseLook,
  outline: s.outlineLook,
  cancel: s.cancelLook,
  booked: s.bookedLook,
};

class ShadowerButton extends React.Component {
  static propTypes = {
    children: PTs.node.isRequired,
    className: PTs.string,
    look: PTs.oneOf(['default', 'inverse', 'outline', 'cancel', 'booked']),
  };

  static defaultProps = {
    className: '',
    look: 'default',
  };

  render() {
    const { children, className, look, ...btnProps } = this.props;
    return (
      /* eslint-disable-next-line react/button-has-type */
      <button className={cx(CLASSNAME_LUT[look], className)} {...btnProps}>
        {children}
      </button>
    );
  }
}

export default withStyles(s)(ShadowerButton);
