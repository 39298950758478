import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import history from '../../../client/history';

import ErrorBox from '../../components/ErrorBox';
import LoginForm from '../../components/LoginForm';
import ResetPasswordRequestForm from '../../components/ResetPasswordRequestForm';
import ShadowerLink from '../../components/ShadowerLink';
import SuccessBox from '../../components/SuccessBox';
import WarningBox from '../../components/WarningBox';

import messages from '../../locale/messages';

import { loadAccount } from '../../state/actions/AccountActions';
import { closeLoginModal, openSignupModal } from '../../state/actions/modals';

import s from './LoginModal.scss';

class LoginModal extends React.PureComponent {
  static propTypes = {
    closeModal: PTs.func.isRequired,
    loadAccount: PTs.func.isRequired,
    message: PTs.node,
    onLoginSuccess: PTs.func.isRequired,
    onOpenSignupModal: PTs.func.isRequired,
    // The client-side route/path to redirect after a successful login.
    redirect: PTs.string,
    show: PTs.bool.isRequired,
  };

  static defaultProps = {
    message: null,
    redirect: null,
  };

  constructor(props) {
    // console.log('LoginModal#constructor');
    super(props);

    this.state = {
      login: {
        requesting: false,
        status: null,
      },
      reset: {
        response: null,
        status: null,
      },
      tabId: 'login',
    };
  }

  // componentDidUpdate() {
  //   console.log('LoginModal#componentDidUpdate');
  // }

  // componentWillUnmount() {
  //   console.log('LoginModal#componentWillUnmount');
  // }

  setLoginState(loginState) {
    this.setState(state => ({
      login: {
        ...state.login,
        ...loginState,
      },
    }));
  }

  handleLoginFormSubmitSuccess = async loginResult => {
    const { onLoginSuccess, redirect } = this.props;

    if (loginResult.status === 'success') {
      this.setLoginState({ requesting: true });
      const loadResult = await this.props.loadAccount();
      if (loadResult.status === 'success') {
        this.setLoginState({ requesting: false, status: 'success' });
        onLoginSuccess(redirect);
      } else {
        this.setLoginState({ requesting: false, status: 'failed' });
      }
    } else {
      this.setLoginState({ requesting: false, status: 'failed' });
    }
  };

  handleResetPasswordSubmitSuccess = result => {
    const { resetPasswordRequest: response, status } = result;
    this.setState(state => ({
      reset: {
        ...state.reset,
        response,
        status,
      },
    }));
  };

  showLoginForm = () => {
    this.setState({ tabId: 'login' });
  };

  showResetPasswordRequestForm = () => {
    this.setState({ tabId: 'reset' });
  };

  render() {
    // console.log('LoginModal#render');
    const { message, closeModal, onOpenSignupModal, show } = this.props;
    const { login, reset, tabId } = this.state;

    let modalHeaderContent;
    // Note: We are rendering the login section regardless of `tabId`. This is so that
    // the user does not lose and information entered if they go to the reset tab and
    // back. We also get the benefit from not using `destroyOnUnmount={false}` on the
    // login form so that all information is gone when the login modal is closed.
    const modalBodyContent = [
      <section
        key="login"
        className={cx({ 'display-none': tabId !== 'login' })}
      >
        {login.requesting && <p>Authenticating...</p>}
        {login.status === 'failed' && (
          <ErrorBox message="An unexpected error occurred." />
        )}
        {message}
        {/*
          TODO: Add in social login
          <SocialLogin />
          <strong className={s.lineThrough}>
            <FormattedMessage {...messages.or} />
          </strong>
        */}
        <LoginForm onSubmitSuccess={this.handleLoginFormSubmitSuccess} />
        <div className={cx(s.loginFlexColumn)}>
          <div>
            <button
              className={s.cantLoginButton}
              onClick={this.showResetPasswordRequestForm}
              type="button"
            >
              <FormattedMessage {...messages.cantLogin} />
            </button>
          </div>
        </div>
      </section>,
    ];

    const modalFooterContent = [
      <div className={s.footerContent}>
        <p>Don't have an account?</p>
        <button
          className={s.signupButton}
          onClick={onOpenSignupModal}
          type="button"
        >
          <FormattedMessage {...messages.signUp} />
        </button>
      </div>,
    ];

    if (tabId === 'login') {
      modalHeaderContent = (
        <Modal.Title>
          <FormattedMessage {...messages.login} />
        </Modal.Title>
      );
    } else {
      modalHeaderContent = (
        <>
          <button
            className={cx(s.backBtn)}
            onClick={this.showLoginForm}
            type="button"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <Modal.Title>
            <FormattedMessage {...messages.forgotPassword} />
          </Modal.Title>
        </>
      );
      modalBodyContent.push(
        <section key="reset">
          {reset.status === 'success' && (
            <SuccessBox>
              An email to reset your password has been sent to{' '}
              <span>{reset.response.email}</span>.
            </SuccessBox>
          )}
          {reset.status === 'unregistered' && (
            <>
              <WarningBox>
                No user has completed the registration process using{' '}
                <span>{reset.response.email}</span>. You may try registering
                with this email if you like.
              </WarningBox>
              <ShadowerLink onClick={closeModal} to="/register">
                Register
              </ShadowerLink>
              <hr />
            </>
          )}
          <h2 className="globalCardHeading">Having Trouble Logging In?</h2>
          <p>
            Enter your email to receive a link to reset the password to your
            account.
          </p>
          <ResetPasswordRequestForm
            onSubmitSuccess={this.handleResetPasswordSubmitSuccess}
          />
        </section>,
      );
    }

    return (
      <Modal
        animation={false}
        bsSize="sm"
        className={s.modal}
        onHide={closeModal}
        show={show}
      >
        <Modal.Header closeButton>{modalHeaderContent}</Modal.Header>
        <Modal.Body>{modalBodyContent}</Modal.Body>
        <Modal.Footer>{modalFooterContent}</Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  ...state.modals.login,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeLoginModal()),
  loadAccount: () => dispatch(loadAccount()),
  onLoginSuccess: redirect => {
    if (redirect) {
      // console.log('Redirecting to', redirect);
      history.push(redirect);
    }
    dispatch(closeLoginModal());
  },
  onOpenSignupModal: () => dispatch(openSignupModal()),
});

export default withStyles(s)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LoginModal),
);
