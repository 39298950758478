import PTs from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Json from '../../lib/Json';
import messages from '../../locale/messages';

import ErrorBox from '../ErrorBox';

function I18nErrorBox(props) {
  const { message, values } = props;

  let msgs = message;
  if (!Array.isArray(message)) {
    msgs = [message];
  }

  const errorMsgs = msgs.map(msg => {
    let errorMsg;
    if (typeof msg === 'string') {
      const msgDesc = messages[msg];
      if (msgDesc) {
        errorMsg = <FormattedMessage {...msgDesc} values={values} />;
      } else {
        errorMsg = message;
      }
    } else if (msg instanceof Error) {
      const error = msg;
      if (error.message) {
        const msgDesc = messages[error.message];
        if (msgDesc) {
          errorMsg = <FormattedMessage {...msgDesc} values={values} />;
        } else {
          errorMsg = error.message;
        }
      }
      if (!errorMsg) {
        errorMsg = error.toString();
      }
    } else if (typeof msg === 'object' && msg !== null) {
      // If has `{ key: '_error', value: { msgId: 'string' } }`-like shape, then...
      if (
        msg.value !== null &&
        typeof msg.value === 'object' &&
        typeof msg.value.msgId === 'string' &&
        typeof msg.key === 'string'
      ) {
        const { value } = msg;
        const { msgId, valuesJson } = value;
        const msgDesc = messages[msgId];
        if (msgDesc) {
          const vals = Json.parse(valuesJson, undefined, {});
          errorMsg = <FormattedMessage {...msgDesc} values={vals} />;
        } else {
          errorMsg = msg.defaultMessage || msg.id;
        }
      } else if (typeof msg.id === 'string') {
        const msgDesc = messages[msg.id];
        if (msgDesc) {
          errorMsg = <FormattedMessage {...msgDesc} values={values} />;
        } else {
          errorMsg = msg.defaultMessage || msg.id;
        }
      } else {
        errorMsg = msg.toString();
      }
    }

    errorMsg = errorMsg || msg;
    return errorMsg;
  });

  let errorMsg = null;
  if (errorMsgs.length === 1) {
    errorMsg = errorMsgs[0];
  } else if (errorMsgs.length > 1) {
    errorMsg = (
      <ul>
        {errorMsgs.map(errMsg => (
          <li>{errMsg}</li>
        ))}
      </ul>
    );
  }

  return <ErrorBox message={errorMsg} />;
}

const MessageDescriptorPropType = PTs.shape({
  defaultMessage: PTs.string,
  description: PTs.string,
  id: PTs.string.isRequired,
});

I18nErrorBox.propTypes = {
  message: PTs.oneOfType([
    MessageDescriptorPropType,
    PTs.instanceOf(Error),
    PTs.node,
    PTs.arrayOf(
      PTs.oneOfType([
        MessageDescriptorPropType,
        PTs.instanceOf(Error),
        PTs.node,
      ]),
    ),
  ]).isRequired,
  values: PTs.shape({}),
};

I18nErrorBox.defaultProps = {
  values: {},
};

export default I18nErrorBox;
