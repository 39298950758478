exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._36hHJ._36hHJ{border:0;-webkit-box-shadow:none;box-shadow:none}._1FEX4{background:rgba(0,0,0,0);border:0;padding:0 2px}._1FEX4.hover{color:var(--primary--400);color:var(--primary--400)}", ""]);

// exports
exports.locals = {
	"root": "_36hHJ",
	"btn": "_1FEX4"
};