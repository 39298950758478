import { typeOf } from 'qc-type_of';

import Json from './Json';

const INTROSPECTION_FIELD_NAMES = Object.freeze(['__typename']);

/**
 * @param {Array<ImageSource>} imageSources
 */
function imageSourcesToSrcSet(imageSources) {
  let srcSet;

  if (Array.isArray(imageSources)) {
    srcSet = imageSources
      .map(imageSource => {
        const { descriptor, url } = imageSource;
        return descriptor ? `${url} ${descriptor}` : url;
      })
      .join(', ');
  }

  return srcSet;
}

function normalizeRecoverableErrors(errors) {
  let normErrors = errors;
  if (Array.isArray(errors)) {
    normErrors = errors.map(error => {
      const { key, value } = error;
      const { code, msgId, valuesJson } = value;
      const values = Json.parse(valuesJson, undefined, {});
      const normError = {
        key,
        value: {
          code,
          msgId,
          values,
        },
      };
      return normError;
    });
  }
  return normErrors;
}

/**
 * Strips the input of any "introspection/metadata" fields.
 *
 * @param {*} input
 *
 * @returns {*} A deep copy of the input without any "introspection/metadata" fields;
 */
function stripIntrospectionFields(input) {
  let output = input;

  if (Array.isArray(input)) {
    output = input.map(stripIntrospectionFields);
  } else if (typeOf(input) === 'object') {
    output = {};
    Object.entries(input).forEach(entry => {
      const [key, value] = entry;
      const fieldName = key;
      if (!INTROSPECTION_FIELD_NAMES.includes(fieldName)) {
        output[fieldName] = stripIntrospectionFields(value);
      }
    });
  }

  return output;
}

export {
  imageSourcesToSrcSet,
  normalizeRecoverableErrors,
  stripIntrospectionFields,
};

const GraphQlUtils = {
  imageSourcesToSrcSet,
  normalizeRecoverableErrors,
  stripIntrospectionFields,
};

export default GraphQlUtils;
