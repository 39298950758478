export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';

export const SET_CONNECTIONS = 'SET_CONNECTIONS';
export const SET_POPULAR_SESSION_LOCATIONS = 'SET_POPULAR_SESSION_LOCATIONS';

// Generic state management
export const SET_PERSIST_VAL = 'SET_PERSIST_VAL';
export const TOGGLE_PERSIST_MISC = 'TOGGLE_PERSIST_MISC';
export const SET_VAL = 'SET_VAL';
export const TOGGLE_MISC = 'TOGGLE_MISC';

// New Message Modal Open and Close actions
export const OPEN_NEW_MESSAGE_MODAL = 'OPEN_NEW_MESSAGE_MODAL';
export const CLOSE_NEW_MESSAGE_MODAL = 'CLOSE_NEW_MESSAGE_MODAL';

// Delete Profile Modal Open and Close actions
export const OPEN_DELETE_PROFILE_MODAL = 'OPEN_DELETE_PROFILE_MODAL';
export const CLOSE_DELETE_PROFILE_MODAL = 'CLOSE_DELETE_PROFILE_MODAL';

// Login Modal Open and Close actions
export const LOGIN_MODAL__UPDATE = 'LOGIN_MODAL__UPDATE';
export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL';
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';

// Signup Modal Open and Close actions
export const OPEN_SIGNUP_MODAL = 'OPEN_SIGNUP_MODAL';
export const CLOSE_SIGNUP_MODAL = 'CLOSE_SIGNUP_MODAL';

// Apply Settings on Front End
export const SET_SITE_SETTINGS_START = 'SET_SITE_SETTINGS_START';
export const SET_SITE_SETTINGS_SUCCESS = 'SET_SITE_SETTINGS_SUCCESS';
export const SET_SITE_SETTINGS_ERROR = 'SET_SITE_SETTINGS_ERROR';

// Send Message
export const SEND_MESSAGE_START = 'SEND_MESSAGE_START';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR';

// Upload Logo
export const LOGO_UPLOAD_LOADER_START = 'LOGO_UPLOAD_LOADER_START';
export const LOGO_UPLOAD_START = 'LOGO_UPLOAD_START';
export const LOGO_UPLOAD_SUCCESS = 'LOGO_UPLOAD_SUCCESS';
export const LOGO_UPLOAD_ERROR = 'LOGO_UPLOAD_ERROR';

// Get Logo
export const GET_LOGO_START = 'GET_LOGO_START';
export const GET_LOGO_SUCCESS = 'GET_LOGO_SUCCESS';
export const GET_LOGO_ERROR = 'GET_LOGO_ERROR';

// Remove Logo
export const REMOVE_LOGO_START = 'REMOVE_LOGO_START';
export const REMOVE_LOGO_SUCCESS = 'REMOVE_LOGO_SUCCESS';
export const REMOVE_LOGO_ERROR = 'REMOVE_LOGO_ERROR';

// Read Messages
export const READ_MESSAGE_START = 'READ_MESSAGE_START';
export const READ_MESSAGE_SUCCESS = 'READ_MESSAGE_SUCCESS';
export const READ_MESSAGE_ERROR = 'READ_MESSAGE_ERROR';

// Upate Banner Form
export const IMAGE_BANNER_UPLOAD_LOADER_START =
  'IMAGE_BANNER_UPLOAD_LOADER_START';
export const UPLOAD_IMAGE_BANNER_START = 'UPLOAD_IMAGE_BANNER_START';
export const UPLOAD_IMAGE_BANNER_SUCCESS = 'UPLOAD_IMAGE_BANNER_SUCCESS';
export const UPLOAD_IMAGE_BANNER_ERROR = 'UPLOAD_IMAGE_BANNER_ERROR';

// Logout
export const USER_LOGOUT_START = 'USER_LOGOUT_START';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';

// Load User Account Data
export const SET_USER_DATA_SUCCESS = 'SET_USER_DATA_SUCCESS';

// // Trust and Verification - Email Verification Confirmation
// export const EMAIL_VERIFICATION_START = 'EMAIL_VERIFICATION_START';
// export const EMAIL_VERIFICATION_SUCCESS = 'EMAIL_VERIFICATION_SUCCESS';
// export const EMAIL_VERIFICATION_ERROR = 'EMAIL_VERIFICATION_ERROR';

// Currency Rates Fetch
export const CURRENCY_RATES_FETCH_START = 'CURRENCY_RATES_FETCH_START';
export const CURRENCY_RATES_FETCH_SUCCESS = 'CURRENCY_RATES_FETCH_SUCCESS';
export const CURRENCY_RATES_FETCH_ERROR = 'CURRENCY_RATES_FETCH_ERROR';

// Select Currency from Dropdown
export const CHOSE_TO_CURRENCY_START = 'CHOSE_TO_CURRENCY_START';
export const CHOSE_TO_CURRENCY_SUCCESS = 'CHOSE_TO_CURRENCY_SUCCESS';
export const CHOSE_TO_CURRENCY_ERROR = 'CHOSE_TO_CURRENCY_ERROR';

// Toggle Menu
export const OPEN_TOGGLE_MENU = 'OPEN_TOGGLE_MENU';
export const CLOSE_TOGGLE_MENU = 'CLOSE_TOGGLE_MENU';

// Report user modal
export const OPEN_REPORT_USER_MODAL = 'OPEN_REPORT_USER_MODAL';
export const CLOSE_REPORT_USER_MODAL = 'CLOSE_REPORT_USER_MODAL';

// Payout - Add
export const ADD_PAYOUT_START = 'Add_PAYOUT_START';
export const ADD_PAYOUT_SUCCESS = 'ADD_PAYOUT_SUCCESS';
export const ADD_PAYOUT_ERROR = 'ADD_PAYOUT_ERROR';

// Payout - Remove
export const REMOVE_PAYOUT_START = 'REMOVE_PAYOUT_START';
export const REMOVE_PAYOUT_SUCCESS = 'REMOVE_PAYOUT_SUCCESS';
export const REMOVE_PAYOUT_ERROR = 'REMOVE_PAYOUT_ERROR';

// Payout - Add
export const SET_DEFAULT_PAYOUT_START = 'SET_DEFAULT_PAYOUT_START';
export const SET_DEFAULT_PAYOUT_SUCCESS = 'SET_DEFAULT_PAYOUT_SUCCESS';
export const SET_DEFAULT_PAYOUT_ERROR = 'SET_DEFAULT_PAYOUT_ERROR';

// Transaction History - Change Payout
export const CHANGE_PAYOUT_START = 'CHANGE_PAYOUT_START';
export const CHANGE_PAYOUT_SUCCESS = 'CHANGE_PAYOUT_SUCCESS';
export const CHANGE_PAYOUT_ERROR = 'CHANGE_PAYOUT_ERROR';

// Contact Expert
export const CONTACT_EXPERT_START = 'CONTACT_EXPERT_START';
export const CONTACT_EXPERT_SUCCESS = 'CONTACT_EXPERT_SUCCESS';
export const CONTACT_EXPERT_ERROR = 'CONTACT_EXPERT_ERROR';

// Hydrate state
export const HYDRATE_STATE = 'HYDRATE_STATE';
