import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';
import AsyncSelect from 'react-select/lib/Async';

import GeocodeUtils from '../../lib/GeocodeUtils';
import { isLocality, isPostalCode } from '../../lib/GoogleGeocodeUtils';

import '../../styles/react-select.module.scss';

import s from './GoogleGeocodeSelect.scss';

function isLocalityOrPostalCodeFilterOption(option, inputValue_unused) {
  // console.log('isLocalityOrPostalCodeFilterOption');
  // console.log('option:', option);
  // console.log('inputValue:', inputValue);

  const googleGeocodeResult = option.data;

  const keep =
    isLocality(googleGeocodeResult) || isPostalCode(googleGeocodeResult);
  // console.log('keep:', keep);
  return keep;
}

function formatOptionLabel(optionData, meta) {
  // console.log('formatOptionLabel');
  // console.log('optionData:', optionData);
  // console.log('meta:', meta);
  const {
    context,
    // inputValue,
    // selectValue,
  } = meta;
  if (context === 'menu') {
    return (
      <span className={cx('react-select__option__label')}>
        {optionData.formatted_address}
      </span>
    );
  }
  return (
    <span className={cx('react-select__single-value__label')}>
      {optionData.formatted_address}
    </span>
  );
}

function getOptionLabel(optionData) {
  // console.log('getOptionLabel');
  // console.log('optionData:', optionData);
  const label = optionData.formatted_address;
  return label;
}

function getOptionValue(optionData) {
  // console.log('getOptionValue');
  // console.log('optionData:', optionData);
  const value = optionData.place_id || optionData.formatted_address;
  return value;
}

function noOptionsMessage({ inputValue }) {
  // console.log('noOptionsMessage');
  // console.log('inputValue:', inputValue);
  if (inputValue.trim().length === 0) {
    return null;
  }
  return `Found no locations matching "${inputValue}".`;
}

function NoDropdownIndicator() {
  return null;
}

function NoIndicatorSeparator() {
  return null;
}

class GoogleGeocodeSelect extends React.PureComponent {
  constructor(props) {
    // console.log('GoogleGeocodeSelect#constructor');
    super(props);

    this.asyncSelectRef = React.createRef();
  }

  handleChange = (option, meta) => {
    // console.log('GoogleGeocodeSelect#handleChange');
    // console.log('option:', option);
    // console.log('meta:', meta);
    /*
    type Action = "clear" | "create-option" | "deselect-option" | "pop-value" | "remove-value" | "select-option" | "set-value";
    */
    const { onSelect } = this.props;
    const { action } = meta;

    if (action === 'select-option') {
      if (typeof onSelect === 'function') {
        onSelect(option);
      }
    }
  };

  handleBlur = evt_unused => {
    // console.log('GoogleGeocodeSelect#handleBlur');
    // console.log('evt:', evt);
    // console.log('this.asyncSelectRef:', this.asyncSelectRef);
    // console.log(
    //   'this.asyncSelectRef.current.select.select.commonProps.getValue():',
    //   this.asyncSelectRef.current.select.select.commonProps.getValue(),
    // );
    // console.log(
    //   'this.asyncSelectRef.current.select.state.value:',
    //   this.asyncSelectRef.current.select.state.value,
    // );

    const { onBlur } = this.props;

    if (typeof onBlur === 'function') {
      // HACK: This is probably not the best way to get the current value of the
      // underlying react-select component.
      onBlur(this.asyncSelectRef.current.select.state.value);
    }
  };

  loadOptions = inputValue =>
    new Promise((resolve, reject) => {
      // console.log('GoogleGeocodeSelect#loadOptions');
      // console.log(`geocoding "${inputValue}"...`);

      const { geocodeOptions } = this.props;

      if (inputValue.trim()) {
        GeocodeUtils.geocode({
          ...geocodeOptions,
          address: inputValue,
        })
          .then(results => {
            // console.log('geocoded.');
            // console.log('results:', results);
            resolve(results);
          })
          .catch(reject);
      } else {
        const results = [];
        // console.log('geocoded.');
        // console.log('results:', results);
        resolve(results);
      }
    });

  render() {
    // console.log('GoogleGeocodeSelect#render');
    const { className, components, ...selectProps } = this.props;
    delete selectProps.geocodeOptions;
    // Don't allow this to be a multi-select, at least not yet.
    if ('isMulti' in selectProps) {
      console.warn(
        '(GoogleGeocodeSelect): Ignoring `isMulti` prop. Not supported.',
      );
      delete selectProps.isMulti;
    }
    delete selectProps.onSelect;
    // console.log('selectProps:', selectProps);
    return (
      <AsyncSelect
        ref={this.asyncSelectRef}
        backspaceRemovesValue={false}
        className={cx('react-select', className, s.root)}
        classNamePrefix="react-select"
        formatOptionLabel={formatOptionLabel}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isSearchable
        noOptionsMessage={noOptionsMessage}
        // Note: The props above are overridable.
        {...selectProps}
        components={{
          ...components,
          DropdownIndicator: NoDropdownIndicator,
          IndicatorSeparator: NoIndicatorSeparator,
        }}
        loadOptions={this.loadOptions}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        // menuIsOpen // Enable this prop to see menu in dev tools.
      />
    );
  }
}

GoogleGeocodeSelect.propTypes = {
  className: PTs.string,
  components: PTs.shape({}),
  // See https://www.npmjs.com/package/geocoding. All options for forward geocoding
  // except `address` and `key`.
  geocodeOptions: PTs.shape({
    bounds: PTs.shape({
      northeast: PTs.shape({
        lat: PTs.number,
        lng: PTs.number,
      }),
      southwest: PTs.shape({
        lat: PTs.number,
        lng: PTs.number,
      }),
    }),
    components: PTs.shape({
      administrative_area: PTs.string,
      country: PTs.string,
      locality: PTs.string,
      postal_code: PTs.string,
      route: PTs.string,
    }),
    // The region code, specified as a ccTLD two-character value.
    region: PTs.string,
  }),
  onBlur: PTs.func,
  onSelect: PTs.func,
};

GoogleGeocodeSelect.defaultProps = {
  className: '',
  components: {},
  geocodeOptions: undefined,
  onBlur: null,
  onSelect: null,
};

export { isLocalityOrPostalCodeFilterOption };

export default withStyles(s)(GoogleGeocodeSelect);
