/* eslint-disable no-console */
import UniversalRouter from 'universal-router';

import routes from './routes';

export default new UniversalRouter(routes, {
  resolveRoute(context, params) {
    const { route } = context;
    if (typeof route.load === 'function') {
      return route.load(context, params).then(importedModule => {
        if (typeof importedModule.default !== 'function') {
          console.warn('importedModule.default is not a function');
          console.log('process.env.BROWSER:', process.env.BROWSER);
          console.log('context:', context);
          console.log('params:', params);
          console.log('importedModule:', importedModule);
          // Let code continue and throw the error we would normally get. We at least have
          // logged some more information to help us solve this issue.

          // Seeing what happens if we return undefined in this case.
          return undefined;
        }
        return importedModule.default(context, params);
      });
    }
    if (typeof route.action === 'function') {
      return route.action(context, params);
    }
    return undefined;
  },
});
