/*::
type ActionError = {
  key: string,
  value: {
    code: string, // Error code
    msgId: string, // Message ID
  },
}

type ActionResult = {
  errors?: Array<ActionError>,
  data: any,
  status: 'failed' | 'success' | string,
}
*/

function doesActionHaveError(actionResult, key, errorCode, messageId) {
  let isFound = false;
  const assertErrorCode = typeof errorCode === 'string';
  const assertMessageId = typeof messageId === 'string';

  if (actionResult && Array.isArray(actionResult.errors)) {
    const { errors } = actionResult;
    if (assertErrorCode && assertMessageId) {
      isFound =
        errors.findIndex(
          error =>
            error.key === key &&
            error.value &&
            error.value.code === errorCode &&
            error.value.msgId === messageId,
        ) !== -1;
    } else if (assertErrorCode) {
      isFound =
        errors.findIndex(
          error =>
            error.key === key && error.value && error.value.code === errorCode,
        ) !== -1;
    } else if (assertMessageId) {
      isFound =
        errors.findIndex(
          error =>
            error.key === key && error.value && error.value.msgId === messageId,
        ) !== -1;
    } else {
      isFound = errors.findIndex(error => error.key === key) !== -1;
    }
  }
  return isFound;
}

function didActionFail(actionResult) {
  const isMatch = actionResult && actionResult.status === 'failed';
  return isMatch;
}

function didActionSucceed(actionResult) {
  const isMatch = actionResult && actionResult.status === 'success';
  return isMatch;
}

function doesActionHaveStatus(actionResult, status) {
  const isMatch = actionResult && actionResult.status === status;
  return isMatch;
}

function normalizeActionResult(actionResult) {
  if (
    actionResult &&
    actionResult.status !== 'failed' &&
    Array.isArray(actionResult.errors) &&
    actionResult.errors.length === 0
  ) {
    // eslint-disable-next-line no-param-reassign
    actionResult.errors = null;
  }
}

function pushError(actionResult, key, errorCode) {
  let added = false;

  if (actionResult && Array.isArray(actionResult.errors)) {
    const { errors } = actionResult;
    const hasErrWithSameKey =
      errors.findIndex(
        error =>
          error.key === key &&
          (error.value.code === errorCode || error.value.msgId === errorCode),
      ) !== -1;

    if (!hasErrWithSameKey) {
      errors.push({
        key,
        value: {
          code: errorCode,
          msgId: errorCode,
        },
      });
      added = true;
    }
  }

  return added;
}

function pushUnauthenticatedError(actionResult, key = 'Unauthenticated') {
  const added = pushError(actionResult, key, 'errors.unauthenticated');
  return added;
}

function pushUnexpectedError(actionResult, key = 'Unexpected') {
  const added = pushError(actionResult, key, 'errors.unexpected');
  return added;
}

export {
  didActionFail,
  didActionSucceed,
  doesActionHaveError,
  doesActionHaveStatus,
  normalizeActionResult,
  pushUnauthenticatedError,
  pushUnexpectedError,
};

const ActionUtils = {
  failed: didActionFail,
  hasError: doesActionHaveError,
  hasStatus: doesActionHaveStatus,
  normalizeActionResult,
  pushUnauthenticatedError,
  pushUnexpectedError,
  succeeded: didActionSucceed,
};

export default ActionUtils;
