import withStyles from 'isomorphic-style-loader/lib/withStyles';
import uniqueId from 'lodash/uniqueId';
import PTs from 'prop-types';
import FormUtils from 'qc-dom_utils/lib/cjs/form';
import ReduxFormUtils from 'qc-redux-form_utils/lib/cjs';
import { toInt } from 'qc-to_int';
import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Form from 'react-bootstrap/lib/Form';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Row from 'react-bootstrap/lib/Row';

import { injectIntl, intlShape } from 'react-intl';
import { Field, propTypes, reduxForm, SubmissionError } from 'redux-form';

import ShadowerButton from '../ShadowerButton';

import DataUtils from '../../lib/DataUtils';
import DateUtils from '../../lib/DateUtils';

import messages from '../../locale/messages';

import { register } from '../../state/actions/AnonymousUserActions';

import validate from './validate';

import s from './RegisterForm.scss';

// TODO: Rename to RegistrationForm.
class RegisterForm extends React.Component {
  static propTypes = {
    ...propTypes,
    error: PTs.shape({
      defaultMessage: PTs.string,
      id: PTs.string,
    }),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      dateOfBirthData: {},
    };
  }

  componentWillMount() {
    const now = new Date();
    const currentYear = now.getFullYear();
    const years = DataUtils.genInts(1920, currentYear, 'desc');
    const days = DataUtils.genInts(1, 31);
    const months = DataUtils.genInts(1, 12);
    this.setState({
      dateOfBirthData: {
        years,
        months,
        days,
      },
    });
  }

  renderFormControl = ({
    type,
    className,
    input,
    label,
    meta: { error, touched },
  }) => {
    const { intl } = this.props;
    return (
      <div>
        {touched && error && (
          <span className={s.errorMessage}>{intl.formatMessage(error)}</span>
        )}
        <FormControl
          {...input}
          className={className}
          placeholder={label}
          type={type}
        />
      </div>
    );
  };

  renderFormControlSelect = ({ children, input }) => (
    <div>
      <FormControl componentClass="select" {...input}>
        {children}
      </FormControl>
    </div>
  );

  render() {
    const { error, handleSubmit, intl, submitting } = this.props;
    const { dateOfBirthData } = this.state;

    return (
      <Form onSubmit={handleSubmit}>
        {error && (
          <span className={s.errorMessage}>{intl.formatMessage(error)}</span>
        )}
        <FormGroup className={s.formGroup}>
          <Field
            component={this.renderFormControl}
            label={intl.formatMessage(messages.firstName)}
            name="firstName"
            type="text"
          />
        </FormGroup>
        <FormGroup className={s.formGroup}>
          <Field
            component={this.renderFormControl}
            label={intl.formatMessage(messages.lastName)}
            name="lastName"
            type="text"
          />
        </FormGroup>
        <FormGroup className={s.formGroup}>
          <Field
            component={this.renderFormControl}
            label={intl.formatMessage(messages.email)}
            name="email"
            type="text"
          />
        </FormGroup>
        <FormGroup className={s.formGroup}>
          <Field
            component={this.renderFormControl}
            label={intl.formatMessage(messages.password)}
            name="password"
            type="password"
          />
        </FormGroup>

        <div className={s.birthdayContainer}>
          <Row>
            <Col lg={12}>
              <ControlLabel>
                {intl.formatMessage(messages.birthDay)}
              </ControlLabel>
            </Col>
            <Col xs={5}>
              <FormGroup>
                <Field
                  component={this.renderFormControlSelect}
                  name="month"
                  parse={val =>
                    // eslint-disable-next-line no-restricted-globals
                    isNaN(toInt(val, null)) ? null : toInt(val, null)
                  }
                >
                  <option value="">{intl.formatMessage(messages.month)}</option>
                  {dateOfBirthData.months.map(item => (
                    <option key={uniqueId()} value={item}>
                      {item}
                    </option>
                  ))}
                </Field>
              </FormGroup>
            </Col>

            <Col className="padding-none" xs={3}>
              <FormGroup>
                <Field
                  component={this.renderFormControlSelect}
                  name="day"
                  parse={val =>
                    // eslint-disable-next-line no-restricted-globals
                    isNaN(toInt(val, null)) ? null : toInt(val, null)
                  }
                >
                  <option value="">{intl.formatMessage(messages.day)}</option>
                  {dateOfBirthData.days.map(item => (
                    <option key={uniqueId()} value={item}>
                      {item}
                    </option>
                  ))}
                </Field>
              </FormGroup>
            </Col>

            <Col xs={4}>
              <FormGroup>
                <Field
                  component={this.renderFormControlSelect}
                  name="year"
                  parse={val =>
                    // eslint-disable-next-line no-restricted-globals
                    isNaN(toInt(val, null)) ? null : toInt(val, null)
                  }
                >
                  <option value="">{intl.formatMessage(messages.year)}</option>
                  {dateOfBirthData.years.map(item => (
                    <option key={uniqueId()} value={item}>
                      {item}
                    </option>
                  ))}
                </Field>
              </FormGroup>
            </Col>
          </Row>
        </div>

        <FormGroup className={s.formGroup}>
          <div className={s.signUpContainer}>
            <ShadowerButton disabled={submitting} type="submit">
              {intl.formatMessage(messages.signUp)}
            </ShadowerButton>
          </div>
        </FormGroup>
      </Form>
    );
  }
}

const FORM_NAME = 'RegisterForm';

const RF = reduxForm({
  form: FORM_NAME,
  onSubmit: async (values, dispatch, props) => {
    let registration = null;
    let st = 'failed';
    const { dirty } = props;

    const { year, month, day } = values;

    // Note: Because the DOB is set across three fields, it is awkward to set a field
    // validation error. Need to set a "_error" error. However, ReduxForm does not
    // seem to recognize "_error" errors from the `validate` function. Hence the
    // reason the DOB validation is done here.
    if (!month || !day || !year) {
      throw new SubmissionError({ _error: messages.birthDayRequired });
    }

    if (year && month && day) {
      if (!DateUtils.isValidDate(year, Number(month) - 1, day)) {
        throw new SubmissionError({ _error: messages.WrongDayChosen });
      }
      const now = new Date();
      const currYear = now.getFullYear();
      const yearDiff = currYear - year;

      if (yearDiff < 18) {
        throw new SubmissionError({ _error: messages.mustBe18OrOld });
      }
      if (yearDiff === 18) {
        const currMonth = now.getMonth();
        if (currMonth < Number(month) - 1) {
          throw new SubmissionError({ _error: messages.mustBe18OrOld });
        }
        if (Number(month) - 1 === currMonth) {
          const currDay = now.getDate();
          if (currDay < Number(day)) {
            throw new SubmissionError({ _error: messages.mustBe18OrOld });
          }
        }
      }
    }

    if (dirty) {
      const dob = { year, month: Number(month), day };
      const { email, firstName, lastName, password, refer } = values;

      registration = {
        firstName,
        lastName,
        email,
        password,
        dob,
        refer,
      };
      const result = await dispatch(register(registration));
      const { status } = result;
      if (status === 'pending' || status === 'success') {
        st = status;
      } else if (status === 'dupemail') {
        throw new SubmissionError({ _error: messages.emailAlreadyExists });
      } else {
        throw new SubmissionError({ _error: messages.somethingWentWrong });
      }
    }
    return { registration, status: st };
  },
  onSubmitFail: (error, dispatch_unused, submitError_unused, props_unused) => {
    FormUtils.focusFirstInvalid(FORM_NAME, ReduxFormUtils.flattenErrors(error));
  },
  touchOnBlur: false,
  // touchOnChange: true,
  validate,
})(RegisterForm);

export default injectIntl(withStyles(s)(RF));
