import { SET_USER_DATA_SUCCESS, USER_LOGOUT_SUCCESS } from '../actions';

/*::
type AccountState = {
  data: ?UserAccount,
}
*/

const initialState /*: AccountState */ = {
  data: null,
};

export default function account(state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
}
