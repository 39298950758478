import { SET_VAL, TOGGLE_MISC } from '../actions';

/*::
type MiscState = {
  // Determines whether to show the dropdown menu in the main navigation.
  displayProfileDropDown: boolean,
  // Determines whether the page is currently visible according to the Page
  // Visibility API. See https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
  // for details.
  isPageVisible: boolean,
}
*/

const initialState = {
  displayProfileDropDown: false,
  isPageVisible: true,
};

export default function misc(state = initialState, action) {
  switch (action.type) {
    case SET_VAL:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case TOGGLE_MISC:
      return {
        ...state,
        [action.payload]: !state[action.payload],
      };
    default:
      break;
  }
  return state;
}
