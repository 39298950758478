export const VALID_FIELD_TYPES = Object.freeze([
  'checkbox',
  'color',
  'date',
  'email',
  'file',
  'google-geocode',
  'hidden',
  'integer',
  'markdown',
  'month',
  'number',
  'password',
  'radio',
  'range',
  'rater',
  'search',
  'select',
  'tel',
  'text',
  'textarea',
  'time',
  'url',
  'us-phone-10',
  'week',
  'zip-code',
  'zip-code-5',
  'zip-code-plus-4',
]);
