import gql from 'graphql-tag';
import { Log } from 'qc-log_api';

import { is401Error } from '../../lib/ApolloErrorUtils';
import { normalizeRecoverableErrors } from '../../lib/GraphQlUtils';

import {
  normalizeActionResult,
  pushUnauthenticatedError,
  pushUnexpectedError,
} from './lib/ActionUtils';

import { SET_USER_DATA_SUCCESS } from './';

const ACTIONS_LOG = Log.Factory.get('account.actions');

function setAccount(account) {
  return {
    type: SET_USER_DATA_SUCCESS,
    payload: account,
  };
}

const deactivateAccountMutation = gql`
  mutation {
    deactivateAccount {
      errors {
        key
        value {
          code
          msgId
          valuesJson
        }
      }
      pending {
        payouts
      }
      status
    }
  }
`;

function deactivateAccount() {
  /**
   * @returns {Promise<ActionResult, undefined>}
   */
  return async (dispatch, getState, { graphqlClient }) => {
    const actionResult = {
      status: 'failed',
    };

    try {
      const { data } = await graphqlClient.mutate({
        mutation: deactivateAccountMutation,
      });
      if (data) {
        const result = data.deactivateAccount;
        actionResult.errors = normalizeRecoverableErrors(result.errors);
        actionResult.data = { pending: result.pending };
        actionResult.status = result.status;
      }
    } catch (e) {
      if (is401Error(e)) {
        pushUnauthenticatedError(actionResult);
      } else {
        ACTIONS_LOG.error(e);
        pushUnexpectedError(actionResult);
      }
    }

    normalizeActionResult(actionResult);

    return actionResult;
  };
}

const getUserAccountQuery = gql`
  query getUserAccount {
    userAccount {
      record {
        aboutYou
        address
        publicAddress: address(variation: public)
        displayName
        firstName
        lastName
        joined {
          year
          month
          day
        }
        lat
        lng
        payPalLinked
        profilePhotoTh: profilePhoto(size: th) {
          alt
          src
        }
        profilePhotoId
        profilePhotoTypeCode
        slug
        stripeLinked
        userId
        authLocal {
          email
        }
        verifiedInfo {
          isEmailConfirmed
          # isFacebookConnected
          # isGoogleConnected
          # isIdVerification
        }
      }
      status
    }
  }
`;

function loadAccount() {
  /**
   * @returns {Promise<ActionResult, undefined>}
   */
  return async (dispatch, getState_unused, { graphqlClient }) => {
    const actionResult = {
      status: 'failed',
    };

    try {
      const { data } = await graphqlClient.query({
        // Make sure we get the latest data for viewing and editing.
        fetchPolicy: 'network-only',
        query: getUserAccountQuery,
      });
      if (data && data.userAccount && data.userAccount.status === 'success') {
        actionResult.data = { userAccount: data.userAccount.record };
        actionResult.status = 'success';
      }
    } catch (e) {
      if (is401Error(e)) {
        pushUnauthenticatedError(actionResult);
      } else {
        ACTIONS_LOG.error(e);
        pushUnexpectedError(actionResult);
      }
    }

    normalizeActionResult(actionResult);

    if (actionResult.status === 'success') {
      dispatch(setAccount(actionResult.data.userAccount));
    }

    return actionResult;
  };
}

export { deactivateAccount, loadAccount };

const AccountActions = {
  deactivate: deactivateAccount,
  load: loadAccount,
};

export default AccountActions;
