import { SET_POPULAR_SESSION_LOCATIONS } from '../actions';

const initState = {
  popular: {
    collection: /*: SessionLocation[] */ [],
  },
};

export default function sessionLocationsReducer(state = initState, action) {
  switch (action.type) {
    case SET_POPULAR_SESSION_LOCATIONS:
      return {
        ...state,
        popular: {
          ...state.popular,
          collection: [...action.payload.popularSessionLocations],
        },
      };
    default:
      break;
  }
  return state;
}
