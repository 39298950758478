export function toExpertCountText(expertCount) {
  // TODO: Make work off an array.
  let text;
  if (expertCount > 500) {
    text = '500+ experts';
  } else if (expertCount > 400) {
    text = '400+ experts';
  } else if (expertCount > 300) {
    text = '300+ experts';
  } else if (expertCount > 200) {
    text = '200+ experts';
  } else if (expertCount > 100) {
    text = '100+ experts';
  } else if (expertCount > 80) {
    text = '80+ experts';
  } else if (expertCount > 60) {
    text = '60+ experts';
  } else if (expertCount > 40) {
    text = '40+ experts';
  } else if (expertCount > 30) {
    text = '30+ experts';
  } else if (expertCount > 20) {
    text = '20+ experts';
  } else if (expertCount > 10) {
    text = '10+ experts';
  } else if (expertCount > 1) {
    text = `${expertCount} experts`;
  } else if (expertCount === 1) {
    text = '1 expert';
  } else {
    text = 'Experts coming soon';
  }
  return text;
}

export function toTotalExpertCountText(expertCount) {
  // TODO: Make work off an array.
  let text;
  if (expertCount > 5000) {
    text = '5000+ total experts';
  } else if (expertCount > 4000) {
    text = '4000+ total experts';
  } else if (expertCount > 3000) {
    text = '3000+ total experts';
  } else if (expertCount > 2000) {
    text = '2000+ total experts';
  } else if (expertCount > 1000) {
    text = '1000+ total experts';
  } else if (expertCount > 500) {
    text = '500+ total experts';
  } else if (expertCount > 400) {
    text = '400+ total experts';
  } else if (expertCount > 300) {
    text = '300+ total experts';
  } else if (expertCount > 200) {
    text = '200+ total experts';
  } else if (expertCount > 100) {
    text = '100+ total experts';
  } else if (expertCount > 80) {
    text = '80+ total experts';
  } else if (expertCount > 60) {
    text = '60+ total experts';
  } else if (expertCount > 40) {
    text = '40+ total experts';
  } else if (expertCount > 30) {
    text = '30+ total experts';
  } else if (expertCount > 20) {
    text = '20+ total experts';
  } else if (expertCount > 10) {
    text = '10+ total experts';
  } else if (expertCount > 1) {
    text = `${expertCount} total experts`;
  } else if (expertCount === 1) {
    text = `${expertCount} expert`;
  } else {
    text = 'Experts coming soon';
  }
  return text;
}

export function expertiseFieldEdgeToAreaOfExpertise(
  expertiseFieldEdge,
  parentAreaOfExpertise,
  appRoot,
) {
  const { expertCount, favorite, node, shadowingCount } = expertiseFieldEdge;
  const {
    desc,
    // descMsgId,
    // expertiseCategoryId,
    id,
    img,
    name,
    nameMsgId,
    slug,
  } = node;
  const areaOfExpertise = {
    children: null,
    desc,
    expertCount,
    favorite,
    id,
    img,
    link: {
      to: `${appRoot}shadowings/areas-of-expertise/${
        parentAreaOfExpertise.slug
      }/${slug}/`,
    },
    parent: parentAreaOfExpertise,
    shadowingCount,
    text: toExpertCountText(expertCount),
    title: name,
    titleMsgId: nameMsgId,
  };
  return areaOfExpertise;
}

export function expertiseCategoryEdgeToAreaOfExpertise(
  expertiseCategoryEdge,
  appRoot,
) {
  const { expertCount, favorite, node, shadowingCount } = expertiseCategoryEdge;
  const {
    desc,
    // descMsgId,
    fieldsConnection,
    id,
    img,
    name,
    nameMsgId,
    slug,
  } = node;
  const areaOfExpertise = {
    children: null,
    desc,
    expertCount,
    favorite,
    id,
    img,
    link: { to: `${appRoot}shadowings/areas-of-expertise/${slug}/` },
    parent: null,
    shadowingCount,
    text: toExpertCountText(expertCount),
    title: name,
    titleMsgId: nameMsgId,
  };
  areaOfExpertise.slug = slug; // HACK: To pass slug on through.
  areaOfExpertise.children = fieldsConnection.edges.map(expertiseFieldEdge => {
    const expertiseField = expertiseFieldEdgeToAreaOfExpertise(
      expertiseFieldEdge,
      areaOfExpertise,
      appRoot,
    );
    return expertiseField;
  });
  delete areaOfExpertise.slug;
  return areaOfExpertise;
}

export function genInts(start, end, order) {
  const data = [];
  if (order === 'desc') {
    for (let i = end; i >= start; i--) {
      data.push(i);
    }
  } else {
    for (let i = start; i <= end; i++) {
      data.push(i);
    }
  }

  return data;
}

export function genTimes() {
  // TODO: Hardcoding this for now was the quickest way to get some data.
  // Add parameters to make it possible to generate various groups of times.
  return [
    '00:00',
    '00:15',
    '00:30',
    '00:45',
    '01:00',
    '01:15',
    '01:30',
    '01:45',
    '02:00',
    '02:15',
    '02:30',
    '02:45',
    '03:00',
    '03:15',
    '03:30',
    '03:45',
    '04:00',
    '04:15',
    '04:30',
    '04:45',
    '05:00',
    '05:15',
    '05:30',
    '05:45',
    '06:00',
    '06:15',
    '06:30',
    '06:45',
    '07:00',
    '07:15',
    '07:30',
    '07:45',
    '08:00',
    '08:15',
    '08:30',
    '08:45',
    '09:00',
    '09:15',
    '09:30',
    '09:45',
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45',
    '13:00',
    '13:15',
    '13:30',
    '13:45',
    '14:00',
    '14:15',
    '14:30',
    '14:45',
    '15:00',
    '15:15',
    '15:30',
    '15:45',
    '16:00',
    '16:15',
    '16:30',
    '16:45',
    '17:00',
    '17:15',
    '17:30',
    '17:45',
    '18:00',
    '18:15',
    '18:30',
    '18:45',
    '19:00',
    '19:15',
    '19:30',
    '19:45',
    '20:00',
    '20:15',
    '20:30',
    '20:45',
    '21:00',
    '21:15',
    '21:30',
    '21:45',
    '22:00',
    '22:15',
    '22:30',
    '22:45',
    '23:00',
    '23:15',
    '23:30',
    '23:45',
  ];
}

const DataUtils = {
  genInts,
  genTimes,
};

export default DataUtils;
