exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3JFyz{margin-bottom:6px}._3JFyz ._2tbSo{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;margin-top:var(--block--spacing--sm);margin-top:var(--block--spacing--sm)}._3JFyz input{-webkit-box-shadow:none !important;box-shadow:none !important;border:0;border-bottom:1px solid var(--neutral--200);border-bottom:1px solid var(--neutral--200);border-radius:0}._3JFyz input:focus, ._3JFyz input:active{-webkit-box-shadow:0 !important;box-shadow:0 !important;outline:none !important}._3JFyz input::-webkit-input-placeholder{color:var(--neutral--600);color:var(--neutral--600)}._3JFyz input::-moz-placeholder{color:var(--neutral--600);color:var(--neutral--600)}._3JFyz input:-ms-input-placeholder{color:var(--neutral--600);color:var(--neutral--600)}._3JFyz input::-ms-input-placeholder{color:var(--neutral--600);color:var(--neutral--600)}._3JFyz input::placeholder{color:var(--neutral--600);color:var(--neutral--600)}._2zpkS{margin-bottom:5px;display:block;color:red;font-size:14px;font-weight:normal}._1nB7Q label{margin-top:5px;font-weight:300;color:var(--neutral--800);color:var(--neutral--800)}", ""]);

// exports
exports.locals = {
	"formGroup": "_3JFyz",
	"signUpContainer": "_2tbSo",
	"errorMessage": "_2zpkS",
	"birthdayContainer": "_1nB7Q"
};