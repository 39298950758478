exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Ttke, ._1y84S, ._2_oqx, ._8dhxo, .CdqIk{padding:10px 20px;font-size:16px;font-size:1rem;border-radius:30px;font-weight:300;border:0;margin:0;min-width:130px;text-align:center}._2Ttke{background:var(--primary--400);background:var(--primary--400);color:#fff;border:1px solid var(--primary--400);border:1px solid var(--primary--400)}._2Ttke:hover{color:#fff;opacity:.8}._1y84S{background:#fff;color:var(--primary--400);color:var(--primary--400);-webkit-box-shadow:var(--base--box-shadow);box-shadow:var(--base--box-shadow);box-shadow:var(--base--box-shadow)}._2_oqx{background:rgba(0,0,0,0);color:var(--primary--400);color:var(--primary--400);border:1px solid var(--primary--400);border:1px solid var(--primary--400)}._2_oqx:hover{opacity:.8}._8dhxo{background:rgba(0,0,0,0);color:var(--neutral--700);color:var(--neutral--700)}._8dhxo:hover{opacity:.8;color:var(--neutral--700);color:var(--neutral--700)}.CdqIk{background:var(--neutral--200);background:var(--neutral--200);color:var(--neutral--600);color:var(--neutral--600)}.CdqIk:hover{color:var(--neutral--600);color:var(--neutral--600)}", ""]);

// exports
exports.locals = {
	"defaultLook": "_2Ttke",
	"inverseLook": "_1y84S",
	"outlineLook": "_2_oqx",
	"cancelLook": "_8dhxo",
	"bookedLook": "CdqIk"
};