import { createBrowserHistory } from 'history';

import config from '../universal/config/client-config';

let browserHistory;

if (process.env.BROWSER) {
  browserHistory = createBrowserHistory();
  const origPush = browserHistory.push;

  browserHistory.push = inPath => {
    let outPath = inPath;
    if (inPath[0] !== '/') {
      outPath = config.app.root + inPath;
    }
    return origPush.call(browserHistory, outPath);
  };
}

const history = browserHistory;

// Navigation manager, e.g. history.push('/home')
// https://github.com/mjackson/history
export default history;
