import _formatDate from 'date-fns/format';
import { toNum } from 'qc-to_num';
import { typeOf } from 'qc-type_of';

import DateUtils from './DateUtils';

const noOp = () => undefined;

const MATCHES_VALIDATOR_CACHE = new Map();

/**
 * Returns a validator that matches a value against the specified pattern and
 * returns the specified error ID when the value does not match.
 *
 * Note: The validator is cached/memoized so that a different validator is not
 * returned when the same arguments are given. This is especially useful when
 * using with the `validators` property of `Field` component from the `redux-form`
 * library/package.
 *
 * @param {RegExp|string} pattern - The pattern the value must match.
 * @param {string} [customErrId] - An optional custom error ID to use in place of
 *   `'validators.value.does_not_match'`.
 *
 * @returns {Function} A validator for the corresponding arguments.
 */
export function matches(pattern, customErrId) {
  if (!pattern) {
    return noOp;
  }

  const re = RegExp(pattern);
  let validatorLut = MATCHES_VALIDATOR_CACHE.get(re);

  if (!validatorLut) {
    validatorLut = {};
    MATCHES_VALIDATOR_CACHE.set(re, validatorLut);
  }

  const key = customErrId || 'validators.value.does_not_match';
  let validator = validatorLut[key];
  if (!validator) {
    validator = value => {
      let errId;
      const typeOfValue = typeOf(value);

      if (
        typeOfValue === 'boolean' ||
        typeOfValue === 'number' ||
        typeOfValue === 'string'
      ) {
        const val = `${value}`.trim();
        if (!re.test(val)) {
          errId = customErrId || 'validators.value.does_not_match';
        }
      }

      return errId;
    };
    validatorLut[key] = validator;
  }

  return validator;
}

const LENGTH_VALIDATOR_CACHE = {};

/**
 * Returns a validator that matches the range defined by the two arguments.
 *
 * Note: Passing non-number values for both arguments is allowed. In this case,
 * a no-op validator is returned. This can make the function easier to use since
 * the calling code does not have to check whether at least one length argument
 * is a number.
 *
 * Note: The validator is cached/memoized so that a different validator is not
 * returned when the same arguments are given. This is especially useful when
 * using with the `validators` property of `Field` component from the `redux-form`
 * library/package.
 *
 * @param {int} [minLength] - An optional minimum length of the value. Defaults to
 *   being unbounded.
 * @param {int} [maxLength] - An optional maximum length of the value. Defaults to
 *   being unbounded.
 *
 * @returns {Function} A validator for the corresponding arguments.
 *
 * @throws {TypeError} If `minLength` is less than 0.
 * @throws {TypeError} If `maxLength` is less than 0.
 * @throws {TypeError} If `maxLength` is less than `minLength`.
 */
export function hasLenInRange(minLength, maxLength) {
  let key;
  let type;

  if (typeOf(minLength) === 'number') {
    if (minLength < 0) {
      throw TypeError('`minLength` must be non-negative.');
    }
    if (typeOf(maxLength) === 'number') {
      if (maxLength < 0) {
        throw TypeError('`minLength` must be non-negative.');
      }
      if (maxLength < minLength) {
        throw TypeError('`maxLength` must not be less than `minLength`.');
      }
      key = `${minLength}:${maxLength}`;
      type = 'between';
    } else {
      key = `${minLength}:`;
      type = 'gte';
    }
  } else if (typeOf(maxLength) === 'number') {
    if (maxLength < 0) {
      throw TypeError('`minLength` must be non-negative.');
    }
    key = `:${maxLength}`;
    type = 'lte';
  } else {
    return noOp;
  }

  let validator = LENGTH_VALIDATOR_CACHE[key];

  if (!validator) {
    validator = value => {
      let errId;
      const typeOfValue = typeOf(value);

      if (
        typeOfValue === 'boolean' ||
        typeOfValue === 'number' ||
        typeOfValue === 'string'
      ) {
        const val = `${value}`.trim();
        switch (type) {
          case 'between':
            if (val.length === 0) {
              errId = 'validators.value.has_length_not_between';
            } else if (val.length < minLength) {
              errId = 'validators.value.has_length_lt';
            } else if (val.length > maxLength) {
              errId = 'validators.value.has_length_gt';
            }
            break;
          case 'gte':
            if (val.length < minLength) {
              errId = 'validators.value.has_length_lt';
            }
            break;
          default:
            if (val.length > maxLength) {
              errId = 'validators.value.has_length_gt';
            }
            break;
        }
      }

      return errId;
    };
    LENGTH_VALIDATOR_CACHE[key] = validator;
  }
  return validator;
}

// export function isDateAfter(minDate) {
//   // TODO: Implement.
//   // Not inclusive.
//   // let errId = 'validators.value.is_date_not_after';

//   if (!minDate) {
//     return noOp;
//   }

//   const validator = noOp;
//   return validator;
// }

// export function isDateBefore(maxDate) {
//   // TODO: Implement.
//   // Not inclusive.
//   // let errId = 'validators.value.is_date_not_before';

//   if (!maxDate) {
//     return noOp;
//   }

//   const validator = noOp;
//   return validator;
// }

const IS_DATE_BETWEEN_VALIDATOR_CACHE = {};

/**
 * Returns a validator that matches the range defined by the first two arguments.
 *
 * Note: Passing non-date-like values for both arguments is allowed. In this case,
 * a no-op validator is returned. This can make the function easier to use since
 * the calling code does not have to check whether at least one date argument
 * is date-like.
 *
 * Note: The validator is cached/memoized so that a different validator is not
 * returned when the same arguments are given. This is especially useful when
 * using with the `validators` property of `Field` component from the `redux-form`
 * library/package.
 *
 * @param {DateLike} [min] - An optional minimum date of the value. Defaults to
 *   being unbounded.
 * @param {DateLike} [max] - An optional maximum date of the value. Defaults to
 *   being unbounded.
 * @param {string|string[]} format - The expected date format(s). Used when
 *   parsing a string-based date.
 *
 * @returns {Function} A validator for the corresponding arguments.
 *
 * @throws {TypeError} If `max` is less than `min`.
 */
export function isDateOnlyBetween(min, max, format) {
  const maxDate = DateUtils.toDate(max, null);
  const minDate = DateUtils.toDate(min, null);
  if (minDate === null && maxDate === null) {
    return noOp;
  }

  if (minDate && maxDate) {
    if (minDate.getTime() > maxDate.getTime()) {
      throw TypeError('`max` must not be less than `min`.');
    }
  }

  let key;
  let type;

  if (minDate) {
    if (maxDate) {
      key = [
        format,
        _formatDate(minDate, 'YYYY-MM-DD'),
        _formatDate(maxDate, 'YYYY-MM-DD'),
      ].join(':');
      type = 'between';
    } else {
      key = [format, _formatDate(minDate, 'YYYY-MM-DD'), '9999-12-31'].join(
        ':',
      );
      type = 'gte';
    }
  } else if (maxDate) {
    key = [format, '-9999-01:01', _formatDate(maxDate, 'YYYY-MM-DD')].join(':');
    type = 'lte';
  }

  let validator = IS_DATE_BETWEEN_VALIDATOR_CACHE[key];

  if (!validator) {
    validator = value => {
      let errId;
      const val = DateUtils.toDate(value, null);

      if (val !== null) {
        const year = val.getFullYear();
        const month = val.getMonth() + 1;
        const day = val.getDate();
        if (type === 'between') {
          const minYear = minDate.getFullYear();
          const maxYear = maxDate.getFullYear();
          const minMonth = minDate.getMonth() + 1;
          const maxMonth = maxDate.getMonth() + 1;
          const minDay = minDate.getDate();
          const maxDay = maxDate.getDate();

          if (
            year < minYear ||
            year > maxYear ||
            month < minMonth ||
            month > maxMonth ||
            day < minDay ||
            day > maxDay
          ) {
            errId = 'validators.value.is_date_not_between';
          }
        } else if (type === 'gte') {
          const minYear = minDate.getFullYear();
          const minMonth = minDate.getMonth() + 1;
          const minDay = minDate.getDate();
          if (year < minYear || month < minMonth || day < minDay) {
            errId = 'validators.value.is_date_lt';
          }
        } else if (type === 'lte') {
          const maxYear = maxDate.getFullYear();
          const maxMonth = maxDate.getMonth() + 1;
          const maxDay = maxDate.getDate();

          if (year > maxYear || month > maxMonth || day > maxDay) {
            errId = 'validators.value.is_date_gt';
          }
        }
      }

      return errId;
    };
    IS_DATE_BETWEEN_VALIDATOR_CACHE[key] = validator;
  }
  return validator;
}

// export function isBetweenThese(minFieldName_unused, maxFieldName_unused) {
//   // TODO: Implement.
//   // let errId = 'validators.value.is_not_between_these';
// }

// export function isBoolean(value_unused) {
//   // TODO: Implement.
//   // let errId = 'validators.value.is_not_boolean';
// }

const IS_DATE_VALIDATOR_CACHE = {};

/**
 * Returns a validator that matches the given date format(s).
 *
 * The validator checks whether the given value can be interpreted as a date.
 *
 * Note: The validator is cached/memoized so that a different validator is not
 * returned when the same arguments are given. This is especially useful when
 * using with the `validators` property of `Field` component from the `redux-form`
 * library/package.
 *
 * @param {string|string[]} format - The expected date format(s). Used when
 *   parsing a string-based date.
 *
 * @returns {Function} A validator for the corresponding argument.
 */
export function isDate(format) {
  // FIXME: TODO: Do something appropriate with `format`.
  const key = format;

  let validator = IS_DATE_VALIDATOR_CACHE[key];

  if (!validator) {
    validator = value => {
      let errId;
      const date = DateUtils.toDate(value, null);
      if (date === null) {
        errId = 'validators.value.is_not_date';
      }
      return errId;
    };
    IS_DATE_VALIDATOR_CACHE[key] = validator;
  }

  return validator;
}

// An email may be no shorter than 6 characters total. It may also be no longer
// than 320 characters total. The part before the `@` may be no longer than 64
// characters.
//
// Note: This RE is not 100% foolproof. It will still match some invalid emails.
const EMAIL_RE = /^(?=.{6,320}$)([-.\w]{1,64}@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-])*\.[a-zA-Z]{2,})$/;

/**
 * A validator that determines whether the given value matches the email
 * pattern.
 *
 * @param {*} value - The value to match against the email pattern.
 *
 * @returns {undefined|'validators.value.is_not_email'} Returns `undefined` if the
 *   value matches, otherwise it returns an error ID:
 *   'validators.value.is_not_email'.
 */
export function isEmail(value) {
  const customErrId = 'validators.value.is_not_email';
  const validator = matches(EMAIL_RE, customErrId);
  return validator(value);
}

// const EQUALTO_VALIDATOR_CACHE = {};

// export function isEqualToThis(otherFieldName_unused) {
//   // TODO: Implement.
//   // let errId = 'validators.value.is_not_equal_to_this';
// }

// const GREATER_THAN_VALIDATOR_CACHE = {};

// export function isGreaterThanThis(otherFieldName_unused) {
//   // TODO: Implement.
//   // let errId = 'validators.value.is_not_greater_than_this';
// }

// export function isOneOf(value_unused) {
//   // TODO: Implement.
//   // let errId = 'validators.value.is_not_one_of';
// }

const IS_INTEGER_RE = /\d+/;

/**
 * A validator that checks whether the given value can be interpreted as an
 * integer.
 *
 * @param {*} value - The value to check.
 *
 * @returns {undefined|'validators.value.is_not_integer'} Returns `undefined` if the
 *   value can be interpreted as an integer, otherwise it returns an error ID:
 *   'validators.value.is_not_integer'.
 */
export function isInteger(value) {
  const customErrId = 'validators.value.is_not_integer';
  const validator = matches(IS_INTEGER_RE, customErrId);
  return validator(value);
}

// const INTEGER_VALIDATOR_CACHE = {};

// export function isIntInRange(minimum, maximum) {
//   let key;
//   let type;

//   const min = toInt(minimum);
//   const max = toInt(maximum);
//   if (typeOf(min) === 'number') {
//     if (typeOf(max) === 'number') {
//       key = `${min}:${max}`;
//       type = 'between';
//     } else {
//       key = `${min}:`;
//       type = 'gte';
//     }
//   } else if (typeOf(max) === 'number') {
//     key = `:${max}`;
//     type = 'lte';
//   } else {
//     return noOp;
//     // throw TypeError('At least one of `minimum` or `maximum` is required.');
//   }

//   if (min > max) {
//     throw TypeError('`minimum` must be greater than or equal to `maximum`.');
//   }

//   let validator = INTEGER_VALIDATOR_CACHE[key];

//   if (!validator) {
//     validator = value => {
//       let errId;
//       const val = toNum(value, null);

//       if (typeOf(val) === 'number') {
//         switch (type) {
//           case 'between':
//             if (val < min || val > max) {
//               errId = 'validators.value.is_integer_not_between';
//             }
//             break;
//           case 'gte':
//             if (val < min) {
//               errId = 'validators.value.is_integer_lt';
//             }
//             break;
//           default:
//             if (val > max) {
//               errId = 'validators.value.is_integer_gt';
//             }
//             break;
//         }
//       }

//       return errId;
//     };
//     INTEGER_VALIDATOR_CACHE[key] = validator;
//   }
//   return validator;
// }

// const LESS_THAN_VALIDATOR_CACHE = {};

// export function isLessThanThis(otherFieldName_unused) {
//   // TODO: Implement.
//   // let errId = 'validators.value.is_less_than_this';
// }

/**
 * A validator that checks whether the given value is valid Markdown syntax.
 *
 * @param {*} value - The value to check.
 */
export function isMarkdown(value_unused) {
  // TODO: Implement.
  // let errId = 'validators.value.is_markdown';
}

// export function isNotIn(value_unused) {
//   // TODO: Implement.
//   // let errId = 'validators.value.is_not_in';
// }

const IS_NUMBER_RE = /\d+(\.\d*)?/;

/**
 * A validator that checks whether the given value can be interpreted as a number.
 *
 * @param {*} value - The value to check.
 *
 * @returns {undefined|'validators.value.is_not_number'} Returns `undefined` if the
 *   value can be interpreted as a number, otherwise it returns an error ID:
 *   'validators.value.is_not_number'.
 */
export function isNumber(value) {
  const customErrId = 'validators.value.is_not_number';
  const validator = matches(IS_NUMBER_RE, customErrId);
  return validator(value);
}

const NUMBER_VALIDATOR_CACHE = {};

/**
 * Returns a validator that matches the range defined by the first two arguments.
 *
 * Note: Passing non-number values for both arguments is allowed. In this case,
 * a no-op validator is returned. This can make the function easier to use since
 * the calling code does not have to check whether at least one argument is a
 * number.
 *
 * Note: The validator is cached/memoized so that a different validator is not
 * returned when the same arguments are given. This is especially useful when
 * using with the `validators` property of `Field` component from the `redux-form`
 * library/package.
 *
 * @param {number} [min] - An optional minimum value. Defaults to being unbounded.
 * @param {number} [max] - An optional maximum value. Defaults to being unbounded.
 *
 * @returns {Function} A validator for the corresponding arguments.
 *
 * @throws {TypeError} If `max` is less than `min`.
 */
export function isNumBetween(min, max) {
  let key;
  let type;

  if (typeOf(min) === 'number') {
    if (typeOf(max) === 'number') {
      key = `${min}:${max}`;
      type = 'between';
    } else {
      key = `${min}:`;
      type = 'gte';
    }
  } else if (typeOf(max) === 'number') {
    key = `:${max}`;
    type = 'lte';
  } else {
    return noOp;
  }

  if (min > max) {
    throw TypeError('`max` must not be less than `min`.');
  }

  let validator = NUMBER_VALIDATOR_CACHE[key];

  if (!validator) {
    validator = value => {
      let errId;
      const val = toNum(value, null);

      if (typeOf(val) === 'number') {
        switch (type) {
          case 'between':
            if (val < min || val > max) {
              errId = 'validators.value.is_number_not_between';
            }
            break;
          case 'gte':
            if (val < min) {
              errId = 'validators.value.is_number_lt';
            }
            break;
          default:
            if (val > max) {
              errId = 'validators.value.is_number_gt';
            }
            break;
        }
      }

      return errId;
    };
    NUMBER_VALIDATOR_CACHE[key] = validator;
  }
  return validator;
}

/**
 * A validator that checks whether the given value has content. If not, then an
 * error ID is returned. `undefined`, `null`, and an empty string are all
 * considered contentless.
 *
 * @param {*} value - The value to check.
 *
 * @returns {undefined|'validators.value.is_required'} Returns `undefined` if the
 *   value has content, otherwise it returns an error ID:
 *   'validators.value.is_required'.
 */
export function isRequired(value) {
  const errId =
    value === undefined || value === null || value === ''
      ? 'validators.value.is_required'
      : undefined;
  return errId;
}

/**
 * A validator that checks whether the given value is considered a strong
 * password.
 *
 * @param {*} value - The value to check.
 */
export function isStrongPassword(value_unused) {
  // TODO: Implement.
  // Note: May be able to reuse matches validator.
  // let errId = 'validators.value.is_not_strong_password';
}

/**
 * A validator that checks whether the given value is considered safe text.
 *
 * @param {*} value - The value to check.
 */
export function isSafeText(value_unused) {
  // TODO: Implement.
  // Note: May be able to reuse matches validator.
  // let errId = 'validators.value.is_not_safe_text';
}

const IS_US_PHONE_RE = /\d+/;

/**
 * A validator that checks whether the given value is an US phone number. It may
 * be a 7 or a 10 digit number.
 *
 * @param {*} value - The value to check.
 */
export function isUsPhone(value) {
  const customErrId = 'validators.value.is_not_us_phone';
  const validator = matches(IS_US_PHONE_RE, customErrId);
  return validator(value);
}

const IS_US_PHONE_10_RE = /\(?\d{3}\)?-?\d{3}-?\d{4}/;

/**
 * A validator that checks whether the given value is a 10 digit US phone number.
 *
 * @param {*} value - The value to check.
 */
export function isUsPhone10(value) {
  const customErrId = 'validators.value.is_not_us_phone_10';
  const validator = matches(IS_US_PHONE_10_RE, customErrId);
  return validator(value);
}

const IS_ZIP_CODE_RE = /\d{5}(-\d{4})?/;

/**
 * A validator that checks whether the given value is a zip code. It may be 5
 * digits or 5 plus 4 digits.
 *
 * @param {*} value - The value to check.
 */
export function isZipCode(value) {
  const customErrId = 'validators.value.is_not_zip_code';
  const validator = matches(IS_ZIP_CODE_RE, customErrId);
  return validator(value);
}

const IS_ZIP_CODE_5_RE = /\d{5}/;

/**
 * A validator that checks whether the given value is a 5 digit zip code.
 *
 * @param {*} value - The value to check.
 */
export function isZipCode5(value) {
  const customErrId = 'validators.value.is_not_zip_code_5';
  const validator = matches(IS_ZIP_CODE_5_RE, customErrId);
  return validator(value);
}

const IS_ZIP_CODE_PLUS_4_RE = /\d{5}-\d{4}/;

/**
 * A validator that checks whether the given value is a 5 plus 4 digit zip code.
 *
 * @param {*} value - The value to check.
 */
export function isZipCodePlus4(value) {
  const customErrId = 'validators.value.is_not_zip_code_plus_4';
  const validator = matches(IS_ZIP_CODE_PLUS_4_RE, customErrId);
  return validator(value);
}
