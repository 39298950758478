import { SET_CONNECTIONS } from '../actions';

const initState = {
  connections: /*: Connection[] */ [],
};

export default function connectionsReducer(state = initState, action) {
  switch (action.type) {
    case SET_CONNECTIONS:
      return {
        ...state,
        connections: [...action.payload.connections],
      };
    default:
      break;
  }
  return state;
}
