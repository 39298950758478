function is401Error(e) {
  const is401Err = e && e.networkError && e.networkError.statusCode === 401;
  return is401Err;
}

export { is401Error };

const ApolloErrorUtils = {
  is401: is401Error,
};

export default ApolloErrorUtils;
