import messages from '../../locale/messages';

const validate = values => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = messages.firstNameRequired;
  }

  if (!values.lastName) {
    errors.lastName = messages.lastNameRequired;
  }

  if (!values.email) {
    errors.email = messages.emailRequired;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
    errors.email = messages.emailInvalid;
  }

  if (!values.password) {
    errors.password = messages.passwordRequired;
  } else if (values.password.length < 8) {
    errors.password = messages.passwordInvalid;
  }

  // Note: Because the DOB is set across three fields, it is awkward to set a field
  // validation error. Need to set a "_error" error. However, ReduxForm only seems
  // to recognize field errors from this file. Therefore, DOB validation will be
  // done on submit.

  return errors;
};

export default validate;
