/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'enums.currencies.aud.desc': {
    id: 'enums.currencies.aud.desc',
    defaultMessage: 'Australian dollar',
  },
  'enums.currencies.aud.name': {
    id: 'enums.currencies.aud.name',
    defaultMessage: 'Australian Dollar',
  },

  'enums.currencies.bgn.desc': {
    id: 'enums.currencies.bgn.desc',
    defaultMessage: 'Bulgarian lev',
  },
  'enums.currencies.bgn.name': {
    id: 'enums.currencies.bgn.name',
    defaultMessage: 'Bulgarian Lev',
  },

  'enums.currencies.bmd.desc': {
    id: 'enums.currencies.bmd.desc',
    defaultMessage: 'Bermudian dollar',
  },
  'enums.currencies.bmd.name': {
    id: 'enums.currencies.bmd.name',
    defaultMessage: 'Bermudian Dollar',
  },

  'enums.currencies.brl.desc': {
    id: 'enums.currencies.brl.desc',
    defaultMessage: 'Brazilian real',
  },
  'enums.currencies.brl.name': {
    id: 'enums.currencies.brl.name',
    defaultMessage: 'Brazilian Real',
  },

  'enums.currencies.bsd.desc': {
    id: 'enums.currencies.bsd.desc',
    defaultMessage: 'Bahamian dollar',
  },
  'enums.currencies.bsd.name': {
    id: 'enums.currencies.bsd.name',
    defaultMessage: 'Bahamian Dollar',
  },

  'enums.currencies.cad.desc': {
    id: 'enums.currencies.cad.desc',
    defaultMessage: 'Canadian dollar',
  },
  'enums.currencies.cad.name': {
    id: 'enums.currencies.cad.name',
    defaultMessage: 'Canadian Dollar',
  },

  'enums.currencies.chf.desc': {
    id: 'enums.currencies.chf.desc',
    defaultMessage: 'Swiss franc',
  },
  'enums.currencies.chf.name': {
    id: 'enums.currencies.chf.name',
    defaultMessage: 'Swiss Franc',
  },

  'enums.currencies.cny.desc': {
    id: 'enums.currencies.cny.desc',
    defaultMessage: 'Renminbi (Chinese) yuan',
  },
  'enums.currencies.cny.name': {
    id: 'enums.currencies.cny.name',
    defaultMessage: 'Renminbi Yuan',
  },

  'enums.currencies.czk.desc': {
    id: 'enums.currencies.czk.desc',
    defaultMessage: 'Czech koruna',
  },
  'enums.currencies.czk.name': {
    id: 'enums.currencies.czk.name',
    defaultMessage: 'Czech Koruna',
  },

  'enums.currencies.dkk.desc': {
    id: 'enums.currencies.dkk.desc',
    defaultMessage: 'Danish krone',
  },
  'enums.currencies.dkk.name': {
    id: 'enums.currencies.dkk.name',
    defaultMessage: 'Danish Krone',
  },

  'enums.currencies.eur.desc': {
    id: 'enums.currencies.eur.desc',
    defaultMessage: 'Euro',
  },
  'enums.currencies.eur.name': {
    id: 'enums.currencies.eur.name',
    defaultMessage: 'Euro',
  },

  'enums.currencies.gbp.desc': {
    id: 'enums.currencies.gbp.desc',
    defaultMessage: 'British pound sterling',
  },
  'enums.currencies.gbp.name': {
    id: 'enums.currencies.gbp.name',
    defaultMessage: 'Pound Sterling',
  },

  'enums.currencies.hkd.desc': {
    id: 'enums.currencies.hkd.desc',
    defaultMessage: 'Hong Kong dollar',
  },
  'enums.currencies.hkd.name': {
    id: 'enums.currencies.hkd.name',
    defaultMessage: 'Hong Kong Dollar',
  },

  'enums.currencies.hrk.desc': {
    id: 'enums.currencies.hrk.desc',
    defaultMessage: 'Croatian kuna',
  },
  'enums.currencies.hrk.name': {
    id: 'enums.currencies.hrk.name',
    defaultMessage: 'Croatian Kuna',
  },

  'enums.currencies.huf.desc': {
    id: 'enums.currencies.huf.desc',
    defaultMessage: 'Hungarian forint',
  },
  'enums.currencies.huf.name': {
    id: 'enums.currencies.huf.name',
    defaultMessage: 'Hungarian Forint',
  },

  'enums.currencies.idr.desc': {
    id: 'enums.currencies.idr.desc',
    defaultMessage: 'Indonesian rupiah',
  },
  'enums.currencies.idr.name': {
    id: 'enums.currencies.idr.name',
    defaultMessage: 'Indonesian Rupiah',
  },

  'enums.currencies.ils.desc': {
    id: 'enums.currencies.ils.desc',
    defaultMessage: 'Israeli new shekel',
  },
  'enums.currencies.ils.name': {
    id: 'enums.currencies.ils.name',
    defaultMessage: 'Israeli New Shekel',
  },

  'enums.currencies.inr.desc': {
    id: 'enums.currencies.inr.desc',
    defaultMessage: 'Indian rupee',
  },
  'enums.currencies.inr.name': {
    id: 'enums.currencies.inr.name',
    defaultMessage: 'Indian Rupee',
  },

  'enums.currencies.isk.desc': {
    id: 'enums.currencies.isk.desc',
    defaultMessage: 'Icelandic króna',
  },
  'enums.currencies.isk.name': {
    id: 'enums.currencies.isk.name',
    defaultMessage: 'Icelandic Króna',
  },

  'enums.currencies.jpy.desc': {
    id: 'enums.currencies.jpy.desc',
    defaultMessage: 'Japanese yen',
  },
  'enums.currencies.jpy.name': {
    id: 'enums.currencies.jpy.name',
    defaultMessage: 'Japanese Yen',
  },

  'enums.currencies.krw.desc': {
    id: 'enums.currencies.krw.desc',
    defaultMessage: 'South Korean won',
  },
  'enums.currencies.krw.name': {
    id: 'enums.currencies.krw.name',
    defaultMessage: 'South Korean Won',
  },

  'enums.currencies.mxn.desc': {
    id: 'enums.currencies.mxn.desc',
    defaultMessage: 'Mexican peso',
  },
  'enums.currencies.mxn.name': {
    id: 'enums.currencies.mxn.name',
    defaultMessage: 'Mexican Peso',
  },

  'enums.currencies.myr.desc': {
    id: 'enums.currencies.myr.desc',
    defaultMessage: 'Malaysian ringgit',
  },
  'enums.currencies.myr.name': {
    id: 'enums.currencies.myr.name',
    defaultMessage: 'Malaysian Ringgit',
  },

  'enums.currencies.nok.desc': {
    id: 'enums.currencies.nok.desc',
    defaultMessage: 'Norwegian krone',
  },
  'enums.currencies.nok.name': {
    id: 'enums.currencies.nok.name',
    defaultMessage: 'Norwegian Krone',
  },

  'enums.currencies.nzd.desc': {
    id: 'enums.currencies.nzd.desc',
    defaultMessage: 'New Zealand dollar',
  },
  'enums.currencies.nzd.name': {
    id: 'enums.currencies.nzd.name',
    defaultMessage: 'New Zealand Dollar',
  },

  'enums.currencies.php.desc': {
    id: 'enums.currencies.php.desc',
    defaultMessage: 'Philippine peso',
  },
  'enums.currencies.php.name': {
    id: 'enums.currencies.php.name',
    defaultMessage: 'Philippine Peso',
  },

  'enums.currencies.pln.desc': {
    id: 'enums.currencies.pln.desc',
    defaultMessage: 'Polish złoty',
  },
  'enums.currencies.pln.name': {
    id: 'enums.currencies.pln.name',
    defaultMessage: 'Polish Złoty',
  },

  'enums.currencies.ron.desc': {
    id: 'enums.currencies.ron.desc',
    defaultMessage: 'Romanian leu',
  },
  'enums.currencies.ron.name': {
    id: 'enums.currencies.ron.name',
    defaultMessage: 'Romanian Leu',
  },

  'enums.currencies.rub.desc': {
    id: 'enums.currencies.rub.desc',
    defaultMessage: 'Russian ruble',
  },
  'enums.currencies.rub.name': {
    id: 'enums.currencies.rub.name',
    defaultMessage: 'Russian Ruble',
  },

  'enums.currencies.sek.desc': {
    id: 'enums.currencies.sek.desc',
    defaultMessage: 'Swedish krona',
  },
  'enums.currencies.sek.name': {
    id: 'enums.currencies.sek.name',
    defaultMessage: 'Swedish Krona',
  },

  'enums.currencies.sgd.desc': {
    id: 'enums.currencies.sgd.desc',
    defaultMessage: 'Singapore dollar',
  },
  'enums.currencies.sgd.name': {
    id: 'enums.currencies.sgd.name',
    defaultMessage: 'Singapore Dollar',
  },

  'enums.currencies.thb.desc': {
    id: 'enums.currencies.thb.desc',
    defaultMessage: 'Thai baht',
  },
  'enums.currencies.thb.name': {
    id: 'enums.currencies.thb.name',
    defaultMessage: 'Thai Baht',
  },

  'enums.currencies.try.desc': {
    id: 'enums.currencies.try.desc',
    defaultMessage: 'Turkish lira',
  },
  'enums.currencies.try.name': {
    id: 'enums.currencies.try.name',
    defaultMessage: 'Turkish Lira',
  },

  'enums.currencies.twd.desc': {
    id: 'enums.currencies.twd.desc',
    defaultMessage: 'New Taiwan dollar',
  },
  'enums.currencies.twd.name': {
    id: 'enums.currencies.twd.name',
    defaultMessage: 'New Taiwan Dollar',
  },

  'enums.currencies.uah.desc': {
    id: 'enums.currencies.uah.desc',
    defaultMessage: 'Ukrainian hryvnia',
  },
  'enums.currencies.uah.name': {
    id: 'enums.currencies.uah.name',
    defaultMessage: 'Ukrainian Hryvnia',
  },

  'enums.currencies.usd.desc': {
    id: 'enums.currencies.usd.desc',
    defaultMessage: 'US Dollars',
  },
  'enums.currencies.usd.name': {
    id: 'enums.currencies.usd.name',
    defaultMessage: 'US Dollars',
  },

  'enums.currencies.xxx.desc': {
    id: 'enums.currencies.xxx.desc',
    defaultMessage: 'No currency',
  },
  'enums.currencies.xxx.name': {
    id: 'enums.currencies.xxx.name',
    defaultMessage: 'No Currency',
  },

  'enums.currencies.zar.desc': {
    id: 'enums.currencies.zar.desc',
    defaultMessage: 'South African rand',
  },
  'enums.currencies.zar.name': {
    id: 'enums.currencies.zar.name',
    defaultMessage: 'Rand',
  },

  'enums.distance_units.kilometers.name': {
    id: 'enums.distance_units.kilometers.name',
    defaultMessage: 'Kilometers',
  },
  'enums.distance_units.miles.name': {
    id: 'enums.distance_units.miles.name',
    defaultMessage: 'Miles',
  },

  // Main Categories:

  'enums.expertise_categories.art.desc': {
    id: 'enums.expertise_categories.art.desc',
    defaultMessage: 'Art related sessions.',
  },
  'enums.expertise_categories.art.name': {
    id: 'enums.expertise_categories.art.name',
    defaultMessage: 'Art',
  },

  'enums.expertise_categories.business.desc': {
    id: 'enums.expertise_categories.business.desc',
    defaultMessage: 'Business related sessions.',
  },
  'enums.expertise_categories.business.name': {
    id: 'enums.expertise_categories.business.name',
    defaultMessage: 'Business',
  },

  'enums.expertise_categories.communication.desc': {
    id: 'enums.expertise_categories.communication.desc',
    defaultMessage: 'Communication related sessions.',
  },
  'enums.expertise_categories.communication.name': {
    id: 'enums.expertise_categories.communication.name',
    defaultMessage: 'Communication',
  },

  'enums.expertise_categories.design.desc': {
    id: 'enums.expertise_categories.design.desc',
    defaultMessage: 'Design related sessions.',
  },
  'enums.expertise_categories.design.name': {
    id: 'enums.expertise_categories.design.name',
    defaultMessage: 'Design',
  },

  'enums.expertise_categories.lifestyle.desc': {
    id: 'enums.expertise_categories.lifestyle.desc',
    defaultMessage: 'Lifestyle related sessions.',
  },
  'enums.expertise_categories.lifestyle.name': {
    id: 'enums.expertise_categories.lifestyle.name',
    defaultMessage: 'Lifestyle',
  },

  'enums.expertise_categories.math_and_science.desc': {
    id: 'enums.expertise_categories.math_and_science.desc',
    defaultMessage: 'Math and science related sessions.',
  },
  'enums.expertise_categories.math_and_science.name': {
    id: 'enums.expertise_categories.math_and_science.name',
    defaultMessage: 'Math and Science',
  },

  'enums.expertise_categories.music.desc': {
    id: 'enums.expertise_categories.music.desc',
    defaultMessage: 'Music related sessions.',
  },
  'enums.expertise_categories.music.name': {
    id: 'enums.expertise_categories.music.name',
    defaultMessage: 'Music',
  },

  'enums.expertise_categories.performing_arts.desc': {
    id: 'enums.expertise_categories.performing_arts.desc',
    defaultMessage: 'Performing arts related sessions.',
  },
  'enums.expertise_categories.performing_arts.name': {
    id: 'enums.expertise_categories.performing_arts.name',
    defaultMessage: 'Performing Arts',
  },

  'enums.expertise_categories.photography.desc': {
    id: 'enums.expertise_categories.photography.desc',
    defaultMessage: 'Photography related sessions.',
  },
  'enums.expertise_categories.photography.name': {
    id: 'enums.expertise_categories.photography.name',
    defaultMessage: 'Photography',
  },

  'enums.expertise_categories.society.desc': {
    id: 'enums.expertise_categories.society.desc',
    defaultMessage: 'Society related sessions.',
  },
  'enums.expertise_categories.society.name': {
    id: 'enums.expertise_categories.society.name',
    defaultMessage: 'Society',
  },

  'enums.expertise_categories.sports_and_recreation.desc': {
    id: 'enums.expertise_categories.sports_and_recreation.desc',
    defaultMessage: 'Sports and recreation related sessions.',
  },
  'enums.expertise_categories.sports_and_recreation.name': {
    id: 'enums.expertise_categories.sports_and_recreation.name',
    defaultMessage: 'Sports and Recreation',
  },

  'enums.expertise_categories.technology.desc': {
    id: 'enums.expertise_categories.technology.desc',
    defaultMessage: 'Technology related sessions.',
  },
  'enums.expertise_categories.technology.name': {
    id: 'enums.expertise_categories.technology.name',
    defaultMessage: 'Technology',
  },

  'enums.expertise_categories.trade_skills.desc': {
    id: 'enums.expertise_categories.trade_skills.desc',
    defaultMessage: 'Trade skills related sessions.',
  },
  'enums.expertise_categories.trade_skills.name': {
    id: 'enums.expertise_categories.trade_skills.name',
    defaultMessage: 'Trade Skills',
  },

  'enums.expertise_categories.video.desc': {
    id: 'enums.expertise_categories.video.desc',
    defaultMessage: 'Video related sessions.',
  },
  'enums.expertise_categories.video.name': {
    id: 'enums.expertise_categories.video.name',
    defaultMessage: 'Video',
  },

  // Art fields:
  'enums.expertise_fields.glasswork.desc': {
    id: 'enums.expertise_fields.glasswork.desc',
    defaultMessage: 'Glasswork is ...',
  },
  'enums.expertise_fields.glasswork.name': {
    id: 'enums.expertise_fields.glasswork.name',
    defaultMessage: 'Glasswork',
  },

  'enums.expertise_fields.graphic_art.desc': {
    id: 'enums.expertise_fields.graphic_art.desc',
    defaultMessage: 'Graphic art is ...',
  },
  'enums.expertise_fields.graphic_art.name': {
    id: 'enums.expertise_fields.graphic_art.name',
    defaultMessage: 'Graphic Art',
  },

  'enums.expertise_fields.metalwork.desc': {
    id: 'enums.expertise_fields.metalwork.desc',
    defaultMessage: 'Metalwork is ...',
  },
  'enums.expertise_fields.metalwork.name': {
    id: 'enums.expertise_fields.metalwork.name',
    defaultMessage: 'Metalwork',
  },

  'enums.expertise_fields.multimedia_art.desc': {
    id: 'enums.expertise_fields.multimedia_art.desc',
    defaultMessage: 'Multimedia art is ...',
  },
  'enums.expertise_fields.multimedia_art.name': {
    id: 'enums.expertise_fields.multimedia_art.name',
    defaultMessage: 'Multimedia Art',
  },

  'enums.expertise_fields.sculpture.desc': {
    id: 'enums.expertise_fields.sculpture.desc',
    defaultMessage: 'Sculpture is ...',
  },
  'enums.expertise_fields.sculpture.name': {
    id: 'enums.expertise_fields.sculpture.name',
    defaultMessage: 'Sculpture',
  },

  'enums.expertise_fields.woodcraft.desc': {
    id: 'enums.expertise_fields.woodcraft.desc',
    defaultMessage: 'Woodcraft is ...',
  },
  'enums.expertise_fields.woodcraft.name': {
    id: 'enums.expertise_fields.woodcraft.name',
    defaultMessage: 'Woodcraft',
  },

  // Business
  'enums.expertise_fields.entrepreneurship.desc': {
    id: 'enums.expertise_fields.entrepreneurship.desc',
    defaultMessage: 'Entrepreneurship is ...',
  },
  'enums.expertise_fields.entrepreneurship.name': {
    id: 'enums.expertise_fields.entrepreneurship.name',
    defaultMessage: 'Entrepreneurship',
  },

  'enums.expertise_fields.finance.desc': {
    id: 'enums.expertise_fields.finance.desc',
    defaultMessage: 'Finance is ...',
  },
  'enums.expertise_fields.finance.name': {
    id: 'enums.expertise_fields.finance.name',
    defaultMessage: 'Finance',
  },

  'enums.expertise_fields.investing.desc': {
    id: 'enums.expertise_fields.investing.desc',
    defaultMessage: 'Investing is ...',
  },
  'enums.expertise_fields.investing.name': {
    id: 'enums.expertise_fields.investing.name',
    defaultMessage: 'Investing',
  },

  'enums.expertise_fields.management.desc': {
    id: 'enums.expertise_fields.management.desc',
    defaultMessage: 'Management is ...',
  },
  'enums.expertise_fields.management.name': {
    id: 'enums.expertise_fields.management.name',
    defaultMessage: 'Management',
  },

  'enums.expertise_fields.marketing.desc': {
    id: 'enums.expertise_fields.marketing.desc',
    defaultMessage: 'Marketing is ...',
  },
  'enums.expertise_fields.marketing.name': {
    id: 'enums.expertise_fields.marketing.name',
    defaultMessage: 'Marketing',
  },

  // Communication

  'enums.expertise_fields.fiction_writing.desc': {
    id: 'enums.expertise_fields.fiction_writing.desc',
    defaultMessage: 'Fiction writing is ...',
  },
  'enums.expertise_fields.fiction_writing.name': {
    id: 'enums.expertise_fields.fiction_writing.name',
    defaultMessage: 'Fiction Writing',
  },

  'enums.expertise_fields.journalism.desc': {
    id: 'enums.expertise_fields.journalism.desc',
    defaultMessage: 'Journalism is ...',
  },
  'enums.expertise_fields.journalism.name': {
    id: 'enums.expertise_fields.journalism.name',
    defaultMessage: 'Journalism',
  },

  'enums.expertise_fields.nonfiction_writing.desc': {
    id: 'enums.expertise_fields.nonfiction_writing.desc',
    defaultMessage: 'Nonfiction writing is ...',
  },
  'enums.expertise_fields.nonfiction_writing.name': {
    id: 'enums.expertise_fields.nonfiction_writing.name',
    defaultMessage: 'Nonfiction Writing',
  },

  'enums.expertise_fields.public_relations.desc': {
    id: 'enums.expertise_fields.public_relations.desc',
    defaultMessage: 'Public relations are ...',
  },
  'enums.expertise_fields.public_relations.name': {
    id: 'enums.expertise_fields.public_relations.name',
    defaultMessage: 'Public relations',
  },

  'enums.expertise_fields.radio_broadcasting.desc': {
    id: 'enums.expertise_fields.radio_broadcasting.desc',
    defaultMessage: 'Radio broadcasting is ...',
  },
  'enums.expertise_fields.radio_broadcasting.name': {
    id: 'enums.expertise_fields.radio_broadcasting.name',
    defaultMessage: 'Radio Broadcasting',
  },

  'enums.expertise_fields.television_broadcasting.desc': {
    id: 'enums.expertise_fields.television_broadcasting.desc',
    defaultMessage: 'Television broadcasting is ...',
  },
  'enums.expertise_fields.television_broadcasting.name': {
    id: 'enums.expertise_fields.television_broadcasting.name',
    defaultMessage: 'Television Broadcasting',
  },

  // Design

  'enums.expertise_fields.architectural_design.desc': {
    id: 'enums.expertise_fields.architectural_design.desc',
    defaultMessage: 'Architectural design is ...',
  },
  'enums.expertise_fields.architectural_design.name': {
    id: 'enums.expertise_fields.architectural_design.name',
    defaultMessage: 'Architectural Design',
  },

  'enums.expertise_fields.computer_aided_design.desc': {
    id: 'enums.expertise_fields.computer_aided_design.desc',
    defaultMessage: 'Computer aided design is ...',
  },
  'enums.expertise_fields.computer_aided_design.name': {
    id: 'enums.expertise_fields.computer_aided_design.name',
    defaultMessage: 'Computer Aided Design',
  },

  'enums.expertise_fields.graphic_design.desc': {
    id: 'enums.expertise_fields.graphic_design.desc',
    defaultMessage: 'Graphic design is ...',
  },
  'enums.expertise_fields.graphic_design.name': {
    id: 'enums.expertise_fields.graphic_design.name',
    defaultMessage: 'Graphic Design',
  },

  'enums.expertise_fields.interior_design.desc': {
    id: 'enums.expertise_fields.interior_design.desc',
    defaultMessage: 'Interior design is ...',
  },
  'enums.expertise_fields.interior_design.name': {
    id: 'enums.expertise_fields.interior_design.name',
    defaultMessage: 'Interior Design',
  },

  'enums.expertise_fields.product_design.desc': {
    id: 'enums.expertise_fields.product_design.desc',
    defaultMessage: 'Product design is ...',
  },
  'enums.expertise_fields.product_design.name': {
    id: 'enums.expertise_fields.product_design.name',
    defaultMessage: 'Product Design',
  },

  'enums.expertise_fields.ui_ux_design.desc': {
    id: 'enums.expertise_fields.ui_ux_design.desc',
    defaultMessage: 'UI/UX design is ...',
  },
  'enums.expertise_fields.ui_ux_design.name': {
    id: 'enums.expertise_fields.ui_ux_design.name',
    defaultMessage: 'UI/UX Design',
  },

  // Lifestyle

  'enums.expertise_fields.crafts.desc': {
    id: 'enums.expertise_fields.crafts.desc',
    defaultMessage: 'Crafts are ...',
  },
  'enums.expertise_fields.crafts.name': {
    id: 'enums.expertise_fields.crafts.name',
    defaultMessage: 'Crafts',
  },

  'enums.expertise_fields.event_planning.desc': {
    id: 'enums.expertise_fields.event_planning.desc',
    defaultMessage: 'Event planning is ...',
  },
  'enums.expertise_fields.event_planning.name': {
    id: 'enums.expertise_fields.event_planning.name',
    defaultMessage: 'Event Planning',
  },

  'enums.expertise_fields.fashion_and_beauty.desc': {
    id: 'enums.expertise_fields.fashion_and_beauty.desc',
    defaultMessage: 'Fashion and beauty are ...',
  },
  'enums.expertise_fields.fashion_and_beauty.name': {
    id: 'enums.expertise_fields.fashion_and_beauty.name',
    defaultMessage: 'Fashion and Beauty',
  },

  'enums.expertise_fields.food_and_drink.desc': {
    id: 'enums.expertise_fields.food_and_drink.desc',
    defaultMessage: 'Food and drink are ...',
  },
  'enums.expertise_fields.food_and_drink.name': {
    id: 'enums.expertise_fields.food_and_drink.name',
    defaultMessage: 'Food and Drink',
  },

  'enums.expertise_fields.health_and_wellness.desc': {
    id: 'enums.expertise_fields.health_and_wellness.desc',
    defaultMessage: 'Health and wellness are ...',
  },
  'enums.expertise_fields.health_and_wellness.name': {
    id: 'enums.expertise_fields.health_and_wellness.name',
    defaultMessage: 'Health and Wellness',
  },

  'enums.expertise_fields.religion_and_spirituality.desc': {
    id: 'enums.expertise_fields.religion_and_spirituality.desc',
    defaultMessage: 'Religion and spirituality are ...',
  },
  'enums.expertise_fields.religion_and_spirituality.name': {
    id: 'enums.expertise_fields.religion_and_spirituality.name',
    defaultMessage: 'Religion and Spirituality',
  },

  // Math and Science

  'enums.expertise_fields.computer_science.desc': {
    id: 'enums.expertise_fields.computer_science.desc',
    defaultMessage: 'Computer science is ...',
  },
  'enums.expertise_fields.computer_science.name': {
    id: 'enums.expertise_fields.computer_science.name',
    defaultMessage: 'Computer Science',
  },

  'enums.expertise_fields.engineering.desc': {
    id: 'enums.expertise_fields.engineering.desc',
    defaultMessage: 'Engineering is ...',
  },
  'enums.expertise_fields.engineering.name': {
    id: 'enums.expertise_fields.engineering.name',
    defaultMessage: 'Engineering',
  },

  'enums.expertise_fields.life_science.desc': {
    id: 'enums.expertise_fields.life_science.desc',
    defaultMessage: 'Life science is ...',
  },
  'enums.expertise_fields.life_science.name': {
    id: 'enums.expertise_fields.life_science.name',
    defaultMessage: 'Life Science',
  },

  'enums.expertise_fields.mathematics.desc': {
    id: 'enums.expertise_fields.mathematics.desc',
    defaultMessage: 'Mathematics is ...',
  },
  'enums.expertise_fields.mathematics.name': {
    id: 'enums.expertise_fields.mathematics.name',
    defaultMessage: 'Mathematics',
  },

  'enums.expertise_fields.physical_science.desc': {
    id: 'enums.expertise_fields.physical_science.desc',
    defaultMessage: 'Physical science is ...',
  },
  'enums.expertise_fields.physical_science.name': {
    id: 'enums.expertise_fields.physical_science.name',
    defaultMessage: 'Physical Science',
  },

  'enums.expertise_fields.social_science.desc': {
    id: 'enums.expertise_fields.social_science.desc',
    defaultMessage: 'Social science is ...',
  },
  'enums.expertise_fields.social_science.name': {
    id: 'enums.expertise_fields.social_science.name',
    defaultMessage: 'Social Science',
  },

  // Music

  'enums.expertise_fields.audio_engineering.desc': {
    id: 'enums.expertise_fields.audio_engineering.desc',
    defaultMessage: 'Audio engineering is ...',
  },
  'enums.expertise_fields.audio_engineering.name': {
    id: 'enums.expertise_fields.audio_engineering.name',
    defaultMessage: 'Audio Engineering',
  },

  'enums.expertise_fields.live_performance.desc': {
    id: 'enums.expertise_fields.live_performance.desc',
    defaultMessage: 'Live performance is ...',
  },
  'enums.expertise_fields.live_performance.name': {
    id: 'enums.expertise_fields.live_performance.name',
    defaultMessage: 'Live Performance',
  },

  'enums.expertise_fields.mastering.desc': {
    id: 'enums.expertise_fields.mastering.desc',
    defaultMessage: 'Mastering is ...',
  },
  'enums.expertise_fields.mastering.name': {
    id: 'enums.expertise_fields.mastering.name',
    defaultMessage: 'Mastering',
  },

  'enums.expertise_fields.mixing.desc': {
    id: 'enums.expertise_fields.mixing.desc',
    defaultMessage: 'Mixing is ...',
  },
  'enums.expertise_fields.mixing.name': {
    id: 'enums.expertise_fields.mixing.name',
    defaultMessage: 'Mixing',
  },

  'enums.expertise_fields.music_production.desc': {
    id: 'enums.expertise_fields.music_production.desc',
    defaultMessage: 'Music production is ...',
  },
  'enums.expertise_fields.music_production.name': {
    id: 'enums.expertise_fields.music_production.name',
    defaultMessage: 'Music Production',
  },

  'enums.expertise_fields.recording.desc': {
    id: 'enums.expertise_fields.recording.desc',
    defaultMessage: 'Recording is ...',
  },
  'enums.expertise_fields.recording.name': {
    id: 'enums.expertise_fields.recording.name',
    defaultMessage: 'Recording',
  },

  'enums.expertise_fields.sound_design.desc': {
    id: 'enums.expertise_fields.sound_design.desc',
    defaultMessage: 'Sound design is ...',
  },
  'enums.expertise_fields.sound_design.name': {
    id: 'enums.expertise_fields.sound_design.name',
    defaultMessage: 'Sound Design',
  },

  // Performing Arts

  'enums.expertise_fields.comedy.desc': {
    id: 'enums.expertise_fields.comedy.desc',
    defaultMessage: 'Comedy is ...',
  },
  'enums.expertise_fields.comedy.name': {
    id: 'enums.expertise_fields.comedy.name',
    defaultMessage: 'Comedy',
  },

  'enums.expertise_fields.dance.desc': {
    id: 'enums.expertise_fields.dance.desc',
    defaultMessage: 'Dance is ...',
  },
  'enums.expertise_fields.dance.name': {
    id: 'enums.expertise_fields.dance.name',
    defaultMessage: 'Dance',
  },

  'enums.expertise_fields.juggling.desc': {
    id: 'enums.expertise_fields.juggling.desc',
    defaultMessage: 'Juggling is ...',
  },
  'enums.expertise_fields.juggling.name': {
    id: 'enums.expertise_fields.juggling.name',
    defaultMessage: 'Juggling',
  },

  'enums.expertise_fields.magic.desc': {
    id: 'enums.expertise_fields.magic.desc',
    defaultMessage: 'Magic is ...',
  },
  'enums.expertise_fields.magic.name': {
    id: 'enums.expertise_fields.magic.name',
    defaultMessage: 'Magic',
  },

  'enums.expertise_fields.theater.desc': {
    id: 'enums.expertise_fields.theater.desc',
    defaultMessage: 'Theater is ...',
  },
  'enums.expertise_fields.theater.name': {
    id: 'enums.expertise_fields.theater.name',
    defaultMessage: 'Theater',
  },

  // Photography

  'enums.expertise_fields.aerial_photography.desc': {
    id: 'enums.expertise_fields.aerial_photography.desc',
    defaultMessage: 'Aerial photography is ...',
  },
  'enums.expertise_fields.aerial_photography.name': {
    id: 'enums.expertise_fields.aerial_photography.name',
    defaultMessage: 'Aerial Photography',
  },

  'enums.expertise_fields.astrophotography.desc': {
    id: 'enums.expertise_fields.astrophotography.desc',
    defaultMessage: 'Astrophotography is ...',
  },
  'enums.expertise_fields.astrophotography.name': {
    id: 'enums.expertise_fields.astrophotography.name',
    defaultMessage: 'Astrophotography',
  },

  'enums.expertise_fields.commercial_photography.desc': {
    id: 'enums.expertise_fields.commercial_photography.desc',
    defaultMessage: 'Commercial photography is ...',
  },
  'enums.expertise_fields.commercial_photography.name': {
    id: 'enums.expertise_fields.commercial_photography.name',
    defaultMessage: 'Commercial Photography',
  },

  'enums.expertise_fields.event_photography.desc': {
    id: 'enums.expertise_fields.event_photography.desc',
    defaultMessage: 'Event photography is ...',
  },
  'enums.expertise_fields.event_photography.name': {
    id: 'enums.expertise_fields.event_photography.name',
    defaultMessage: 'Event Photography',
  },

  'enums.expertise_fields.nature_and_wildlife.desc': {
    id: 'enums.expertise_fields.nature_and_wildlife.desc',
    defaultMessage: 'Nature and wildlife photography is ...',
  },
  'enums.expertise_fields.nature_and_wildlife.name': {
    id: 'enums.expertise_fields.nature_and_wildlife.name',
    defaultMessage: 'Nature and Wildlife Photography',
  },

  'enums.expertise_fields.photo_editing.desc': {
    id: 'enums.expertise_fields.photo_editing.desc',
    defaultMessage: 'Photo editing is ...',
  },
  'enums.expertise_fields.photo_editing.name': {
    id: 'enums.expertise_fields.photo_editing.name',
    defaultMessage: 'Photo Editing',
  },

  // Society

  'enums.expertise_fields.education.desc': {
    id: 'enums.expertise_fields.education.desc',
    defaultMessage: 'Education is ...',
  },
  'enums.expertise_fields.education.name': {
    id: 'enums.expertise_fields.education.name',
    defaultMessage: 'Education',
  },

  'enums.expertise_fields.environment_and_sustainability.desc': {
    id: 'enums.expertise_fields.environment_and_sustainability.desc',
    defaultMessage: 'Environment and sustainability are ...',
  },
  'enums.expertise_fields.environment_and_sustainability.name': {
    id: 'enums.expertise_fields.environment_and_sustainability.name',
    defaultMessage: 'Environment and Sustainability',
  },

  'enums.expertise_fields.government.desc': {
    id: 'enums.expertise_fields.government.desc',
    defaultMessage: 'Government is ...',
  },
  'enums.expertise_fields.government.name': {
    id: 'enums.expertise_fields.government.name',
    defaultMessage: 'Government',
  },

  'enums.expertise_fields.law.desc': {
    id: 'enums.expertise_fields.law.desc',
    defaultMessage: 'Law is ...',
  },
  'enums.expertise_fields.law.name': {
    id: 'enums.expertise_fields.law.name',
    defaultMessage: 'Law',
  },

  'enums.expertise_fields.nonprofit_organizations.desc': {
    id: 'enums.expertise_fields.nonprofit_organizations.desc',
    defaultMessage: 'Nonprofit organizations are ...',
  },
  'enums.expertise_fields.nonprofit_organizations.name': {
    id: 'enums.expertise_fields.nonprofit_organizations.name',
    defaultMessage: 'Nonprofit Organizations',
  },

  // Sports and Recreation

  'enums.expertise_fields.adventure_sports.desc': {
    id: 'enums.expertise_fields.adventure_sports.desc',
    defaultMessage: 'Adventure sports are ...',
  },
  'enums.expertise_fields.adventure_sports.name': {
    id: 'enums.expertise_fields.adventure_sports.name',
    defaultMessage: 'Adventure Sports',
  },

  'enums.expertise_fields.extreme_sports.desc': {
    id: 'enums.expertise_fields.extreme_sports.desc',
    defaultMessage: 'Extreme sports are ...',
  },
  'enums.expertise_fields.extreme_sports.name': {
    id: 'enums.expertise_fields.extreme_sports.name',
    defaultMessage: 'Extreme Sports',
  },

  'enums.expertise_fields.fitness_and_exercise.desc': {
    id: 'enums.expertise_fields.fitness_and_exercise.desc',
    defaultMessage: 'Fitness and exercise are ...',
  },
  'enums.expertise_fields.fitness_and_exercise.name': {
    id: 'enums.expertise_fields.fitness_and_exercise.name',
    defaultMessage: 'Fitness and Exercise',
  },

  'enums.expertise_fields.motor_sports.desc': {
    id: 'enums.expertise_fields.motor_sports.desc',
    defaultMessage: 'Motor sports are ...',
  },
  'enums.expertise_fields.motor_sports.name': {
    id: 'enums.expertise_fields.motor_sports.name',
    defaultMessage: 'Motor Sports',
  },

  'enums.expertise_fields.summer_sports.desc': {
    id: 'enums.expertise_fields.summer_sports.desc',
    defaultMessage: 'Summer sports are ...',
  },
  'enums.expertise_fields.summer_sports.name': {
    id: 'enums.expertise_fields.summer_sports.name',
    defaultMessage: 'Summer Sports',
  },

  'enums.expertise_fields.winter_sports.desc': {
    id: 'enums.expertise_fields.winter_sports.desc',
    defaultMessage: 'Winter sports are ...',
  },
  'enums.expertise_fields.winter_sports.name': {
    id: 'enums.expertise_fields.winter_sports.name',
    defaultMessage: 'Winter Sports',
  },

  // Technology

  'enums.expertise_fields.app_development.desc': {
    id: 'enums.expertise_fields.app_development.desc',
    defaultMessage: 'App development is ...',
  },
  'enums.expertise_fields.app_development.name': {
    id: 'enums.expertise_fields.app_development.name',
    defaultMessage: 'App Development',
  },

  'enums.expertise_fields.artificial_intelligence.desc': {
    id: 'enums.expertise_fields.artificial_intelligence.desc',
    defaultMessage: 'Artificial intelligence is ...',
  },
  'enums.expertise_fields.artificial_intelligence.name': {
    id: 'enums.expertise_fields.artificial_intelligence.name',
    defaultMessage: 'Artificial Intelligence',
  },

  'enums.expertise_fields.game_development.desc': {
    id: 'enums.expertise_fields.game_development.desc',
    defaultMessage: 'Game development is ...',
  },
  'enums.expertise_fields.game_development.name': {
    id: 'enums.expertise_fields.game_development.name',
    defaultMessage: 'Game Development',
  },

  'enums.expertise_fields.network_and_security.desc': {
    id: 'enums.expertise_fields.network_and_security.desc',
    defaultMessage: 'Network and security are ...',
  },
  'enums.expertise_fields.network_and_security.name': {
    id: 'enums.expertise_fields.network_and_security.name',
    defaultMessage: 'Network and Security',
  },

  'enums.expertise_fields.robotics.desc': {
    id: 'enums.expertise_fields.robotics.desc',
    defaultMessage: 'Robotics are ...',
  },
  'enums.expertise_fields.robotics.name': {
    id: 'enums.expertise_fields.robotics.name',
    defaultMessage: 'Robotics',
  },

  'enums.expertise_fields.software_engineering.desc': {
    id: 'enums.expertise_fields.software_engineering.desc',
    defaultMessage: 'Software engineering is ...',
  },
  'enums.expertise_fields.software_engineering.name': {
    id: 'enums.expertise_fields.software_engineering.name',
    defaultMessage: 'Software Engineering',
  },

  'enums.expertise_fields.web_development.desc': {
    id: 'enums.expertise_fields.web_development.desc',
    defaultMessage: 'Web development is ...',
  },
  'enums.expertise_fields.web_development.name': {
    id: 'enums.expertise_fields.web_development.name',
    defaultMessage: 'Web Development',
  },

  // Trade skills
  'enums.expertise_fields.automotive.desc': {
    id: 'enums.expertise_fields.automotive.desc',
    defaultMessage: 'Automotive is ...',
  },
  'enums.expertise_fields.automotive.name': {
    id: 'enums.expertise_fields.automotive.name',
    defaultMessage: 'Automotive',
  },

  'enums.expertise_fields.aviation.desc': {
    id: 'enums.expertise_fields.aviation.desc',
    defaultMessage: 'Aviation is ...',
  },
  'enums.expertise_fields.aviation.name': {
    id: 'enums.expertise_fields.aviation.name',
    defaultMessage: 'Aviation',
  },

  'enums.expertise_fields.construction.desc': {
    id: 'enums.expertise_fields.construction.desc',
    defaultMessage: 'Construction is ...',
  },
  'enums.expertise_fields.construction.name': {
    id: 'enums.expertise_fields.construction.name',
    defaultMessage: 'Construction',
  },

  'enums.expertise_fields.electrical.desc': {
    id: 'enums.expertise_fields.electrical.desc',
    defaultMessage: 'Electrical is ...',
  },
  'enums.expertise_fields.electrical.name': {
    id: 'enums.expertise_fields.electrical.name',
    defaultMessage: 'Electrical',
  },

  'enums.expertise_fields.home_improvement.desc': {
    id: 'enums.expertise_fields.home_improvement.desc',
    defaultMessage: 'Home improvement is ...',
  },
  'enums.expertise_fields.home_improvement.name': {
    id: 'enums.expertise_fields.home_improvement.name',
    defaultMessage: 'Home Improvement',
  },

  'enums.expertise_fields.manufacturing.desc': {
    id: 'enums.expertise_fields.manufacturing.desc',
    defaultMessage: 'Manufacturing is ...',
  },
  'enums.expertise_fields.manufacturing.name': {
    id: 'enums.expertise_fields.manufacturing.name',
    defaultMessage: 'Manufacturing',
  },

  'enums.expertise_fields.mechanical.desc': {
    id: 'enums.expertise_fields.mechanical.desc',
    defaultMessage: 'Mechanical is ...',
  },
  'enums.expertise_fields.mechanical.name': {
    id: 'enums.expertise_fields.mechanical.name',
    defaultMessage: 'Mechanical',
  },

  // Video

  'enums.expertise_fields.animation.desc': {
    id: 'enums.expertise_fields.animation.desc',
    defaultMessage: 'Animation is ...',
  },
  'enums.expertise_fields.animation.name': {
    id: 'enums.expertise_fields.animation.name',
    defaultMessage: 'Animation',
  },

  'enums.expertise_fields.cgi_modeling.desc': {
    id: 'enums.expertise_fields.cgi_modeling.desc',
    defaultMessage: 'CGI modeling is ...',
  },
  'enums.expertise_fields.cgi_modeling.name': {
    id: 'enums.expertise_fields.cgi_modeling.name',
    defaultMessage: 'CGI Modeling',
  },

  'enums.expertise_fields.post_production.desc': {
    id: 'enums.expertise_fields.post_production.desc',
    defaultMessage: 'Post-production is ...',
  },
  'enums.expertise_fields.post_production.name': {
    id: 'enums.expertise_fields.post_production.name',
    defaultMessage: 'Post-Production',
  },

  'enums.expertise_fields.screenwriting.desc': {
    id: 'enums.expertise_fields.screenwriting.desc',
    defaultMessage: 'Screenwriting is ...',
  },
  'enums.expertise_fields.screenwriting.name': {
    id: 'enums.expertise_fields.screenwriting.name',
    defaultMessage: 'Screenwriting',
  },

  'enums.expertise_fields.video_editing.desc': {
    id: 'enums.expertise_fields.video_editing.desc',
    defaultMessage: 'Video editing is ...',
  },
  'enums.expertise_fields.video_editing.name': {
    id: 'enums.expertise_fields.video_editing.name',
    defaultMessage: 'Video Editing',
  },

  'enums.expertise_fields.video_production.desc': {
    id: 'enums.expertise_fields.video_production.desc',
    defaultMessage: 'Video production is ...',
  },
  'enums.expertise_fields.video_production.name': {
    id: 'enums.expertise_fields.video_production.name',
    defaultMessage: 'Video Production',
  },

  'enums.expertise_fields.visual_effects.desc': {
    id: 'enums.expertise_fields.visual_effects.desc',
    defaultMessage: 'Visual effects is ...',
  },
  'enums.expertise_fields.visual_effects.name': {
    id: 'enums.expertise_fields.visual_effects.name',
    defaultMessage: 'Visual Effects',
  },

  // End of fields

  'enums.location_types.business.desc': {
    id: 'enums.location_types.business.desc',
    defaultMessage: "Sessions are held at the expert's place of work.",
  },
  'enums.location_types.business.name': {
    id: 'enums.location_types.business.name',
    defaultMessage: 'At a business',
  },

  'enums.location_types.home.desc': {
    id: 'enums.location_types.home.desc',
    defaultMessage: "Sessions are held at the expert's home.",
  },
  'enums.location_types.home.name': {
    id: 'enums.location_types.home.name',
    defaultMessage: 'At my home',
  },

  'enums.payment_options.card.desc': {
    id: 'enums.payment_options.card.desc',
    defaultMessage: 'Payment by card. For example, a credit-card.',
  },
  'enums.payment_options.card.name': {
    id: 'enums.payment_options.card.name',
    defaultMessage: 'Card',
  },

  'enums.payment_options.paypal.desc': {
    id: 'enums.payment_options.paypal.desc',
    defaultMessage: 'Payment by PayPal.',
  },
  'enums.payment_options.paypal.name': {
    id: 'enums.payment_options.paypal.name',
    defaultMessage: 'PayPal',
  },

  'enums.profile_features.offered_in_spanish.desc': {
    id: 'enums.profile_features.offered_in_spanish.desc',
    defaultMessage: 'Sessions are available for those who speak Spanish.',
  },
  'enums.profile_features.offered_in_spanish.name': {
    id: 'enums.profile_features.offered_in_spanish.name',
    defaultMessage: 'Offered in Spanish',
  },

  'enums.profile_features.tools_and_equipment.desc': {
    id: 'enums.profile_features.tools_and_equipment.desc',
    defaultMessage: 'Tools and equipment will be provided by the expert.',
  },
  'enums.profile_features.tools_and_equipment.name': {
    id: 'enums.profile_features.tools_and_equipment.name',
    defaultMessage: 'Equipment provided',
  },

  'enums.profile_session_booking_statuses.booked.desc': {
    id: 'enums.profile_session_booking_statuses.booked.desc',
    defaultMessage:
      'The booking is booked. The user may cancel a booked session.',
  },
  'enums.profile_session_booking_statuses.booked.name': {
    id: 'enums.profile_session_booking_statuses.booked.name',
    defaultMessage: 'Booked',
  },

  'enums.profile_session_booking_statuses.cancelled.desc': {
    id: 'enums.profile_session_booking_statuses.cancelled.desc',
    defaultMessage: 'The booking has been cancelled. This is a terminal state.',
  },
  'enums.profile_session_booking_statuses.cancelled.name': {
    id: 'enums.profile_session_booking_statuses.cancelled.name',
    defaultMessage: 'Cancelled',
  },

  'enums.profile_session_booking_statuses.pending.desc': {
    id: 'enums.profile_session_booking_statuses.pending.desc',
    defaultMessage: 'The booking is pending.',
  },
  'enums.profile_session_booking_statuses.pending.name': {
    id: 'enums.profile_session_booking_statuses.pending.name',
    defaultMessage: 'Pending',
  },

  'enums.profile_session_booking_statuses.waiting.desc': {
    id: 'enums.profile_session_booking_statuses.waiting.desc',
    defaultMessage: [
      'Session is full but user wants to be on the waiting list in case someone cancels. ',
      'When someone cancels, then the next person on the waiting list becomes booked.',
    ].join(''),
  },
  'enums.profile_session_booking_statuses.waiting.name': {
    id: 'enums.profile_session_booking_statuses.waiting.name',
    defaultMessage: 'Waiting',
  },

  'enums.profile_session_date_statuses.cancelled.desc': {
    id: 'enums.profile_session_date_statuses.cancelled.desc',
    defaultMessage:
      'The session date has been cancelled. This is a terminal state.',
  },
  'enums.profile_session_date_statuses.cancelled.name': {
    id: 'enums.profile_session_date_statuses.cancelled.name',
    defaultMessage: 'Cancelled',
  },

  'enums.profile_session_date_statuses.provisional.desc': {
    id: 'enums.profile_session_date_statuses.provisional.desc',
    defaultMessage: 'The session date is provisional. Not ready to be booked.',
  },
  'enums.profile_session_date_statuses.provisional.name': {
    id: 'enums.profile_session_date_statuses.provisional.name',
    defaultMessage: 'Provisional',
  },

  'enums.profile_session_date_statuses.scheduled.desc': {
    id: 'enums.profile_session_date_statuses.scheduled.desc',
    defaultMessage:
      'The session date has been scheduled meaning it is available to be booked.',
  },
  'enums.profile_session_date_statuses.scheduled.name': {
    id: 'enums.profile_session_date_statuses.scheduled.name',
    defaultMessage: 'Scheduled',
  },

  'enums.profile_session_types.at_home.desc': {
    id: 'enums.profile_session_types.at_home.desc',
    defaultMessage: [
      "This session is also available at a shadower's home.",
    ].join(' '),
  },
  'enums.profile_session_types.at_home.name': {
    id: 'enums.profile_session_types.at_home.name',
    defaultMessage: 'At-home',
  },

  'enums.profile_session_types.interactive.desc': {
    id: 'enums.profile_session_types.interactive.desc',
    defaultMessage: [
      'Shadowers will gain hands-on experience using your tools and equipment.',
    ].join(' '),
  },
  'enums.profile_session_types.interactive.name': {
    id: 'enums.profile_session_types.interactive.name',
    defaultMessage: 'Interactive',
  },

  'enums.profile_session_types.private.desc': {
    id: 'enums.profile_session_types.private.desc',
    defaultMessage: [
      'Only the first person to book with you can book the remaining spots.',
    ].join(' '),
  },
  'enums.profile_session_types.private.name': {
    id: 'enums.profile_session_types.private.name',
    defaultMessage: 'Private',
  },

  'enums.profile_session_types.shadowing_only.desc': {
    id: 'enums.profile_session_types.shadowing_only.desc',
    defaultMessage: [
      'Shadowers will follow you, ask questions and observe your work.',
    ].join(' '),
  },
  'enums.profile_session_types.shadowing_only.name': {
    id: 'enums.profile_session_types.shadowing_only.name',
    defaultMessage: 'Observation only',
  },

  'enums.profile_session_types.tools_and_equipment_provided.desc': {
    id: 'enums.profile_session_types.tools_and_equipment_provided.desc',
    defaultMessage: [
      'You will provide the necessary supplies for the session.',
    ].join(' '),
  },
  'enums.profile_session_types.tools_and_equipment_provided.name': {
    id: 'enums.profile_session_types.tools_and_equipment_provided.name',
    defaultMessage: 'Equipment provided',
  },

  'enums.session_locations.usa.ca.los_angeles.desc': {
    id: 'enums.session_locations.usa.ca.los_angeles.desc',
    defaultMessage: 'Sessions located in or near Los Angeles, CA.',
  },
  'enums.session_locations.usa.ca.los_angeles.name': {
    id: 'enums.session_locations.usa.ca.los_angeles.name',
    defaultMessage: 'Los Angeles',
  },

  'enums.session_locations.usa.ca.san_diego.desc': {
    id: 'enums.session_locations.usa.ca.san_diego.desc',
    defaultMessage: 'Sessions located in or near San Diego, CA.',
  },
  'enums.session_locations.usa.ca.san_diego.name': {
    id: 'enums.session_locations.usa.ca.san_diego.name',
    defaultMessage: 'San Diego',
  },

  'enums.session_locations.usa.co.denver.desc': {
    id: 'enums.session_locations.usa.co.denver.desc',
    defaultMessage: 'Sessions located in or near Denver, CO.',
  },

  'enums.session_locations.usa.co.denver.name': {
    id: 'enums.session_locations.usa.co.denver.name',
    defaultMessage: 'Denver',
  },

  'enums.session_locations.usa.co.fort_collins.desc': {
    id: 'enums.session_locations.usa.co.fort_collins.desc',
    defaultMessage: 'Sessions located in or near Fort Collins, CO.',
  },
  'enums.session_locations.usa.co.fort_collins.name': {
    id: 'enums.session_locations.usa.co.fort_collins.name',
    defaultMessage: 'Fort Collins',
  },

  'enums.session_locations.usa.co.boulder.desc': {
    id: 'enums.session_locations.usa.co.boulder.desc',
    defaultMessage: 'Sessions located in or near Boulder, CO.',
  },

  'enums.session_locations.usa.co.boulder.name': {
    id: 'enums.session_locations.usa.co.boulder.name',
    defaultMessage: 'Boulder',
  },

  'enums.session_locations.usa.co.colorado_springs.desc': {
    id: 'enums.session_locations.usa.co.colorado_springs.desc',
    defaultMessage: 'Sessions located in or near Colorado Springs, CO.',
  },

  'enums.session_locations.usa.co.colorado_springs.name': {
    id: 'enums.session_locations.usa.co.colorado_springs.name',
    defaultMessage: 'Colorado Springs',
  },

  'enums.session_locations.usa.ga.atlanta.desc': {
    id: 'enums.session_locations.usa.ga.atlanta.desc',
    defaultMessage: 'Sessions located in or near Atlanta, GA.',
  },
  'enums.session_locations.usa.ga.atlanta.name': {
    id: 'enums.session_locations.usa.ga.atlanta.name',
    defaultMessage: 'Atlanta',
  },

  'enums.session_locations.usa.ma.boston.desc': {
    id: 'enums.session_locations.usa.ma.boston.desc',
    defaultMessage: 'Sessions located in or near Boston, MA.',
  },
  'enums.session_locations.usa.ma.boston.name': {
    id: 'enums.session_locations.usa.ma.boston.name',
    defaultMessage: 'Boston',
  },

  'enums.session_locations.usa.ny.new_york_city.desc': {
    id: 'enums.session_locations.usa.ny.new_york_city.desc',
    defaultMessage: 'Sessions located in or near New York City, NY.',
  },
  'enums.session_locations.usa.ny.new_york_city.name': {
    id: 'enums.session_locations.usa.ny.new_york_city.name',
    defaultMessage: 'New York City',
  },

  'enums.session_locations.usa.tx.dallas.desc': {
    id: 'enums.session_locations.usa.tx.dallas.desc',
    defaultMessage: 'Sessions located in or near Dallas, TX.',
  },
  'enums.session_locations.usa.tx.dallas.name': {
    id: 'enums.session_locations.usa.tx.dallas.name',
    defaultMessage: 'Dallas',
  },

  'enums.session_locations.usa.tx.houston.desc': {
    id: 'enums.session_locations.usa.tx.houston.desc',
    defaultMessage: 'Sessions located in or near Houston, TX.',
  },
  'enums.session_locations.usa.tx.houston.name': {
    id: 'enums.session_locations.usa.tx.houston.name',
    defaultMessage: 'Houston',
  },

  'errors.account.deactivate.no_payout_account': {
    id: 'errors.account.deactivate.no_payout_account',
    defaultMessage:
      'You still having pending payouts but no Stripe account has been linked.',
    description: '',
  },

  'errors.file.too_large': {
    id: 'errors.file.too_large',
    defaultMessage: 'File size too large. Size must not exceed {size}.',
  },

  'errors.file.unsupported_format': {
    id: 'errors.file.unsupported_format',
    defaultMessage:
      'Unsupported file format: {unsupported}. Supported formats: {supported}.',
  },

  'errors.image.too_large': {
    id: 'errors.image.too_large',
    defaultMessage: 'Image dimensions must not exceed {dimensions} pixels.',
  },

  'errors.image.too_small': {
    id: 'errors.image.too_small',
    defaultMessage: 'Image dimensions must be at least {dimensions} pixels.',
  },

  'errors.message': {
    id: 'errors.message',
    defaultMessage: '{message}',
    description:
      'A general error message. Whatever value `message` has is the error message.',
  },

  'errors.payment_method.add.customer_attached': {
    id: 'errors.payment_method.add.customer_attached',
    defaultMessage: 'A customer is already attached to the payment source.',
    description: 'A customer is already attached to the payment source.',
  },

  'errors.payment_method.add.max_allowed': {
    id: 'errors.payment_method.add.max_allowed',
    defaultMessage: [
      'No more payment methods allowed.',
      'Please remove one before adding another.',
    ].join(' '),
    description: 'The maximum amount of payment methods has been reached.',
  },

  'errors.payment_method.add.nonexistent': {
    id: 'errors.payment_method.add.nonexistent',
    defaultMessage: 'Cannot add a nonexistent payment source.',
    description: 'Cannot add a nonexistent payment source.',
  },

  'errors.payment_method.add.not_reusable': {
    id: 'errors.payment_method.add.not_reusable',
    defaultMessage: 'Payment source must be reusable.',
    description: 'Only a reusable payment source may be added.',
  },

  'errors.payment_method.add.status_invalid': {
    id: 'errors.payment_method.add.status_invalid',
    defaultMessage: 'Payment source status must be one of {statuses}.',
    description: 'Only certain payment source statuses may be added.',
  },

  'errors.payment_method.add.type_invalid': {
    id: 'errors.payment_method.add.type_invalid',
    defaultMessage: 'Payment source type must be one of {types}.',
    description: 'Only certain payment source types may be added.',
  },

  'errors.unauthenticated': {
    id: 'errors.unauthenticated',
    defaultMessage:
      'Authentication is required to perform the {action} action.',
    description:
      'Authentication is required to perform the request action specified by `action`.',
  },

  'errors.unexpected': {
    id: 'errors.unexpected',
    defaultMessage: 'An unexpected error occurred.',
    description: 'An unexpected error occurred.',
  },

  'validators.value.after_now': {
    id: 'validators.value.after_now',
    defaultMessage: '{label} must be now or in the past.',
    description: 'The field with the given `label` must be now or in the past.',
  },
  'validators.value.before_now': {
    id: 'validators.value.before_now',
    defaultMessage: '{label} must be now or in the future.',
    description:
      'The field with the given `label` must be now or in the future.',
  },
  'validators.value.before_other': {
    id: 'validators.value.before_other',
    defaultMessage: '{label} must be equal or after {other}.',
    description:
      'The field with `label` must be equal or after the field with `other`.',
  },
  'validators.value.does_not_match': {
    id: 'validators.value.does_not_match',
    defaultMessage: '{label} must match the pattern: {pattern}.',
    description:
      'The field with the given `label` must match the pattern: `pattern`.',
  },
  'validators.value.does_not_match_other': {
    id: 'validators.value.does_not_match_other',
    defaultMessage: '{label} must match {other}.',
    description:
      'The field with the given `label` must match the other field with the given `other`.',
  },
  'validators.value.does_not_match_password': {
    id: 'validators.value.does_not_match_password',
    defaultMessage: '{label} must match existing password.',
    description:
      'The field with the given `label` must match the existing password.',
  },
  'validators.value.duplicate': {
    id: 'validators.value.duplicate',
    defaultMessage: '"{value}" has already been taken.',
    description: 'The `value` for `label` has already been taken.',
  },
  'validators.value.has_length_not_between': {
    id: 'validators.value.has_length_not_between',
    defaultMessage:
      '{label} must be at least {minLength} characters long but no more than {maxLength}.',
    description:
      'The field with the given `label` must contain at least `minLength` characters but no more than `maxLength` characters.',
  },
  'validators.value.has_length_lt': {
    id: 'validators.value.has_length_lt',
    defaultMessage: '{label} must be at least {minLength} characters long.',
    description:
      'The field with the given `label` must be at least `minLength` characters long.',
  },
  'validators.value.has_length_gt': {
    id: 'validators.value.has_length_gt',
    defaultMessage: '{label} must be no more than {maxLength} characters long.',
    description:
      'The field with the given `label` must be no more than `maxLength` characters long.',
  },
  'validators.value.has_size_not_between': {
    id: 'validators.value.has_size_not_between',
    defaultMessage:
      '{label} must be at least {minSize} long but no more than {maxSize}.',
    description:
      'The field with the given `label` must contain at least `minSize` items but no more than `maxSize` items.',
  },
  'validators.value.is_invalid': {
    id: 'validators.value.is_invalid',
    defaultMessage: '{label} has an invalid value of "{value}".',
    description:
      'A generic error about the field with the given `label` having an invalid `value`.',
  },
  'validators.value.is_not_email': {
    id: 'validators.value.is_not_email',
    defaultMessage: '{label} must be a valid email address.',
    description:
      'The field with the given `label` must be a valid email address.',
  },
  'validators.value.is_not_integer': {
    id: 'validators.value.is_not_integer',
    defaultMessage: '{label} must be an integer.',
    description: 'The field with the given `label` must be an integer.',
  },
  'validators.value.is_not_number': {
    id: 'validators.value.is_not_number',
    defaultMessage: '{label} must be a number.',
    description: 'The field with the given `label` must be a number.',
  },
  'validators.value.is_not_safe_text': {
    id: 'validators.value.is_not_safe_text',
    defaultMessage: '{label} must only contain safe characters.',
    description:
      'The field with the given `label` must only contain safe characters.',
  },
  'validators.value.is_not_strong_password': {
    id: 'validators.value.is_not_strong_password',
    defaultMessage: '{label} must be strong.',
    description: 'The field with the given `label` must be a strong password.',
  },
  'validators.value.is_not_us_phone': {
    id: 'validators.value.is_not_us_phone',
    defaultMessage: '{label} must be a valid phone number.',
    description:
      'The field with the given `label` must be a valid phone number.',
  },
  'validators.value.is_not_us_phone_10': {
    id: 'validators.value.is_not_us_phone_10',
    defaultMessage: '{label} must be a valid ten digit phone number.',
    description:
      'The field with the given `label` must be a valid ten digit phone number.',
  },
  'validators.value.is_not_zip_code': {
    id: 'validators.value.is_not_zip_code',
    defaultMessage: '{label} must be a valid zip code.',
    description: 'The field with the given `label` must be a valid zip code.',
  },
  'validators.value.is_not_zip_code_5': {
    id: 'validators.value.is_not_zip_code_5',
    defaultMessage: '{label} must be a valid five digit zip code.',
    description:
      'The field with the given `label` must be a valid five digit zip code.',
  },
  'validators.value.is_not_zip_code_plus_4': {
    id: 'validators.value.is_not_zip_code_plus_4',
    defaultMessage:
      '{label} must be a valid zip code plus a four digit extension.',
    description:
      'The field with the given `label` must be a valid zip code plus a four digit extension.',
  },
  'validators.value.is_number_not_between': {
    id: 'validators.value.is_number_not_between',
    defaultMessage: '{label} must be between {min} and {max} (inclusive).',
    description:
      'The field with the given `label` must be between `min` and `max` (inclusive).',
  },
  'validators.value.is_number_lt': {
    id: 'validators.value.is_number_lt',
    defaultMessage: '{label} must be no less than {min}.',
    description: 'The field with the given `label` must be no less than `min`.',
  },
  'validators.value.is_number_gt': {
    id: 'validators.value.is_number_gt',
    defaultMessage: '{label} must be no more than {max}.',
    description: 'The field with the given `label` must be no more than `max`.',
  },
  'validators.value.is_required': {
    id: 'validators.value.is_required',
    defaultMessage: '{label} is required.',
    description: 'The field with the given `label` is required.',
  },
  'validators.value.must_choose_at_least': {
    id: 'validators.value.must_choose_at_least',
    defaultMessage: 'Must choose at least {count} {entity}.',
    description:
      'The set of fields requires the user to choose at least `count` `entity`s.',
  },

  'ui.expert_count': {
    id: 'ui.expert_count',
    defaultMessage: '{expertCount}+ experts',
    description:
      'The number of experts for a particular category, location, etc.',
  },

  expert: {
    id: 'shadower.expert',
    defaultMessage: 'Expert',
    description: 'Menu item',
  },
  // home: {
  //   id: 'shadower.home',
  //   defaultMessage: 'Home',
  //   description: 'Menu item',
  // },
  // becomeAHost: {
  //   id: 'shadower.becomeAHost',
  //   defaultMessage: 'Become a host',
  //   description: 'Menu item',
  // },
  // help: {
  //   id: 'shadower.help',
  //   defaultMessage: 'Help',
  //   description: 'Menu item',
  // },
  login: {
    id: 'shadower.login',
    defaultMessage: 'Log in',
    description: 'Log in',
  },
  signup: {
    id: 'shadower.signup',
    defaultMessage: 'Sign up',
    description: 'Sign up',
  },
  // host: {
  //   id: 'shadower.host',
  //   defaultMessage: 'Host',
  //   description: 'Menu item',
  // },
  // manageListing: {
  //   id: 'shadower.manageListing',
  //   defaultMessage: 'Manage Listing',
  //   description: 'Menu item',
  // },
  // listYourSpace: {
  //   id: 'shadower.listYourSpace',
  //   defaultMessage: 'List Your Space',
  //   description: 'Menu item',
  // },
  // yourReservations: {
  //   id: 'shadower.yourReservations',
  //   defaultMessage: 'Your Reservations',
  //   description: 'Menu item',
  // },
  transactionHistory: {
    id: 'shadower.transactionHistory',
    defaultMessage: 'Transaction History',
    description: 'Menu item',
  },
  // profile: {
  //   id: 'shadower.profile',
  //   defaultMessage: 'Profile',
  //   description: 'Menu item',
  // },
  // accountSettings: {
  //   id: 'shadower.accountSettings',
  //   defaultMessage: 'Account Settings',
  //   description: 'Menu item',
  // },
  // trips: {
  //   id: 'shadower.trips',
  //   defaultMessage: 'Trips',
  //   description: 'Menu item',
  // },
  // dashboard: {
  //   id: 'shadower.dashboard',
  //   defaultMessage: 'Dashboard',
  //   description: 'Menu item',
  // },
  // editProfile: {
  //   id: 'shadower.editProfile',
  //   defaultMessage: 'Edit Profile',
  //   description: 'Menu item',
  // },
  messages: {
    id: 'shadower.messages',
    defaultMessage: 'Messages',
    description: 'Menu item',
  },
  logout: {
    id: 'shadower.logout',
    defaultMessage: 'Logout',
    description: 'Menu item',
  },
  // about: {
  //   id: 'shadower.about',
  //   defaultMessage: 'About',
  //   description: 'Menu item',
  // },
  // careers: {
  //   id: 'shadower.careers',
  //   defaultMessage: 'Careers',
  //   description: 'Menu item',
  // },
  // press: {
  //   id: 'shadower.press',
  //   defaultMessage: 'Press',
  //   description: 'Menu item',
  // },
  // policies: {
  //   id: 'shadower.policies',
  //   defaultMessage: 'Policies',
  //   description: 'Menu item',
  // },
  // discover: {
  //   id: 'shadower.discover',
  //   defaultMessage: 'Discover',
  //   description: 'Menu item',
  // },
  // trustSafety: {
  //   id: 'shadower.trustSafety',
  //   defaultMessage: 'Trust & Safety',
  //   description: 'Menu item',
  // },
  // travelCredit: {
  //   id: 'shadower.travelCredit',
  //   defaultMessage: 'Travel Credit',
  //   description: 'Menu item',
  // },
  // citizen: {
  //   id: 'shadower.citizen',
  //   defaultMessage: 'Citizen',
  //   description: 'Menu item',
  // },
  // businessTravel: {
  //   id: 'shadower.businessTravel',
  //   defaultMessage: 'Business Travel',
  //   description: 'Menu item',
  // },
  // guidebooks: {
  //   id: 'shadower.guidebooks',
  //   defaultMessage: 'Guidebooks',
  //   description: 'Menu item',
  // },
  // hosting: {
  //   id: 'shadower.hosting',
  //   defaultMessage: 'Hosting',
  //   description: 'Menu item',
  // },
  // whyHost: {
  //   id: 'shadower.whyHost',
  //   defaultMessage: 'Why Host',
  //   description: 'Menu item',
  // },
  // hospitality: {
  //   id: 'shadower.hospitality',
  //   defaultMessage: 'Hospitality',
  //   description: 'Menu item',
  // },
  // responsibleHosting: {
  //   id: 'shadower.responsibleHosting',
  //   defaultMessage: 'Responsible Hosting',
  //   description: 'Menu item',
  // },
  // termsPrivacy: {
  //   id: 'shadower.termsPrivacy',
  //   defaultMessage: 'Terms & Privacy',
  //   description: 'Menu item',
  // },
  or: {
    id: 'shadower.or',
    defaultMessage: 'or',
    description: 'or',
  },
  signUp: {
    id: 'shadower.signUp',
    defaultMessage: 'Sign Up',
    description: 'Sign Up Button',
  },
  noAccount: {
    id: 'shadower.noAccount',
    defaultMessage: "Don't have an account?",
    description: "Don't have an account?",
  },
  email: {
    id: 'shadower.email',
    defaultMessage: 'Email',
    description: 'Your email address to log in',
  },
  emailRequired: {
    id: 'shadower.emailRequired',
    defaultMessage: 'Email is required',
    description: 'Email is required',
  },
  emailInvalid: {
    id: 'shadower.emailInvalid',
    defaultMessage: 'Invalid email address',
    description: 'Invalid email address',
  },
  loginCredentialsInvalid: {
    id: 'login.credentials.invalid',
    defaultMessage:
      "Email or password is invalid. Make sure it's typed in correctly or “Sign Up” instead.",
    description: 'Email or password invalid.',
  },
  password: {
    id: 'shadower.password',
    defaultMessage: 'Password',
    description: 'Your Password to Login',
  },
  passwordRequired: {
    id: 'shadower.passwordRequired',
    defaultMessage: 'Password is required',
    description: 'Password is required',
  },
  passwordInvalid: {
    id: 'shadower.passwordInvalid',
    defaultMessage:
      'Your password must be at least 8 characters. Please try again',
    description: 'Invalid Password',
  },
  somethingWentWrong: {
    id: 'shadower.somethingWentWrong',
    defaultMessage: 'Sorry, something went wrong. Please try again.',
    description: 'Wrong went wrong',
  },
  loggedIn: {
    id: 'shadower.loggedIn',
    defaultMessage: 'You are already logged in!',
    description: 'You are already logged-in',
  },
  cantLogin: {
    id: 'shadower.cantLogin',
    defaultMessage: "Can't log in?",
    description: 'No se puede iniciar sesión?',
  },
  terms1: {
    id: 'shadower.terms1',
    defaultMessage:
      "By signing up, I confirm that I have read and agree to Shadower's ",
    description: 'Terms content part1',
  },
  // terms2: {
  //   id: 'shadower.terms2',
  //   defaultMessage:
  //     'Terms of Service, Nondiscrimination Policy, Payments Terms of Service, Privacy Policy, Guest Refund Policy, and Host Guarantee Terms.',
  //   description: 'Terms content part1',
  // },
  signupWithEmail: {
    id: 'shadower.signupWithEmail',
    defaultMessage: 'Sign up with email',
    description: 'Login Modal title',
  },
  facebook: {
    id: 'shadower.facebook',
    defaultMessage: 'Facebook',
    description: 'sign up with facebook',
  },
  google: {
    id: 'shadower.google',
    defaultMessage: 'Google',
    description: 'Signup with Google',
  },
  signupWith: {
    id: 'shadower.signupWith',
    defaultMessage: 'Sign up with',
    description: 'Signup with',
  },
  alreadyHaveAccount: {
    id: 'shadower.alreadyHaveAccount',
    defaultMessage: 'Already have an account?',
    description: 'alreadyHaveAccount',
  },
  termsOfService: {
    id: 'shadower.termsOfService',
    defaultMessage: 'Terms of Service',
    description: 'Terms of Service',
  },
  // nonDiscriminationPolicy: {
  //   id: 'shadower.nonDiscriminationPolicy',
  //   defaultMessage: 'NonDiscrimination Policy',
  //   description: 'NonDiscrimination Policy',
  // },
  firstName: {
    id: 'shadower.firstName',
    defaultMessage: 'First name',
    description: 'Your First name to Register',
  },
  firstNameRequired: {
    id: 'shadower.firstNameRequired',
    defaultMessage: 'First name is required',
    description: 'First name required',
  },
  lastName: {
    id: 'shadower.lastName',
    defaultMessage: 'Last name',
    description: 'Your last name to register',
  },
  lastNameRequired: {
    id: 'shadower.lastNameRequired',
    defaultMessage: 'Last name is required',
    description: 'Last name required',
  },
  // dateOfBirth: {
  //   id: 'shadower.dateOfBirth',
  //   defaultMessage: 'Birthday',
  //   description: 'Your Birthday to Register',
  // },
  // dateOfBirthRequired: {
  //   id: 'shadower.dateOfBirthRequired',
  //   defaultMessage: 'Birthday is required',
  //   description: 'Birthday Required',
  // },
  day: {
    id: 'shadower.day',
    defaultMessage: 'Day',
    description: 'Day of birthday',
  },
  dayRequired: {
    id: 'shadower.dayRequired',
    defaultMessage: 'Day is required',
    description: 'Day is required',
  },
  month: {
    id: 'shadower.month',
    defaultMessage: 'Month',
    description: 'Month of birthday',
  },
  monthRequired: {
    id: 'shadower.monthRequired',
    defaultMessage: 'Month is required',
    description: 'Month is required',
  },
  year: {
    id: 'shadower.year',
    defaultMessage: 'Year',
    description: 'Year of birthday',
  },
  yearRequired: {
    id: 'shadower.yearRequired',
    defaultMessage: 'Year is required',
    description: 'Year is required',
  },
  mustBe18OrOld: {
    id: 'shadower.mustBe18OrOld',
    defaultMessage: 'You must be at least 18 years old',
    description: 'You must at least 18 years old',
  },
  WrongDayChosen: {
    id: 'shadower.WrongDayChosen',
    defaultMessage: 'Wrong day chosen for the month',
    description: 'Wrong day chosen for the month',
  },
  birthDay: {
    id: 'shadower.birthDay',
    defaultMessage: 'Date of Birth',
    description: 'birthDay',
  },
  paymentTerms: {
    id: 'shadower.paymentTerms',
    defaultMessage: 'Payments Terms of Service',
    description: 'Payments Terms of Service',
  },
  privacyPolicy: {
    id: 'shadower.privacyPolicy',
    defaultMessage: 'Privacy Policy',
    description: 'Privacy Policy',
  },
  guestRefundPolicy: {
    id: 'shadower.guestRefundPolicy',
    defaultMessage: 'Guest Refund Policy',
    description: 'Guest Refund Policy',
  },
  and: {
    id: 'shadower.and',
    defaultMessage: 'and',
    description: 'And',
  },
  expertGuaranteeTerms: {
    id: 'shadower.expertGuaranteeTerms',
    defaultMessage: 'Host Guarantee Terms',
    description: 'Host Guarantee Terms',
  },
  forgotPassword: {
    id: 'shadower.forgotPassword',
    defaultMessage: 'Forgot Password',
    description: 'Forgot Password',
  },
  forgotPasswordInfo: {
    id: 'shadower.forgotPasswordInfo',
    defaultMessage:
      'Enter the email address associated with your account, and we’ll email you a link to reset your password.',
    description: 'Forgot password info',
  },
  backToLogin: {
    id: 'shadower.backToLogin',
    defaultMessage: 'Back to login',
    description: 'Back to login',
  },
  sendLink: {
    id: 'shadower.sendLink',
    defaultMessage: 'Send Link',
    description: 'Send Link',
  },
  inbox: {
    id: 'shadower.inbox',
    defaultMessage: 'Inbox',
    description: 'Inbox',
  },
  yourListings: {
    id: 'shadower.yourListings',
    defaultMessage: 'Your Listings',
    description: 'Your Listings',
  },
  yourTrips: {
    id: 'shadower.yourTrips',
    defaultMessage: 'Your Trips',
    description: 'Your Trips',
  },
  account: {
    id: 'shadower.account',
    defaultMessage: 'Account',
    description: 'Account',
  },
  addListing: {
    id: 'shadower.addListing',
    defaultMessage: 'Add New Listings',
    description: ' Add New Listings Button',
  },
  profilePhoto: {
    id: 'shadower.profilePhoto',
    defaultMessage: 'Profile Photo',
    description: 'Profile Photo',
  },
  trustAndVerification: {
    id: 'shadower.trustAndVerification',
    defaultMessage: 'Trust and Verification',
    description: 'Trust and Verification',
  },
  reviews: {
    id: 'shadower.reviews',
    defaultMessage: 'Reviews',
    description: 'Reviews',
  },
  review: {
    id: 'shadower.review',
    defaultMessage: 'Review',
    description: 'Review',
  },
  viewProfile: {
    id: 'shadower.viewProfile',
    defaultMessage: 'View Profile',
    description: 'View Profile',
  },
  payment: {
    id: 'shadower.payment',
    defaultMessage: 'Payment',
    description: 'Payment',
  },
  payoutPreferences: {
    id: 'shadower.payoutPreferences',
    defaultMessage: 'Payout Preferences',
    description: 'Payout Preferences',
  },
  security: {
    id: 'shadower.security',
    defaultMessage: 'Security',
    description: 'Security',
  },
  settings: {
    id: 'shadower.settings',
    defaultMessage: 'Settings',
    description: 'Settings',
  },
  yourFirstSession: {
    id: 'shadower.yourFirstSession',
    defaultMessage: 'Your First Session',
    description: 'Your First Session',
  },
  bookedSessions: {
    id: 'shadower.bookedSessions',
    defaultMessage: 'Booked Sessions',
    description: 'Booked Sessions',
  },
  savedSessions: {
    id: 'shadower.savedSessions',
    defaultMessage: 'Saved Sessions',
    description: 'Saved Sessions',
  },
  favorites: {
    id: 'shadower.favorites',
    defaultMessage: 'Favorites',
    description: 'Favorites',
  },

  // where: {
  //   id: 'shadower.where',
  //   defaultMessage: 'Where',
  //   description: 'Where',
  // },
  // when: {
  //   id: 'shadower.when',
  //   defaultMessage: 'When',
  //   description: 'When',
  // },
  // guest: {
  //   id: 'shadower.guest',
  //   defaultMessage: 'Guests',
  //   description: 'Guests',
  // },
  // search: {
  //   id: 'shadower.search',
  //   defaultMessage: 'Search',
  //   description: 'Search',
  // },
  // homeWhere: {
  //   id: 'shadower.homeWhere',
  //   defaultMessage: 'Destination, city, address',
  //   description: 'Destination, city, address',
  // },
  // checkIn: {
  //   id: 'shadower.checkIn',
  //   defaultMessage: 'Check In',
  //   description: 'Check In',
  // },
  // checkOut: {
  //   id: 'shadower.checkOut',
  //   defaultMessage: 'Check Out',
  //   description: 'Check Out',
  // },
  // recommended: {
  //   id: 'shadower.recommended',
  //   defaultMessage: 'Recommended',
  //   description: 'Recommended Home page Slider title',
  // },
  // mostViewed: {
  //   id: 'shadower.mostViewed',
  //   defaultMessage: 'Most Viewed',
  //   description: 'Most Viewed Home page Slider title',
  // },
  // location: {
  //   id: 'shadower.location',
  //   defaultMessage: 'Location',
  //   description: 'Location',
  // },
  // dates: {
  //   id: 'shadower.dates',
  //   defaultMessage: 'Dates',
  //   description: 'Dates',
  // },
  // roomType: {
  //   id: 'shadower.roomType',
  //   defaultMessage: 'Room Type',
  //   description: 'Type of Room',
  // },
  // priceRange: {
  //   id: 'shadower.priceRange',
  //   defaultMessage: 'Price Range',
  //   description: 'Price Range',
  // },
  // instantBook: {
  //   id: 'shadower.instantBook',
  //   defaultMessage: 'Instant Book',
  //   description: 'Instant Book',
  // },
  // instantBookInfo: {
  //   id: 'shadower.instantBookInfo',
  //   defaultMessage: 'Listings you can book without waiting for host approval.',
  //   description: 'instant Book Information',
  // },
  // size: {
  //   id: 'shadower.size',
  //   defaultMessage: 'Size',
  //   description: 'Size',
  // },
  // anyBed: {
  //   id: 'shadower.anyBed',
  //   defaultMessage: 'Any bedrooms',
  //   description: 'Any bedrooms',
  // },
  // anyBath: {
  //   id: 'shadower.anyBath',
  //   defaultMessage: 'Any bathrooms',
  //   description: 'Any bathrooms',
  // },
  // anyBeds: {
  //   id: 'shadower.anyBeds',
  //   defaultMessage: 'Any beds',
  //   description: 'Any beds',
  // },
  // filter: {
  //   id: 'shadower.filter',
  //   defaultMessage: 'Filter',
  //   description: 'Filter button',
  // },
  // seeHomes: {
  //   id: 'shadower.seeHomes',
  //   defaultMessage: 'See Homes',
  //   description: 'See Homes',
  // },
  // rentals: {
  //   id: 'shadower.rentals',
  //   defaultMessage: 'Rentals',
  //   description: 'Rentals',
  // },
  // aminities: {
  //   id: 'shadower.aminities',
  //   defaultMessage: 'Amenities',
  //   description: 'Amenities',
  // },
  // facilities: {
  //   id: 'shadower.facilities',
  //   defaultMessage: 'Facilities',
  //   description: 'Facilities',
  // },
  // houseRules: {
  //   id: 'shadower.houseRules',
  //   defaultMessage: 'House Rules',
  //   description: 'House Rules',
  // },
  // cancel: {
  //   id: 'shadower.cancel',
  //   defaultMessage: 'Cancel',
  //   description: 'Cancel button',
  // },
  // applyFilters: {
  //   id: 'shadower.applyFilters',
  //   defaultMessage: 'Apply filters',
  //   description: 'Apply filters button',
  // },
  // map: {
  //   id: 'shadower.map',
  //   defaultMessage: 'Map',
  //   description: 'Map button for mobile',
  // },
  // filters: {
  //   id: 'shadower.filters',
  //   defaultMessage: 'Filters',
  //   description: 'Filters button for mobile',
  // },
  // results: {
  //   id: 'shadower.results',
  //   defaultMessage: 'Results',
  //   description: 'Results button for mobile',
  // },
  // noResultsTitle: {
  //   id: 'shadower.noResultsTitle',
  //   defaultMessage: 'No Results',
  //   description: 'No results message for search listing',
  // },
  // noResultsSubTitle: {
  //   id: 'shadower.noResultsSubTitle',
  //   defaultMessage: 'Try adjusting your search. Here are some ideas:',
  //   description: 'No results message for search listing',
  // },
  // noResultsTerms1: {
  //   id: 'shadower.noResultsTerms1',
  //   defaultMessage: 'Change your filters or dates',
  //   description: 'No results message for search listing',
  // },
  // noResultsTerms2: {
  //   id: 'shadower.noResultsTerms2',
  //   defaultMessage: 'Zoom out on the map',
  //   description: 'No results message for search listing',
  // },
  // noResultsTerms3: {
  //   id: 'shadower.noResultsTerms3',
  //   defaultMessage: 'Search for a specific city, address',
  //   description: 'No results message for search listing',
  // },
  // aboutListing: {
  //   id: 'shadower.aboutListing',
  //   defaultMessage: 'About this listing',
  //   description: 'View listing description',
  // },
  // contactHost: {
  //   id: 'shadower.contactHost',
  //   defaultMessage: 'Contact Host',
  //   description: 'Contact host button and link',
  // },
  // theSpace: {
  //   id: 'shadower.theSpace',
  //   defaultMessage: 'The space',
  //   description: 'The space',
  // },
  // accommodates: {
  //   id: 'shadower.accommodates',
  //   defaultMessage: 'Accommodates',
  //   description: 'The space accommodates',
  // },
  // bedrooms: {
  //   id: 'shadower.bedrooms',
  //   defaultMessage: 'Bedrooms',
  //   description: 'The space bedrooms',
  // },
  // bathrooms: {
  //   id: 'shadower.bathrooms',
  //   defaultMessage: 'Bathrooms',
  //   description: 'The space bathrooms',
  // },
  // beds: {
  //   id: 'shadower.beds',
  //   defaultMessage: 'Beds',
  //   description: 'The space beds',
  // },
  // propertyType: {
  //   id: 'shadower.propertyType',
  //   defaultMessage: 'Property type',
  //   description: 'The space property type',
  // },
  // sharedSpaces: {
  //   id: 'shadower.sharedSpaces',
  //   defaultMessage: 'Shared Spaces',
  //   description: 'View listing shared spaces',
  // },
  // prices: {
  //   id: 'shadower.prices',
  //   defaultMessage: 'Prices',
  //   description: 'View listing prices',
  // },
  // extraPeople: {
  //   id: 'shadower.extraPeople',
  //   defaultMessage: 'Extra people',
  //   description: 'View listing extra people',
  // },
  // noCharge: {
  //   id: 'shadower.noCharge',
  //   defaultMessage: 'No Charge',
  //   description: 'Extra people no charge',
  // },
  // cancellations: {
  //   id: 'shadower.cancellations',
  //   defaultMessage: 'Cancellations',
  //   description: 'View listing cancellations',
  // },
  // viewDetails: {
  //   id: 'shadower.viewDetails',
  //   defaultMessage: 'View details',
  //   description: 'Cancellations view details',
  // },
  // safetyFeatures: {
  //   id: 'shadower.safetyFeatures',
  //   defaultMessage: 'Safety features',
  //   description: 'View listing Safety features',
  // },
  // availability: {
  //   id: 'shadower.availability',
  //   defaultMessage: 'Availability',
  //   description: 'View listing availability',
  // },
  // in: {
  //   id: 'shadower.in',
  //   defaultMessage: 'in',
  //   description: 'in',
  // },
  shadowers: {
    id: 'shadower.shadowers',
    defaultMessage: 'Shadowers',
  },
  experts: {
    id: 'shadower.experts',
    defaultMessage: 'Experts',
  },
  // bedroom: {
  //   id: 'shadower.bedroom',
  //   defaultMessage: 'Bedroom',
  //   description: 'Bedroom',
  // },
  // bed: {
  //   id: 'shadower.bed',
  //   defaultMessage: 'Bed',
  //   description: 'Bed',
  // },
  // noReviewTitle: {
  //   id: 'shadower.noReviewTitle',
  //   defaultMessage: 'This place would love your review',
  //   description: 'View listing no review title',
  // },
  // noReviewInfo: {
  //   id: 'shadower.noReviewInfo',
  //   defaultMessage:
  //     'When you book this place, here’s where your review will show up!',
  //   description: 'View listing no review info',
  // },
  // loadMore: {
  //   id: 'shadower.loadMore',
  //   defaultMessage: 'Load More',
  //   description: 'Load more button',
  // },
  // viewCountInfo: {
  //   id: 'shadower.viewCountInfo',
  //   defaultMessage: 'This home is on people minds.',
  //   description: 'View listing view count details',
  // },
  // viewCountTotal1: {
  //   id: 'shadower.viewCountTotal1',
  //   defaultMessage: 'It’s been viewed',
  //   description: 'View listing view count details',
  // },
  // viewCountTotal2: {
  //   id: 'shadower.viewCountTotal2',
  //   defaultMessage: 'times in the past week.',
  //   description: 'View listing view count details',
  // },
  // neighborhood: {
  //   id: 'shadower.neighborhood',
  //   defaultMessage: 'The neighborhood',
  //   description: 'View listing neighborhood',
  // },
  // neighborhoodInfo: {
  //   id: 'shadower.neighborhoodInfo',
  //   defaultMessage:
  //     'Exact location information is provided after a booking is confirmed.',
  //   description: 'View listing neighborhoodInfo',
  // },
  // yourHost: {
  //   id: 'shadower.yourHost',
  //   defaultMessage: 'Your Host',
  //   description: 'View listing your host',
  // },
  // responseRate: {
  //   id: 'shadower.responseRate',
  //   defaultMessage: 'Response rate',
  //   description: 'View listing response rate',
  // },
  // responseTime: {
  //   id: 'shadower.responseTime',
  //   defaultMessage: 'Response time',
  //   description: 'View listing response time',
  // },
  // joinedIn: {
  //   id: 'shadower.joinedIn',
  //   defaultMessage: 'Joined in',
  //   description: 'View listing host joining date',
  // },
  // contactHostinfo1: {
  //   id: 'shadower.contactHostinfo1',
  //   defaultMessage: 'Make sure you share the following',
  //   description: 'Contact host instructions',
  // },
  // contactHostinfo2: {
  //   id: 'shadower.contactHostinfo2',
  //   defaultMessage: 'Tell',
  //   description: 'Contact host instructions',
  // },
  // contactHostinfo3: {
  //   id: 'shadower.contactHostinfo3',
  //   defaultMessage: 'a little about yourself',
  //   description: 'Contact host instructions',
  // },
  // contactHostinfo4: {
  //   id: 'shadower.contactHostinfo4',
  //   defaultMessage: 'What brings you to',
  //   description: 'Contact host instructions',
  // },
  // contactHostinfo5: {
  //   id: 'shadower.contactHostinfo5',
  //   defaultMessage: 'Who’s joining you',
  //   description: 'Contact host instructions',
  // },
  // contactHostinfo6: {
  //   id: 'shadower.contactHostinfo6',
  //   defaultMessage: 'What do you love about this listing? Mention it',
  //   description: 'Contact host instructions',
  // },
  // contactHostDate: {
  //   id: 'shadower.contactHostDate',
  //   defaultMessage: 'When are you traveling',
  //   description: 'Contact host dates title',
  // },
  // hostErrorMessage1: {
  //   id: 'shadower.hostErrorMessage1',
  //   defaultMessage: 'Please specify check in and check out',
  //   description: 'Contact host error message',
  // },
  // hostErrorMessage2: {
  //   id: 'shadower.hostErrorMessage2',
  //   defaultMessage: 'Those dates are not available',
  //   description: 'Contact host error message',
  // },
  // hostErrorMessage3: {
  //   id: 'shadower.hostErrorMessage3',
  //   defaultMessage: 'You can contact the host now',
  //   description: 'Contact host error message',
  // },
  // chooseGuests: {
  //   id: 'shadower.chooseGuests',
  //   defaultMessage: 'Choose Guests',
  //   description: 'Contact host choose guests dropdown',
  // },
  // textBoxMessage: {
  //   id: 'shadower.textBoxMessage',
  //   defaultMessage: 'Start your message',
  //   description: 'Contact host text box start your message',
  // },
  sendMessage: {
    id: 'shadower.sendMessage',
    defaultMessage: 'Send message',
    description: 'Contact host button',
  },
  // contacthostError1: {
  //   id: 'shadower.contacthostError1',
  //   defaultMessage: 'You must select number of guests',
  //   description: 'Contact host error message',
  // },
  // contacthostError2: {
  //   id: 'shadower.contacthostError2',
  //   defaultMessage: 'Message is required',
  //   description: 'Contact host error message',
  // },
  // serviceFee: {
  //   id: 'shadower.serviceFee',
  //   defaultMessage: 'Service fee',
  //   description: 'Bill details',
  // },
  // cleaningFee: {
  //   id: 'shadower.cleaningFee',
  //   defaultMessage: 'Cleaning fee',
  //   description: 'Bill details',
  // },
  // total: {
  //   id: 'shadower.total',
  //   defaultMessage: 'Total',
  //   description: 'Bill details',
  // },
  // nights: {
  //   id: 'shadower.nights',
  //   defaultMessage: 'nights',
  //   description: 'Bill details',
  // },
  // checkAvailability: {
  //   id: 'shadower.checkAvailability',
  //   defaultMessage: 'Check Availability',
  //   description: 'Booking button',
  // },
  // viewOtherListings: {
  //   id: 'shadower.viewOtherListings',
  //   defaultMessage: 'View Other Listings',
  //   description: 'Booking button',
  // },
  // book: {
  //   id: 'shadower.book',
  //   defaultMessage: 'Book',
  //   description: 'Booking button',
  // },
  // requestToBook: {
  //   id: 'shadower.requestToBook',
  //   defaultMessage: 'Request to Book',
  //   description: 'Booking button',
  // },
  // maximumStay: {
  //   id: 'shadower.maximumStay',
  //   defaultMessage: 'Maximum stay is',
  //   description: 'Maximum day error',
  // },
  // perNight: {
  //   id: 'shadower.perNight',
  //   defaultMessage: 'Per Night',
  //   description: 'Per Night',
  // },
  // bookingInfo: {
  //   id: 'shadower.bookingInfo',
  //   defaultMessage: 'You won’t be charged yet',
  //   description: 'You won’t be charged yet',
  // },
  // viewPhotos: {
  //   id: 'shadower.viewPhotos',
  //   defaultMessage: 'View Photos',
  //   description: 'View photos button',
  // },
  // cancellationPolicies: {
  //   id: 'shadower.cancellationPolicies',
  //   defaultMessage: 'Cancellation Policies',
  //   description: 'Cancellation Policies',
  // },
  // flexible: {
  //   id: 'shadower.flexible',
  //   defaultMessage: 'Flexible',
  //   description: 'Flexible',
  // },
  // moderate: {
  //   id: 'shadower.moderate',
  //   defaultMessage: 'Moderate',
  //   description: 'Moderate',
  // },
  // strict: {
  //   id: 'shadower.strict',
  //   defaultMessage: 'Strict',
  //   description: 'Strict',
  // },
  // addProfilePhoto: {
  //   id: 'shadower.addProfilePhoto',
  //   defaultMessage: 'Add your profile photo',
  //   description: 'Avatar upload title',
  // },
  // uploadInfo: {
  //   id: 'shadower.uploadInfo',
  //   defaultMessage:
  //     'This helps your host recognize you when you meet, so make sure it shows your face.',
  //   description: 'Avatar upload info',
  // },
  // continue: {
  //   id: 'shadower.continue',
  //   defaultMessage: 'Continue',
  //   description: 'Continue button',
  // },
  dropzoneUpload: {
    id: 'shadower.dropzoneUpload',
    defaultMessage: 'Upload a photo',
    description: 'Dropzone upload info',
  },
  // dropzoneUploadError: {
  //   id: 'shadower.dropzoneUploadError',
  //   defaultMessage: 'Remove the existing image and try upload again',
  //   description: 'Dropzone upload error message',
  // },
  // meetupTitle: {
  //   id: 'shadower.meetupTitle',
  //   defaultMessage: 'Get ready to book with',
  //   description: 'Get ready to book with',
  // },
  // meetupInfo1: {
  //   id: 'shadower.meetupInfo1',
  //   defaultMessage:
  //     'We ask everyone to confirm a few things before traveling or hosting.',
  //   description: 'Meetup information',
  // },
  // meetupInfo2: {
  //   id: 'shadower.meetupInfo2',
  //   defaultMessage: 'You’ll only have to do this once.',
  //   description: 'Meetup information',
  // },
  next: {
    id: 'shadower.next',
    defaultMessage: 'Next',
    description: 'Next button',
  },
  // checkEmail: {
  //   id: 'shadower.checkEmail',
  //   defaultMessage: 'Check your email',
  //   description: 'Email verification title',
  // },
  // verificationInfo1: {
  //   id: 'shadower.verificationInfo1',
  //   defaultMessage: 'Tap the link in the email we sent you.',
  //   description: 'Verification info',
  // },
  // verificationInfo2: {
  //   id: 'shadower.verificationInfo2',
  //   defaultMessage:
  //     'Confirming your email address helps us send you trip information.',
  //   description: 'Verification info',
  // },
  // resendEmail: {
  //   id: 'shadower.resendEmail',
  //   defaultMessage: 'Resend email',
  //   description: 'Resend email button',
  // },
  // changeEmail: {
  //   id: 'shadower.changeEmail',
  //   defaultMessage: 'Change email address',
  //   description: 'Change email address',
  // },
  // aboutYourTrip: {
  //   id: 'shadower.aboutYourTrip',
  //   defaultMessage: 'About Your Trip',
  //   description: 'Payment About Your Trip',
  // },
  // whoComing: {
  //   id: 'shadower.whoComing',
  //   defaultMessage: 'Who`s Coming',
  //   description: 'Who`s Coming',
  // },
  // sayHello: {
  //   id: 'shadower.sayHello',
  //   defaultMessage: 'Say hello to your host and tell them why you’re coming',
  //   description: 'Payment page info',
  // },
  // descriptionInfo: {
  //   id: 'shadower.descriptionInfo',
  //   defaultMessage:
  //     'Visiting family or friends? Seeing the sights? This helps your host plan for your trip.',
  //   description: 'Payment description information',
  // },
  // payment: {
  //   id: 'shadower.payment',
  //   defaultMessage: 'Payment',
  //   description: 'Payment',
  // },
  // paymentInfo: {
  //   id: 'shadower.paymentInfo',
  //   defaultMessage:
  //     'You’ll only be charged if your request is accepted by the host.They’ll have 24 hours to accept or decline.',
  //   description: 'Payment information',
  // },
  // paymentCurrency: {
  //   id: 'shadower.paymentCurrency',
  //   defaultMessage: 'Payment Currency',
  //   description: 'Payment currency',
  // },
  chooseCurrency: {
    id: 'shadower.chooseCurrency',
    defaultMessage: 'Choose Currency',
    description: 'Choose Currency',
  },
  // paymentType: {
  //   id: 'shadower.paymentType',
  //   defaultMessage: 'Payment Type',
  //   description: 'Payment Type',
  // },
  // paypal: {
  //   id: 'shadower.paypal',
  //   defaultMessage: 'Paypal',
  //   description: 'Paypal',
  // },
  // loginInfo: {
  //   id: 'shadower.loginInfo',
  //   defaultMessage:
  //     'Log into PayPal. You will be able to review your request before it is final.',
  //   description: 'Login information',
  // },
  // currencyError: {
  //   id: 'shadower.currencyError',
  //   defaultMessage: 'Choose a payment currency',
  //   description: 'Currency Error',
  // },
  // hostedBy: {
  //   id: 'shadower.hostedBy',
  //   defaultMessage: 'Hosted by',
  //   description: 'Hosted by',
  // },
  // cancellationPolicy: {
  //   id: 'shadower.cancellationPolicy',
  //   defaultMessage: 'Cancellation policy',
  //   description: 'Cancellation policy',
  // },
  // deleteListing: {
  //   id: 'shadower.deleteListing',
  //   defaultMessage: 'Delete this Listing',
  //   description: 'Manage listing delete this listing',
  // },
  // deleteListingInfo: {
  //   id: 'shadower.deleteListingInfo',
  //   defaultMessage:
  //     'Are you sure you want to delete this listing? You cannot reverse this action.',
  //   description: 'Manage listing delete listing information',
  // },
  // delete: {
  //   id: 'shadower.delete',
  //   defaultMessage: 'Delete',
  //   description: 'Manage listing Delete button',
  // },
  // goBack: {
  //   id: 'shadower.goBack',
  //   defaultMessage: 'Go Back',
  //   description: 'Manage listing Go back button',
  // },
  // progressBarText1: {
  //   id: 'shadower.progressBarText1',
  //   defaultMessage: 'You’re',
  //   description: 'Manage listing Progress bar text',
  // },
  // progressBarText2: {
  //   id: 'shadower.progressBarText2',
  //   defaultMessage: 'done with your listing',
  //   description: 'Manage listing Progress bar text',
  // },
  // listingUpdateInfo: {
  //   id: 'shadower.listingUpdateInfo',
  //   defaultMessage: 'Last updated on',
  //   description: 'Manage listing update info',
  // },
  // editListing: {
  //   id: 'shadower.editListing',
  //   defaultMessage: 'Edit Listings',
  //   description: 'Manage listing button',
  // },
  // finishListing: {
  //   id: 'shadower.finishListing',
  //   defaultMessage: 'Finish the Listings',
  //   description: 'Manage listing button',
  // },
  // preview: {
  //   id: 'shadower.preview',
  //   defaultMessage: 'Preview',
  //   description: 'Manage listing button',
  // },
  // listed: {
  //   id: 'shadower.listed',
  //   defaultMessage: 'Listed',
  //   description: 'Manage listing dropdown',
  // },
  // unListed: {
  //   id: 'shadower.unListed',
  //   defaultMessage: 'Unlisted',
  //   description: 'Manage listing dropdown',
  // },
  // title: {
  //   id: 'shadower.title',
  //   defaultMessage: 'You don`t have any listings!',
  //   description: 'You don`t have any listings!',
  // },
  // content: {
  //   id: 'shadower.content',
  //   defaultMessage:
  //     'Make money by renting out your extra space.You`ll also get to meet interesting travelers from around the world!',
  //   description: 'content',
  // },
  // button: {
  //   id: 'shadower.button',
  //   defaultMessage: 'Post a new listing',
  //   description: 'Post a new listing',
  // },
  // noTripTitle1: {
  //   id: 'shadower.noTripTitle1',
  //   defaultMessage: 'You don`t have any upcoming trips!',
  //   description: 'No trips title',
  // },
  // noTripTitle2: {
  //   id: 'shadower.noTripTitle2',
  //   defaultMessage: 'Choose your next adventure rightaway!',
  //   description: 'No trips title',
  // },
  // noTripsButton: {
  //   id: 'shadower.noTripsButton',
  //   defaultMessage: 'Search Listing',
  //   description: 'No trips button',
  // },
  // noReservation: {
  //   id: 'shadower.noReservation',
  //   defaultMessage: 'You don`t have any upcoming reservations!',
  //   description: 'No reservation',
  // },
  status: {
    id: 'shadower.status',
    defaultMessage: 'Status',
    description: 'Status',
  },
  // dateLocation: {
  //   id: 'shadower.dateLocation',
  //   defaultMessage: 'Dates and Location',
  //   description: 'Dates and Location',
  // },
  details: {
    id: 'shadower.details',
    defaultMessage: 'Details',
    description: 'Details',
  },
  // noList: {
  //   id: 'shadower.noList',
  //   defaultMessage: 'No List Found',
  //   description: 'No List Found',
  // },
  // notexist: {
  //   id: 'shadower.notexist',
  //   defaultMessage: 'Guest profile doesn`t exist',
  //   description: 'Guest profile not exist',
  // },
  // messageHistroy: {
  //   id: 'shadower.messageHistroy',
  //   defaultMessage: 'Message History',
  //   description: 'Message History',
  // },
  // contactSupport: {
  //   id: 'shadower.contactSupport',
  //   defaultMessage: 'Contact Support',
  //   description: 'Contact Support',
  // },
  // viewItinerary: {
  //   id: 'shadower.viewItinerary',
  //   defaultMessage: 'View Itinerary',
  //   description: 'View Itinerary',
  // },
  // viewReceipt: {
  //   id: 'shadower.viewReceipt',
  //   defaultMessage: 'View Receipt',
  //   description: 'View Receipt',
  // },
  // approve: {
  //   id: 'shadower.approve',
  //   defaultMessage: 'Approve',
  //   description: 'Approve',
  // },
  // decline: {
  //   id: 'shadower.decline',
  //   defaultMessage: 'Decline',
  //   description: 'Decline',
  // },
  emailAlreadyExists: {
    id: 'shadower.emailAlreadyExists',
    defaultMessage:
      'That email address is already in use. Please choose a different one or log in using it.',
    description: 'Email address is already exist',
  },
  // iAm: {
  //   id: 'shadower.iam',
  //   defaultMessage: 'I am',
  //   description: 'Gender Label',
  // },
  // gender: {
  //   id: 'shadower.gender',
  //   defaultMessage: 'Gender',
  //   description: 'Gender Field',
  // },
  // genderMale: {
  //   id: 'shadower.genderMale',
  //   defaultMessage: 'Male',
  //   description: 'Gender Male',
  // },
  // genderFemale: {
  //   id: 'shadower.genderFemale',
  //   defaultMessage: 'Female',
  //   description: 'Gender Female',
  // },
  // genderOther: {
  //   id: 'shadower.genderOther',
  //   defaultMessage: 'Other',
  //   description: 'Gender Other',
  // },
  // genderRequired: {
  //   id: 'shadower.genderRequired',
  //   defaultMessage: 'Gender is Required',
  //   description: 'Gender is Required',
  // },
  // preferredLanguage: {
  //   id: 'shadower.preferredLanguage',
  //   defaultMessage: 'Preferred Language',
  //   description: 'Preferred Language',
  // },
  // preferredLanguageRequired: {
  //   id: 'shadower.preferredLanguageRequired',
  //   defaultMessage: 'Preferred Language is Required',
  //   description: 'Preferred Language is Required',
  // },
  // preferredCurrency: {
  //   id: 'shadower.preferredCurrency',
  //   defaultMessage: 'Preferred Currency',
  //   description: 'Preferred Currency',
  // },
  // preferredCurrencyRequired: {
  //   id: 'shadower.preferredCurrencyRequired',
  //   defaultMessage: 'Preferred Currency is Required',
  //   description: 'Preferred Currency is Required',
  // },
  // phoneNumber: {
  //   id: 'shadower.phoneNumber',
  //   defaultMessage: 'Phone Number',
  //   description: 'Phone Number',
  // },
  // phoneNumberRequired: {
  //   id: 'shadower.phoneNumberRequired',
  //   defaultMessage: 'Phone Number is Required',
  //   description: 'Phone Number is Required',
  // },
  // phoneNumberInvalid: {
  //   id: 'shadower.phoneNumberInvalid',
  //   defaultMessage: 'Invalid Phone Number',
  //   description: 'Phone Number is Invalid',
  // },
  // liveLocation: {
  //   id: 'shadower.liveLocation',
  //   defaultMessage: 'Where You Live',
  //   description: 'location',
  // },
  // locationRequired: {
  //   id: 'shadower.locationRequired',
  //   defaultMessage: 'Location is required',
  //   description: 'Location is required',
  // },
  // info: {
  //   id: 'shadower.info',
  //   defaultMessage: 'Describe Yourself',
  //   description: 'Describe Yourself',
  // },
  // infoRequired: {
  //   id: 'shadower.infoRequired',
  //   defaultMessage: 'Describe Yourself is Required',
  //   description: 'Describe Yourself is Required',
  // },
  // notLoggedIn: {
  //   id: 'shadower.notLoggedIn',
  //   defaultMessage: 'You are not logged In. Please login to continue!',
  //   description: 'Wrong went wrong',
  // },
  // save: {
  //   id: 'shadower.save',
  //   defaultMessage: 'Save',
  //   description: 'Save Button',
  // },
  birthDayRequired: {
    id: 'shadower.birthDayRequired',
    defaultMessage: 'Select your birth date to continue',
    description: 'birthDayRequired',
  },
  // Required: {
  //   id: 'shadower.Required',
  //   defaultMessage: 'Required',
  //   description: 'Required Heading',
  // },
  // lastNameInfo: {
  //   id: 'shadower.lastNameInfo',
  //   defaultMessage:
  //     'Your public profile only shows your first name. When you request a booking, your host will see your first and last name.',
  //   description: 'lastNameInfo',
  // },
  // genderInfo: {
  //   id: 'shadower.genderInfo',
  //   defaultMessage:
  //     'We use this data for analysis and never share it with other users.',
  //   description: 'genderInfo',
  // },
  // emailInfo: {
  //   id: 'shadower.emailInfo',
  //   defaultMessage:
  //     'We won’t share your private email address with other users.',
  //   description: 'emailInfo',
  // },
  // learnMore: {
  //   id: 'shadower.learnMore',
  //   defaultMessage: 'Learn More',
  //   description: 'learnMore',
  // },
  // phoneNumberInfo: {
  //   id: 'shadower.phoneNumberInfo',
  //   defaultMessage:
  //     'This is only shared once you have a confirmed booking with another user. This is how we can all get in touch.',
  //   description: 'phoneNumberInfo',
  // },
  // preferredLanguageInfo: {
  //   id: 'shadower.preferredLanguageInfo',
  //   defaultMessage: 'We’ll send you messages in this language.',
  //   description: 'preferredLanguageInfo',
  // },
  // preferredCurrencyInfo: {
  //   id: 'shadower.preferredCurrencyInfo',
  //   defaultMessage: 'We’ll show you prices in this currency.',
  //   description: 'preferredCurrencyInfo',
  // },
  // info1: {
  //   id: 'shadower.info1',
  //   defaultMessage: 'Help other people get to know you.',
  //   description: 'info1',
  // },
  // info2: {
  //   id: 'shadower.info2',
  //   defaultMessage:
  //     'Tell them about the things you like: What are 5 things you can’t live without? Share your favorite travel destinations, books, movies, shows, music, food.',
  //   description: 'info2',
  // },
  // info3: {
  //   id: 'shadower.info3',
  //   defaultMessage:
  //     'Tell them what it’s like to have you as a guest or host: What’s your style of traveling? Of hosting?',
  //   description: 'info3',
  // },
  // info4: {
  //   id: 'shadower.info4',
  //   defaultMessage: 'Tell them about you: Do you have a life motto?',
  //   description: 'info4',
  // },
  profilePhotoInfo: {
    id: 'shadower.profilePhotoInfo',
    defaultMessage:
      'Please upload a square photo that clearly shows your face.',
    description: 'Profile photo upload information',
  },
  // verifiedInfo: {
  //   id: 'shadower.verifiedInfo',
  //   defaultMessage: 'Verified Info',
  //   description: 'Verified Info',
  // },
  // verifiedEmail: {
  //   id: 'shadower.verifiedEmail',
  //   defaultMessage: 'You have verified your email address',
  //   description: 'You have verified your email address',
  // },
  // facebookInfo: {
  //   id: 'shadower.facebookInfo',
  //   defaultMessage:
  //     'Sign in with Facebook and discover your trusted connections to hosts and guests all over the world.',
  //   description: 'Facebook information',
  // },
  // disconnect: {
  //   id: 'shadower.disconnect',
  //   defaultMessage: 'Disconnect',
  //   description: 'Disconnect button',
  // },
  // googleInfo: {
  //   id: 'shadower.googleInfo',
  //   defaultMessage:
  //     'Connect your account to your Google account for simplicity and ease.',
  //   description: 'Google information',
  // },
  // notVerifiedDetails: {
  //   id: 'shadower.notVerifiedDetails',
  //   defaultMessage: 'You haven`t verified any of the below items',
  //   description: 'Not verified information',
  // },
  // notVerifiedInfo: {
  //   id: 'shadower.notVerifiedInfo',
  //   defaultMessage: 'Not Verified Info',
  //   description: 'Not Verified Info',
  // },
  // pleaseVerify: {
  //   id: 'shadower.pleaseVerify',
  //   defaultMessage: 'Please verify your email address.',
  //   description: 'Verify your email address.',
  // },
  // verifyEmail: {
  //   id: 'shadower.verifyEmail',
  //   defaultMessage: 'Verify Email',
  //   description: 'Verify Email',
  // },
  // connect: {
  //   id: 'shadower.connect',
  //   defaultMessage: 'Connect',
  //   description: 'Connect button',
  // },
  // reviewTitle: {
  //   id: 'shadower.reviewTitle',
  //   defaultMessage: 'Thank you for reviewing',
  //   description: 'Review',
  // },
  // reviewTitle2: {
  //   id: 'shadower.reviewTitle2',
  //   defaultMessage:
  //     'Thank you for reviewing your guest. Your review will be visible on their profile.',
  //   description: 'Review',
  // },
  finish: {
    id: 'shadower.finish',
    defaultMessage: 'Finish',
    description: 'Finish button',
  },
  // reviewPageTitle: {
  //   id: 'shadower.reviewPageTitle',
  //   defaultMessage: 'Describe your experience (required)',
  //   description: 'Review',
  // },
  // reviewPageTitle1: {
  //   id: 'shadower.reviewPageTitle1',
  //   defaultMessage: 'Your review will be public on your guest profile',
  //   description: 'Review',
  // },
  // reviewTextArea: {
  //   id: 'shadower.reviewTextArea',
  //   defaultMessage: 'What was it like host this guest',
  //   description: 'Review',
  // },
  // reviewRating: {
  //   id: 'shadower.reviewRating',
  //   defaultMessage: 'Overall Rating',
  //   description: 'Review',
  // },
  // submit: {
  //   id: 'shadower.submit',
  //   defaultMessage: 'Submit',
  //   description: 'Submit button',
  // },
  // reviewError1: {
  //   id: 'shadower.reviewError1',
  //   defaultMessage: 'Please write your review!',
  //   description: 'Review',
  // },
  // reviewError2: {
  //   id: 'shadower.reviewError2',
  //   defaultMessage: 'Please rate your review!',
  //   description: 'Review',
  // },
  // pastReviewTitle: {
  //   id: 'shadower.pastReviewTitle',
  //   defaultMessage: 'Past Reviews You’ve Written',
  //   description: 'Review',
  // },
  // noReview: {
  //   id: 'shadower.noReview',
  //   defaultMessage: 'There is no review to show!',
  //   description: 'Review',
  // },
  // response: {
  //   id: 'shadower.response',
  //   defaultMessage: 'Response',
  //   description: 'Review',
  // },
  // reviewPanelTitle1: {
  //   id: 'shadower.reviewPanelTitle',
  //   defaultMessage: 'Reviews About you',
  //   description: 'Review',
  // },
  // reviewPanelTitle2: {
  //   id: 'shadower.reviewPanelTitle2',
  //   defaultMessage: 'Reviews By you',
  //   description: 'Review',
  // },
  // reviewContent: {
  //   id: 'shadower.reviewContent',
  //   defaultMessage:
  //     'Nobody to review right now. Looks like it’s time for another trip!',
  //   description: 'Review',
  // },
  // submitReviewFor: {
  //   id: 'shadower.submitReviewFor',
  //   defaultMessage: 'Submit a public review for',
  //   description: 'Review',
  // },
  // writeReview: {
  //   id: 'shadower.writeReview',
  //   defaultMessage: 'Write a Review',
  //   description: 'Review',
  // },
  // viewLitneray: {
  //   id: 'shadower.viewLitneray',
  //   defaultMessage: 'View litneray',
  //   description: 'Review',
  // },
  // reviewWritten: {
  //   id: 'shadower.reviewWritten',
  //   defaultMessage:
  //     'Reviews are written at the end of a reservation. Reviews you’ve received will be visible both here and on your public profile.',
  //   description: 'Review',
  // },
  // hey: {
  //   id: 'shadower.hey',
  //   defaultMessage: 'Hey I`m',
  //   description: 'View profile',
  // },
  // reportUser: {
  //   id: 'shadower.reportUser',
  //   defaultMessage: 'Report this user',
  //   description: 'View profile',
  // },
  // noVerifications: {
  //   id: 'shadower.noVerifications',
  //   defaultMessage: 'No verifications yet',
  //   description: 'View profile',
  // },
  // addVerifications: {
  //   id: 'shadower.addVerifications',
  //   defaultMessage: 'Add Verifications',
  //   description: 'View profile',
  // },
  // emailConfirmed: {
  //   id: 'shadower.emailConfirmed',
  //   defaultMessage: 'Email Confirmed',
  //   description: 'View profile',
  // },
  // fbConnected: {
  //   id: 'shadower.fbConnected',
  //   defaultMessage: 'Facebook Connected',
  //   description: 'View profile',
  // },
  // googleConnected: {
  //   id: 'shadower.googleConnected',
  //   defaultMessage: 'Google Connected',
  //   description: 'View profile',
  // },
  // moreVerifications: {
  //   id: 'shadower.moreVerifications',
  //   defaultMessage: 'Add More Verifications',
  //   description: 'View profile',
  // },
  addPayout: {
    id: 'shadower.addPayout',
    defaultMessage: 'Add Payout Method',
    description: 'Payout',
  },
  address1: {
    id: 'shadower.address1',
    defaultMessage: 'Address Line 1',
    description: 'Payout Form',
  },
  address2: {
    id: 'shadower.address2',
    defaultMessage: 'Address Line 2',
    description: 'Payout Form',
  },
  city: {
    id: 'shadower.city',
    defaultMessage: 'City',
    description: 'Payout Form',
  },
  state: {
    id: 'shadower.state',
    defaultMessage: 'State / Province',
    description: 'Payout Form',
  },
  zipCode: {
    id: 'shadower.zipCode',
    defaultMessage: 'ZIP Code / Postal Code',
    description: 'Payout Form',
  },
  payoutError1: {
    id: 'shadower.payoutError1',
    defaultMessage: 'Country is required',
    description: 'Payout Form',
  },
  payoutError2: {
    id: 'shadower.payoutError2',
    defaultMessage: 'City is required',
    description: 'Payout Form',
  },
  payoutError3: {
    id: 'shadower.payoutError3',
    defaultMessage: 'State / Province is required',
    description: 'Payout Form',
  },
  payoutError4: {
    id: 'shadower.payoutError4',
    defaultMessage: 'ZIP Code / Postal Code is required',
    description: 'Payout Form',
  },
  payoutError5: {
    id: 'shadower.payoutError5',
    defaultMessage: 'Enter valid email address',
    description: 'Payout Form',
  },
  payoutError6: {
    id: 'shadower.payoutError6',
    defaultMessage: 'Currency is required',
    description: 'Payout Form',
  },
  country: {
    id: 'shadower.country',
    defaultMessage: 'Country',
    description: 'Payout Form',
  },
  chooseCountry: {
    id: 'shadower.chooseCountry',
    defaultMessage: 'Choose Country',
    description: 'Payout Form',
  },
  back: {
    id: 'shadower.back',
    defaultMessage: 'Back',
    description: 'Back button',
  },
  paypalIntro1: {
    id: 'shadower.paypalIntro1',
    defaultMessage:
      'PayPal is an online payment processing service that allows you to receive payments from',
    description: 'Paypal intro',
  },
  paypalIntro2: {
    id: 'shadower.paypalIntro2',
    defaultMessage: 'To use PayPal with',
    description: 'Paypal intro',
  },
  paypalIntro3: {
    id: 'shadower.paypalIntro3',
    defaultMessage: 'you must have an existing account with PayPal.',
    description: 'Paypal intro',
  },
  paypalIntro4: {
    id: 'shadower.paypalIntro4',
    defaultMessage: 'Learn more about PayPal',
    description: 'Paypal intro',
  },
  paypalEmail: {
    id: 'shadower.paypalEmail',
    defaultMessage:
      'Enter the email address associated with your existing PayPal account.',
    description: 'Paypal email',
  },
  paypalCurrency: {
    id: 'shadower.paypalCurrency',
    defaultMessage: 'In what currency would you like to be paid?',
    description: 'Paypal currency',
  },
  payoutIntro1: {
    id: 'shadower.payoutIntro1',
    defaultMessage:
      'We offer two options for payouts: directly into a bank account or PayPal account. Processing time depends on which payout method you choose.',
    description: 'Payout intro',
  },
  payoutIntro2: {
    id: 'shadower.payoutIntro2',
    defaultMessage:
      'All transfers are handled securely through Stripe or PayPal. We do not collect any financial information and do not have access to the transfers.',
    description: 'Payout intro',
  },
  payoutTitle: {
    id: 'shadower.payoutTitle',
    defaultMessage: 'Method',
    description: 'Payout header',
  },
  payoutTitle1: {
    id: 'shadower.payoutTitle1',
    defaultMessage: 'Processed in',
    description: 'Payout header',
  },
  payoutTitle2: {
    id: 'shadower.payoutTitle2',
    defaultMessage: 'Fees',
    description: 'Payout header',
  },
  payoutTitle3: {
    id: 'shadower.payoutTitle3',
    defaultMessage: 'Currency',
    description: 'Payout header',
  },
  payoutTitle4: {
    id: 'shadower.payoutTitle4',
    defaultMessage: 'Details',
    description: 'Payout header',
  },
  noPayoutFound: {
    id: 'shadower.noPayoutFound',
    defaultMessage: 'No payout methods found.',
    description: 'No payout methods',
  },
  payoutMethod: {
    id: 'shadower.payoutMethod',
    defaultMessage: 'Payout Methods',
    description: 'Payout Method',
  },
  addPayoutMethod: {
    id: 'shadower.addPayoutMethod',
    defaultMessage: 'To get paid, you need to set up a payout method',
    description: 'Payout Method',
  },
  paymentReleaseInfo1: {
    id: 'shadower.paymentReleaseInfo1',
    defaultMessage: 'Payout per session releases within one week. ',
    description: 'Payout Method',
  },
  paymentReleaseInfo2: {
    id: 'shadower.paymentReleaseInfo2',
    defaultMessage:
      'The time it takes for the funds to appear in your account depends on your payout method. ',
    description: 'Payout Method',
  },
  payoutTitleBlock1: {
    id: 'shadower.payoutTitleBlock1',
    defaultMessage:
      'When you receive a payment for a reservation, we call that payment to you a “payout”. Our secure payment system supports several payout methods,which can be setup and edited here. Your available payout options and currencies differ by country.',
    description: 'Payout Method',
  },
  payoutFaq: {
    id: 'shadower.payoutFaq',
    defaultMessage: 'Visit the Payout FAQ',
    description: 'Payout Method',
  },
  options: {
    id: 'shadower.options',
    defaultMessage: 'Options',
    description: 'Payout Method',
  },
  default: {
    id: 'shadower.default',
    defaultMessage: 'Default',
    description: 'Payout Method',
  },
  ready: {
    id: 'shadower.ready',
    defaultMessage: 'Ready',
    description: 'Payout Method',
  },
  setDefault: {
    id: 'shadower.setDefault',
    defaultMessage: 'Set Default',
    description: 'Payout Method',
  },
  remove: {
    id: 'shadower.remove',
    defaultMessage: 'Remove',
    description: 'Payout Method',
  },
  directDeposit: {
    id: 'shadower.directDeposit',
    defaultMessage: 'Direct Deposit, Paypal, etc..',
    description: 'Payout Method',
  },
  transactionPayout: {
    id: 'shadower.transactionPayout',
    defaultMessage: 'Payout',
    description: 'Transaction history',
  },
  transferTo: {
    id: 'shadower.transferTo',
    defaultMessage: 'Transfer to',
    description: 'Transaction history',
  },
  transferDate: {
    id: 'shadower.transferDate',
    defaultMessage: 'Date',
    description: 'Date',
  },
  transferType: {
    id: 'shadower.transferType',
    defaultMessage: 'Type',
    description: 'Type',
  },
  transferAmount: {
    id: 'shadower.transferAmount',
    defaultMessage: 'Amount',
    description: 'Amount',
  },
  paidOut: {
    id: 'shadower.paidOut',
    defaultMessage: 'Paid Out',
    description: 'Paid Out',
  },
  reservation: {
    id: 'shadower.reservation',
    defaultMessage: 'Reservation',
    description: 'Reservation',
  },
  payTo: {
    id: 'shadower.payTo',
    defaultMessage: 'Pay to',
    description: 'Pay to',
  },
  grossEarnings: {
    id: 'shadower.grossEarnings',
    defaultMessage: 'Gross Earnings',
    description: 'Gross Earnings',
  },
  allListings: {
    id: 'shadower.allListings',
    defaultMessage: 'All listings',
    description: 'All listings',
  },
  noTransactions: {
    id: 'shadower.noTransactions',
    defaultMessage: 'No Transactions',
    description: 'No Transactions',
  },
  transactionsAddPayout: {
    id: 'shadower.transactionsAddPayout',
    defaultMessage: 'Add Payout',
    description: 'Transaction payout',
  },
  completedTransactions: {
    id: 'shadower.completedTransactions',
    defaultMessage: 'Completed Transactions',
    description: 'Completed Transactions',
  },
  futureTransactions: {
    id: 'shadower.futureTransactions',
    defaultMessage: 'Future Transactions',
    description: 'Completed Transactions',
  },
  exportCSV: {
    id: 'shadower.exportCSV',
    defaultMessage: 'Export to CSV',
    description: 'Export to CSV',
  },
  noMessagesTitle: {
    id: 'shadower.noMessagesTitle',
    defaultMessage: 'No messages yet.',
    description: 'Inbox',
  },
  noMessagesTitle1: {
    id: 'shadower.noMessagesTitle1',
    defaultMessage:
      'When a shadower or expert messages you, you’ll see their messages here.',
    description: 'Inbox',
  },
  shadower: {
    id: 'shadower.shadower',
    defaultMessage: 'Shadower',
    description: 'Shadower',
  },
  errorMessage: {
    id: 'shadower.errorMessage',
    defaultMessage: 'Oops something went wrong, refresh or check back later',
    description: 'Error message',
  },
  receipt: {
    id: 'shadower.receipt',
    defaultMessage: 'Receipt',
    description: 'Receipt',
  },
  customerReceipt: {
    id: 'shadower.customerReceipt',
    defaultMessage: 'Customer Receipt',
    description: 'Customer Receipt',
  },
  confirmationCode: {
    id: 'shadower.confirmationCode',
    defaultMessage: 'Confirmation Code',
    description: 'Confirmation Code',
  },
  name: {
    id: 'shadower.name',
    defaultMessage: 'Name',
    description: 'Name',
  },
  travelDestination: {
    id: 'shadower.travelDestination',
    defaultMessage: 'Travel Destination',
    description: 'Travel Destination',
  },
  duration: {
    id: 'shadower.duration',
    defaultMessage: 'Duration',
    description: 'Duration',
  },
  accommodationType: {
    id: 'shadower.accommodationType',
    defaultMessage: 'Accommodation Type',
    description: 'Accommodation Type',
  },
  accommodationAddress: {
    id: 'shadower.accommodationAddress',
    defaultMessage: 'Accommodation Address',
    description: 'Accommodation Address',
  },
  accommodationHost: {
    id: 'shadower.accommodationHost',
    defaultMessage: 'Accommodation Host',
    description: 'Accommodation Host',
  },
  reservationCharges: {
    id: 'shadower.reservationCharges',
    defaultMessage: 'Reservation Charges',
    description: 'Reservation Charges',
  },
  paymentReceived: {
    id: 'shadower.paymentReceived',
    defaultMessage: 'Payment Received:',
    description: 'Payment Received:',
  },
  receiptInfo1: {
    id: 'shadower.receiptInfo1',
    defaultMessage:
      'is authorized to accept Accommodation Fees on behalf of the Host as its limited agent.',
    description: 'Receipt information',
  },
  receiptInfo2: {
    id: 'shadower.receiptInfo2',
    defaultMessage:
      'This means that your payment obligation to the Host is satisfied by your payment to',
    description: 'Receipt information',
  },
  receiptInfo3: {
    id: 'shadower.receiptInfo3',
    defaultMessage:
      'Any disagreements by the Host regarding that payment must be settled between the Host and',
    description: 'Receipt information',
  },
  itinerayTitle: {
    id: 'shadower.itinerayTitle',
    defaultMessage: 'You’re going to',
    description: 'Itinerary',
  },
  reservationCode: {
    id: 'shadower.reservationCode',
    defaultMessage: 'Reservation code:',
    description: 'Itinerary',
  },
  address: {
    id: 'shadower.address',
    defaultMessage: 'Address',
    description: 'Itinerary',
  },
  viewListing: {
    id: 'shadower.viewListing',
    defaultMessage: 'View Listing',
    description: 'View Listing',
  },
  messageHost: {
    id: 'shadower.messageHost',
    defaultMessage: 'Message Host',
    description: 'Itinerary',
  },
  billing: {
    id: 'shadower.billing',
    defaultMessage: 'Billing',
    description: 'Itinerary',
  },
  messageAction1: {
    id: 'shadower.messageAction1',
    defaultMessage: 'You messaged',
    description: 'View message',
  },
  messageAction2: {
    id: 'shadower.messageAction2',
    defaultMessage: 'about their listing',
    description: 'View message',
  },
  messageActionInfo: {
    id: 'shadower.messageActionInfo',
    defaultMessage:
      'Most hosts respond within 24 hours. If this listing is your top choice, enter your payment information to officially request a reservation.',
    description: 'View message',
  },
  RequestToBook: {
    id: 'shadower.RequestToBook',
    defaultMessage: 'Request to book',
    description: 'Request to book',
  },
  messageAction3: {
    id: 'shadower.messageAction3',
    defaultMessage: 'Your booking request sent to',
    description: 'View message',
  },
  messageAction4: {
    id: 'shadower.messageAction4',
    defaultMessage: 'for their listing',
    description: 'View message',
  },
  cancelInfo: {
    id: 'shadower.cancelInfo',
    defaultMessage:
      "Most hosts respond within 24 hours. If you don't want to continue with this host further, you can cancel this request.",
    description: 'View message',
  },
  cancelReservation: {
    id: 'shadower.cancelReservation',
    defaultMessage: 'Cancel Reservation',
    description: 'Cancel Reservation',
  },
  requestDeclined: {
    id: 'shadower.requestDeclined',
    defaultMessage: 'Request declined',
    description: 'Request declined',
  },
  guestDeclinedInfo: {
    id: 'shadower.guestDeclinedInfo',
    defaultMessage:
      'Your request is declined by host. You can choose different dates or search for other listing.',
    description: 'Request declined',
  },
  requestApprovedAction1: {
    id: 'shadower.requestApprovedAction1',
    defaultMessage: 'Your booking request approved by',
    description: 'Request approved',
  },
  requestTimeInfo1: {
    id: 'shadower.requestTimeInfo1',
    defaultMessage: 'You have to book within',
    description: 'Request approved',
  },
  requestTimeInfo2: {
    id: 'shadower.requestTimeInfo2',
    defaultMessage: 'or it will expire.',
    description: 'Request approved',
  },
  bookingConfirmedInfo1: {
    id: 'shadower.bookingConfirmedInfo1',
    defaultMessage: 'Your booking is confirmed with',
    description: 'Booking confirmed',
  },
  bookingCanceledInfo: {
    id: 'shadower.bookingCanceledInfo',
    defaultMessage:
      'You can contact your host and do necessary arrangements or you can cancel your reservation.',
    description: 'Booking canceld',
  },
  bookingExpiredTitle: {
    id: 'shadower.bookingExpiredTitle',
    defaultMessage: 'Your booking request is expired',
    description: 'Booking expired',
  },
  bookingExpiredInfo: {
    id: 'shadower.bookingExpiredInfo',
    defaultMessage:
      'Try sending request again or send an inquiry to get in touch with the host',
    description: 'Booking expired',
  },
  gotoListing: {
    id: 'shadower.gotoListing',
    defaultMessage: 'Go to Listing',
    description: 'Go to Listing',
  },
  bookingRequestCancel1: {
    id: 'shadower.bookingRequestCancel',
    defaultMessage: 'Booking request is cancelled',
    description: 'Booking request is cancelled',
  },
  bookingRequestCancel2: {
    id: 'shadower.bookingRequestCance2',
    defaultMessage: 'Your booking request is cancelled.',
    description: 'BYour booking request is cancelled.',
  },
  expertAction1: {
    id: 'shadower.expertAction1',
    defaultMessage: 'Invite',
    description: 'Expert Action Block.',
  },
  expertAction2: {
    id: 'shadower.expertAction2',
    defaultMessage: 'to book by pre-approving this trip',
    description: 'Expert Action Block.',
  },
  expertAction3: {
    id: 'shadower.expertAction3',
    defaultMessage: 'Let',
    description: 'Expert Action Block.',
  },
  expertAction4: {
    id: 'shadower.expertAction4',
    defaultMessage: 'know these dates are available by pre-approving them.',
    description: 'Expert Action Block.',
  },
  expertResponseTime1: {
    id: 'shadower.expertResponseTime1',
    defaultMessage: 'Respond within',
    description: 'Expert Action Block.',
  },
  expertResponseTime2: {
    id: 'shadower.expertResponseTime2',
    defaultMessage: 'to maintain your response rate.',
    description: 'Expert Action Block.',
  },
  preApprove: {
    id: 'shadower.preApprove',
    defaultMessage: 'Pre-approve',
    description: 'Pre-approve',
  },
  guestRequest: {
    id: 'shadower.guestRequest',
    defaultMessage: 'sent a request to booking for your listing',
    description: 'Guest Request',
  },
  requestApproved: {
    id: 'shadower.requestApproved',
    defaultMessage: 'Request Approved',
    description: 'Request Approved',
  },
  timeToExpire: {
    id: 'shadower.timeToExpire',
    defaultMessage:
      'Your guest have 24 hours to repond to this or it will get expired',
    description: 'Time To Expire',
  },
  declinedInfo: {
    id: 'shadower.declinedInfo',
    defaultMessage:
      'Make sure your calendar is up-to-date and that your listing page accurately reflects your house rules and requirements.',
    description: 'Declined Info',
  },
  bookingIsConfirmed: {
    id: 'shadower.bookingIsConfirmed',
    defaultMessage: 'Booking is confirmed',
    description: 'Booking is confirmed',
  },
  contactGuest: {
    id: 'shadower.contactGuest',
    defaultMessage:
      'You can contact your guest and do necessary arrangements or you can cancel.',
    description: 'Contact Guest',
  },
  bookingIsExpired: {
    id: 'shadower.bookingIsExpired',
    defaultMessage: 'Booking request is expired',
    description: 'Booking request is expired',
  },
  bookingIsExpired1: {
    id: 'shadower.bookingIsExpired1',
    defaultMessage: 'booking request is expired.',
    description: 'booking request is expired.',
  },
  bookingRequestCancel3: {
    id: 'shadower.bookingRequestCance3',
    defaultMessage: 'booking request is cancelled.',
    description: 'booking request is cancelled.',
  },
  writeMessage: {
    id: 'shadower.writeMessage',
    defaultMessage: 'Write your message...',
    description: 'Write your message',
  },
  loadMoreMsg: {
    id: 'shadower.loadMoreMsg',
    defaultMessage: 'Load More Messages',
    description: 'Load More Messages',
  },
  noItmesFound: {
    id: 'shadower.noItmesFound',
    defaultMessage: 'No items found',
    description: 'No items found',
  },
  messageStatus1: {
    id: 'shadower.messageStatus1',
    defaultMessage: 'Inquiry',
    description: 'Message Status',
  },
  earnedAmount: {
    id: 'shadower.earnedAmount',
    defaultMessage: 'Earned Amount',
    description: 'Payment details',
  },
  missedEarnings: {
    id: 'shadower.missedEarnings',
    defaultMessage: 'Missed Earnings',
    description: 'Missed Earnings',
  },
  refundAmount: {
    id: 'shadower.refundAmount',
    defaultMessage: 'Refund Amount',
    description: 'Refund Amount',
  },
  cleaningPrice: {
    id: 'shadower.cleaningPrice',
    defaultMessage: 'Cleaning Price',
    description: 'Payment details',
  },
  subTotal: {
    id: 'shadower.subTotal',
    defaultMessage: 'Subtotal',
    description: 'Payment details',
  },
  youEarn: {
    id: 'shadower.youEarn',
    defaultMessage: 'You earn',
    description: 'Payment details',
  },
  tripDetails: {
    id: 'shadower.tripDetails',
    defaultMessage: 'Trip details',
    description: 'Trip details',
  },
  noThreadFound: {
    id: 'shadower.noThreadFound',
    defaultMessage:
      'No thread found! If you think something went wrong, please refresh the page',
    description: 'View Message',
  },
  dashBoardHeader: {
    id: 'shadower.dashBoardHeader',
    defaultMessage: 'Welcome to',
    description: 'Dashboard',
  },
  dashBoardInfo: {
    id: 'shadower.dashBoardInfo',
    defaultMessage:
      'Check your messages, view upcoming trip information, and find travel inspiration all from your dashboard.Before booking your first stay, make sure to:',
    description: 'Dashboard',
  },
  dashBoardInfo1: {
    id: 'shadower.dashBoardInfo1',
    defaultMessage:
      'Upload a photo and write a short bio to help hosts get to know you before inviting you into their home.',
    description: 'Dashboard',
  },
  completeYourProfile: {
    id: 'shadower.completeYourProfile',
    defaultMessage: 'Complete Your Profile',
    description: 'Dashboard',
  },
  allMessages: {
    id: 'shadower.allMessages',
    defaultMessage: 'All Messages',
    description: 'Dashboard',
  },
  hi: {
    id: 'shadower.hi',
    defaultMessage: 'Hi',
    description: 'Hi',
  },
  letYouGetReady: {
    id: 'shadower.letYouGetReady',
    defaultMessage: 'Let’s get you ready to become a host',
    description: 'Let You Get Ready',
  },
  whatKindOfPlace: {
    id: 'shadower.whatKindOfPlace',
    defaultMessage: 'What kind of place do you have?',
    description: 'What Kind Of Place',
  },
  change: {
    id: 'shadower.change',
    defaultMessage: 'Change',
    description: 'Change Link',
  },
  whatKindOfPlaceListing: {
    id: 'shadower.whatKindOfPlaceListing',
    defaultMessage: 'What kind of place are you listing?',
    description: 'What Kind Of Place Listing',
  },
  whatTypeOfProperty: {
    id: 'shadower.whatTypeOfProperty',
    defaultMessage: 'What type of property is this?',
    description: 'What Type Of Property',
  },
  whatGuestHave: {
    id: 'shadower.whatGuestHave',
    defaultMessage: 'What will guests have?',
    description: 'What will guests have',
  },
  howManyRooms: {
    id: 'shadower.howManyRooms',
    defaultMessage: 'How many total rooms does your property have?',
    description: 'How many total rooms does your property have?',
  },
  isPersonalHome: {
    id: 'shadower.isPersonalHome',
    defaultMessage: 'Is this your personal home?',
    description: 'Is this your personal home?',
  },
  isPersonalHomeInfo: {
    id: 'shadower.isPersonalHomeInfo',
    defaultMessage:
      'Guests like to know if this is a place you live and keep your personal belongings in.',
    description: 'Personal Home Info',
  },
  skip: {
    id: 'shadower.skip',
    defaultMessage: 'Skip now',
    description: 'Skip Button',
  },
  howManyGuests: {
    id: 'shadower.howManyGuests',
    defaultMessage: 'How many guests can your place accommodate?',
    description: 'How many guests can your place accommodate?',
  },
  howManyBeds: {
    id: 'shadower.howManyBeds',
    defaultMessage: 'How many beds can guests use?',
    description: 'How many beds can guests use?',
  },
  howManyBedrooms: {
    id: 'shadower.howManyBedrooms',
    defaultMessage: 'How many bedrooms can guests use?',
    description: 'How many bedrooms can guests use?',
  },
  howManyGuestsStay: {
    id: 'shadower.howManyGuestsStay',
    defaultMessage: 'How many guests can stay?',
    description: 'How many guests can stay?',
  },
  howManyBathrooms: {
    id: 'shadower.howManyBathrooms',
    defaultMessage: 'How many bathrooms?',
    description: 'How many bathrooms?',
  },
  whereLocated: {
    id: 'shadower.whereLocated',
    defaultMessage: 'Where’s your place located?',
    description: 'Where’s your place located?',
  },
  bathroom: {
    id: 'shadower.bathroom',
    defaultMessage: 'bathroom',
    description: 'Bathroom',
  },
  yourFullAddress: {
    id: 'shadower.yourFullAddress',
    defaultMessage: 'Your Full Address',
    description: 'Your Full Address',
  },
  street: {
    id: 'shadower.street',
    defaultMessage: 'Street Address',
    description: 'Street Address',
  },
  buildingName: {
    id: 'shadower.buildingName',
    defaultMessage: 'Apt, Suite, Bldg. (optional)',
    description: 'Building Name',
  },
  zipcode: {
    id: 'shadower.zipcode',
    defaultMessage: 'ZIP Code',
    description: 'ZIP Code',
  },
  countryRequired: {
    id: 'shadower.countryRequired',
    defaultMessage: 'Country is Required',
    description: 'countryRequired',
  },
  stateRequired: {
    id: 'shadower.stateRequired',
    defaultMessage: 'State is Required',
    description: 'stateRequired',
  },
  cityRequired: {
    id: 'shadower.cityRequired',
    defaultMessage: 'City is Required',
    description: 'cityRequired',
  },
  streetRequired: {
    id: 'shadower.streetRequired',
    defaultMessage: 'Street is Required',
    description: 'streetRequired',
  },
  zipcodeRequired: {
    id: 'shadower.zipcodeRequired',
    defaultMessage: 'Zip Code is Required',
    description: 'zipcodeRequired',
  },
  houseTypeRequired: {
    id: 'shadower.houseTypeRequired',
    defaultMessage: 'House Type is Required',
    description: 'House Type is Required',
  },
  // whatamenities: {
  //   id: 'shadower.whatamenities',
  //   defaultMessage: 'What amenities do you offer?',
  //   description: 'Amenities Headline',
  // },
  // safetyamenities: {
  //   id: 'shadower.safetyamenities',
  //   defaultMessage: 'Safety amenities',
  //   description: 'Safety amenities Headline',
  // },
  // whatSpace: {
  //   id: 'shadower.whatSpace',
  //   defaultMessage: 'What spaces can guests use?',
  //   description: 'What spaces can guests use?',
  // },
  // step1Heading: {
  //   id: 'shadower.step1Heading',
  //   defaultMessage: 'Become a host',
  //   description: 'STEP 1 Heading',
  // },
  // step2Heading: {
  //   id: 'shadower.step2Heading',
  //   defaultMessage: 'STEP 2',
  //   description: 'STEP 2 Heading',
  // },
  // step3Heading: {
  //   id: 'shadower.step3Heading',
  //   defaultMessage: 'STEP 3',
  //   description: 'STEP 3 Heading',
  // },
  // step1SubHeading: {
  //   id: 'shadower.step1SubHeading',
  //   defaultMessage: 'Start with the basics',
  //   description: 'STEP 1 Sub Heading',
  // },
  // step2SubHeading: {
  //   id: 'shadower.step2SubHeading',
  //   defaultMessage: 'Set the scene',
  //   description: 'STEP 2 Sub Heading',
  // },
  // step3SubHeading: {
  //   id: 'shadower.step3SubHeading',
  //   defaultMessage: 'Get ready for guests',
  //   description: 'STEP 3 Sub Heading',
  // },
  // step1HeadingContent: {
  //   id: 'shadower.step1HeadingContent',
  //   defaultMessage: 'Beds, bathrooms, amenities, and more',
  //   description: 'STEP 1 Heading Content',
  // },
  // step2HeadingContent: {
  //   id: 'shadower.step2HeadingContent',
  //   defaultMessage: 'Photos, short description, title',
  //   description: 'STEP 2 Heading Content',
  // },
  // step3HeadingContent: {
  //   id: 'shadower.step3HeadingContent',
  //   defaultMessage: 'Booking settings, calendar, price',
  //   description: 'STEP 3 Heading Content',
  // },
  // mapWarning: {
  //   id: 'shadower.mapWarning',
  //   defaultMessage: 'Drag the pin to set your location.',
  //   description: 'Map Warning',
  // },
  // mapSuccess: {
  //   id: 'shadower.mapSuccess',
  //   defaultMessage: 'Great! Thank you!',
  //   description: 'Map Success',
  // },
  // photos: {
  //   id: 'shadower.photos',
  //   defaultMessage: 'Photos',
  //   description: 'Photos',
  // },
  // placeTitle: {
  //   id: 'shadower.placeTitle',
  //   defaultMessage: 'Name your place',
  //   description: 'Title',
  // },
  // titleLabel: {
  //   id: 'shadower.titleLabel',
  //   defaultMessage: 'Listing title',
  //   description: 'Title',
  // },
  // titleRequired: {
  //   id: 'shadower.titleRequired',
  //   defaultMessage: 'Oops! We need a title for your place.',
  //   description: 'Title is required',
  // },
  // description: {
  //   id: 'shadower.description',
  //   defaultMessage: 'Edit your description',
  //   description: 'Description',
  // },
  // descriptionRequired: {
  //   id: 'shadower.descriptionRequired',
  //   defaultMessage: 'Description is required',
  //   description: 'Description',
  // },
  // guestRequirementsTitle: {
  //   id: 'shadower.guestRequirementsTitle',
  //   defaultMessage: 'Review guest requirements',
  //   description: 'Guest Requirements Title',
  // },
  // guestRequirementsDescription: {
  //   id: 'shadower.guestRequirementsDescription',
  //   defaultMessage:
  //     'Requires all guests to submit the following before they can book:',
  //   description: 'Guest Requirements Description',
  // },
  // setHouseRules: {
  //   id: 'shadower.setHouseRules',
  //   defaultMessage: 'Set house rules for your guests',
  //   description: 'House Rules',
  // },
  // reviewGuestBookTitle: {
  //   id: 'shadower.reviewGuestBookTitle',
  //   defaultMessage: 'How guests book: Instantly',
  //   description: 'How guests book: Instantly',
  // },
  // reviewGuestBookDescription: {
  //   id: 'shadower.reviewGuestBookDescription',
  //   defaultMessage: 'Guests can book available days instantly as long as they:',
  //   description: 'Guests can book available',
  // },
  // reviewGuestBookNote: {
  //   id: 'shadower.reviewGuestBookNote',
  //   defaultMessage:
  //     'Guests who don’t meet your requirements must send a reservation request.',
  //   description: 'Review Guests Note',
  // },
  // advanceNoticeTitle: {
  //   id: 'shadower.advanceNoticeTitle',
  //   defaultMessage: 'How much notice do you need before a guest arrives?',
  //   description: 'Advance Notice Title',
  // },
  // advanceNoticeCheckInTitle: {
  //   id: 'shadower.advanceNoticeCheckInTitle',
  //   defaultMessage: 'When can guests check in?',
  //   description: 'Advance Notice Check In Title',
  // },
  // advanceNoticeFrom: {
  //   id: 'shadower.advanceNoticeFrom',
  //   defaultMessage: 'From:',
  //   description: 'From',
  // },
  // advanceNoticeTo: {
  //   id: 'shadower.advanceNoticeTo',
  //   defaultMessage: 'To:',
  //   description: 'To',
  // },
  // advanceNoticeError: {
  //   id: 'shadower.advanceNoticeError',
  //   defaultMessage: 'From time should be earlier To time',
  //   description: 'Error Message',
  // },
  // advanceNoticeInvalid: {
  //   id: 'shadower.advanceNoticeInvalid',
  //   defaultMessage: 'Invalid input value',
  //   description: 'Error Message',
  // },
  // MaxDaysNoticeTitle: {
  //   id: 'shadower.MaxDaysNoticeTitle',
  //   defaultMessage: 'How far in advance can guests book?',
  //   description: 'How far in advance can guests book?',
  // },
  // MinMaxNightsTitle: {
  //   id: 'shadower.MinMaxNightsTitle',
  //   defaultMessage: 'How long can guests stay?',
  //   description: 'How long can guests stay?',
  // },
  // calendar: {
  //   id: 'shadower.calendar',
  //   defaultMessage: 'Update your calendar',
  //   description: 'calendar title',
  // },
  // pricing: {
  //   id: 'shadower.pricing',
  //   defaultMessage: 'Base price',
  //   description: 'pricing title',
  // },
  // pricingDescription: {
  //   id: 'shadower.pricingDescription',
  //   defaultMessage: 'Your base price is your default nightly rate.',
  //   description: 'pricing description',
  // },
  // basePrice: {
  //   id: 'shadower.basePrice',
  //   defaultMessage: 'Base price',
  //   description: 'Base price',
  // },
  // basePriceLabel: {
  //   id: 'shadower.basePriceLabel',
  //   defaultMessage: 'Price per night',
  //   description: 'Base price Label',
  // },
  // basePriceInvalid: {
  //   id: 'shadower.basePriceInvalid',
  //   defaultMessage: 'Invalid Base Price, only numbers(eg: 25) are allowed',
  //   description: 'Base price Invalid',
  // },
  // basePriceRequired: {
  //   id: 'shadower.basePriceRequired',
  //   defaultMessage: 'Base Price is required',
  //   description: 'Base Price is required',
  // },
  // cleaningPriceInvalid: {
  //   id: 'shadower.cleaningPriceInvalid',
  //   defaultMessage: 'Invalid Cleaning Price, only numbers(eg: 25) are allowed',
  //   description: 'Cleaning price Invalid',
  // },
  // currency: {
  //   id: 'shadower.currency',
  //   defaultMessage: 'Currency',
  //   description: 'Currency',
  // },
  // discount: {
  //   id: 'shadower.discount',
  //   defaultMessage: 'Discounts',
  //   description: 'Discounts',
  // },
  // discountLabel: {
  //   id: 'shadower.discountLabel',
  //   defaultMessage: '% off',
  //   description: 'Discount Label',
  // },
  // discountWeekly: {
  //   id: 'shadower.discountWeekly',
  //   defaultMessage: 'Weekly discount',
  //   description: 'Weekly discount',
  // },
  // discountInvalid: {
  //   id: 'shadower.discountInvalid',
  //   defaultMessage:
  //     'Invalid discount. The discount must be between 0% and 99%.',
  //   description: 'Invalid discount',
  // },
  // discountMonthly: {
  //   id: 'shadower.discountMonthly',
  //   defaultMessage: 'Monthly discount',
  //   description: 'Monthly discount',
  // },
  // summary: {
  //   id: 'shadower.summary',
  //   defaultMessage: 'Based on your settings, here’s what you could expect',
  //   description: 'Summary Title',
  // },
  // localLaws: {
  //   id: 'shadower.localLaws',
  //   defaultMessage: 'Your local laws and taxes',
  //   description: 'Local Laws',
  // },
  // setCoverPhoto: {
  //   id: 'shadower.setCoverPhoto',
  //   defaultMessage: 'Choose a photo for cover image',
  //   description: 'setCoverPhoto',
  // },
  // photosPlaceholder: {
  //   id: 'shadower.photosPlaceholder',
  //   defaultMessage: 'Click here to upload photos or drag them in',
  //   description: 'Photos Placeholder',
  // },
  // descriptionLabel: {
  //   id: 'shadower.descriptionLabel',
  //   defaultMessage: "Descibe the decor, light, what's nearby, etc...",
  //   description: 'Description Placeholder',
  // },
  // descriptionSubheading: {
  //   id: 'shadower.descriptionSubheading',
  //   defaultMessage: 'Summary',
  //   description: 'Description Subheading',
  // },
  // instantBookingTitle: {
  //   id: 'shadower.instantBookingTitle',
  //   defaultMessage: 'Increase your earnings with Instant Book',
  //   description: 'Instant booking',
  // },
  // instantBookingInfo: {
  //   id: 'shadower.instantBookingInfo',
  //   defaultMessage:
  //     'Instant Book can give your listing an edge.Not only do guests prefer to book instantly,we’re promoting Instant Book listings in search results.',
  //   description: 'Instant booking',
  // },
  // whoCanBook: {
  //   id: 'shadower.whoCanBook',
  //   defaultMessage: 'Who can book instantly',
  //   description: 'Instant booking',
  // },
  // whoCanBookInfo: {
  //   id: 'shadower.whoCanBookInfo',
  //   defaultMessage:
  //     'Choose who can book available days without requesting approval.',
  //   description: 'Instant booking',
  // },
  // whoCanBookInfo1: {
  //   id: 'shadower.whoCanBookInfo1',
  //   defaultMessage:
  //     'Guests who meet your requirements and agree to your rules.',
  //   description: 'Instant booking',
  // },
  // whoCanBookInfo2: {
  //   id: 'shadower.whoCanBookInfo2',
  //   defaultMessage: 'Anyone else must send a reservation request.',
  //   description: 'Instant booking',
  // },
  // whoCanBookInfo3: {
  //   id: 'shadower.whoCanBookInfo3',
  //   defaultMessage: 'No one. All guests must send reservation requests.',
  //   description: 'Instant booking',
  // },
  // maxDaysTitle: {
  //   id: 'shadower.maxDaysTitle',
  //   defaultMessage: 'Booking Window',
  //   description: 'Booking Window',
  // },
  // datesDropDown: {
  //   id: 'shadower.datesDropDown',
  //   defaultMessage: 'Dates unavailable by default',
  //   description: 'Booking Dropdown',
  // },
  // datesOption1: {
  //   id: 'shadower.datesOption1',
  //   defaultMessage: '3 months into the future',
  //   description: 'Booking Dropdown',
  // },
  // datesOption2: {
  //   id: 'shadower.datesOption2',
  //   defaultMessage: '6 months into the future',
  //   description: 'Booking Dropdown',
  // },
  // datesOption3: {
  //   id: 'shadower.datesOption3',
  //   defaultMessage: '9 months into the future',
  //   description: 'Booking Dropdown',
  // },
  // datesOption4: {
  //   id: 'shadower.datesOption4',
  //   defaultMessage: '12 months into the future',
  //   description: 'Booking Dropdown',
  // },
  // datesOption5: {
  //   id: 'shadower.datesOption5',
  //   defaultMessage: 'All future dates',
  //   description: 'Booking Dropdown',
  // },
  // syncCalendars: {
  //   id: 'shadower.syncCalendars',
  //   defaultMessage: 'Sync calendars',
  //   description: 'Sync calendars',
  // },
  // tripLength: {
  //   id: 'shadower.tripLength',
  //   defaultMessage: 'Trip length',
  //   description: 'Trip length',
  // },
  // tripLengthError1: {
  //   id: 'shadower.tripLengthError1',
  //   defaultMessage: 'Minimum nights can`t be higher than maximum nights',
  //   description: 'Trip length',
  // },
  // tabPlaceType: {
  //   id: 'shadower.tabPlaceType',
  //   defaultMessage: 'Place type',
  //   description: 'Tab bar',
  // },
  // baths: {
  //   id: 'shadower.baths',
  //   defaultMessage: 'Baths',
  //   description: 'Tab bar',
  // },
  // setCover: {
  //   id: 'shadower.setCover',
  //   defaultMessage: 'Set Cover Photo',
  //   description: 'Set Cover Photo',
  // },
  // tabDescription: {
  //   id: 'shadower.tabDescription',
  //   defaultMessage: 'Description',
  //   description: 'Tab bar',
  // },
  // tabTitle: {
  //   id: 'shadower.tabTitle',
  //   defaultMessage: 'Title',
  //   description: 'Tab bar',
  // },
  // guestRequirements: {
  //   id: 'shadower.guestRequirements',
  //   defaultMessage: 'Guest Requirements',
  //   description: 'Tab bar',
  // },
  // reviewGuestBook: {
  //   id: 'shadower.reviewGuestBook',
  //   defaultMessage: 'Review GuestBook',
  //   description: 'Tab bar',
  // },
  // advanceNotice: {
  //   id: 'shadower.advanceNotice',
  //   defaultMessage: 'Advance Notice',
  //   description: 'Tab bar',
  // },
  // bookingWindow: {
  //   id: 'shadower.bookingWindow',
  //   defaultMessage: 'Booking window',
  //   description: 'Tab bar',
  // },
  // minMaxNights: {
  //   id: 'shadower.minMaxNights',
  //   defaultMessage: 'Min Max Nights',
  //   description: 'Tab bar',
  // },
  // tabCalendar: {
  //   id: 'shadower.tabCalendar',
  //   defaultMessage: 'Calendar',
  //   description: 'Tab bar',
  // },
  // tabPricing: {
  //   id: 'shadower.tabPricing',
  //   defaultMessage: 'Pricing',
  //   description: 'Tab bar',
  // },
  // tabDiscount: {
  //   id: 'shadower.tabDiscount',
  //   defaultMessage: 'Discount',
  //   description: 'Tab bar',
  // },
  // tabBooking: {
  //   id: 'shadower.tabBooking',
  //   defaultMessage: 'Booking',
  //   description: 'Tab bar',
  // },
  // tabLocalLaws: {
  //   id: 'shadower.tabLocalLaws',
  //   defaultMessage: 'Local Laws',
  //   description: 'Tab bar',
  // },
  // headerText: {
  //   id: 'shadower.headerText',
  //   defaultMessage: 'Step',
  //   description: 'Header Text',
  // },
  // readyToPublish: {
  //   id: 'shadower.readyToPublish',
  //   defaultMessage: 'Your Listing is ready to Publish!',
  //   description: 'Listing inde',
  // },
  // publishNow: {
  //   id: 'shadower.publishNow',
  //   defaultMessage: 'Publish Now',
  //   description: 'Listing index',
  // },
  // previewListing: {
  //   id: 'shadower.previewListing',
  //   defaultMessage: 'Preview Listing',
  //   description: 'Listing index',
  // },
  // listingPublished: {
  //   id: 'shadower.listingPublished',
  //   defaultMessage: 'Your Listing is Published!',
  //   description: 'Listing index',
  // },
  // unPublishNow: {
  //   id: 'shadower.unPublishNow',
  //   defaultMessage: 'UnPublish Now',
  //   description: 'Listing index',
  // },
  // signUpTitle: {
  //   id: 'shadower.signUpTitle',
  //   defaultMessage: 'New User Registration',
  //   description: 'Signup Title',
  // },
  // panelReservation: {
  //   id: 'shadower.panelReservation',
  //   defaultMessage: 'Reservations',
  //   description: 'Reservations',
  // },
  transactions: {
    id: 'shadower.transactions',
    defaultMessage: 'Transactions',
    description: 'Transactions',
  },
  // notFoundTitle: {
  //   id: 'shadower.notFoundTitle',
  //   defaultMessage: 'Uh-oh!',
  //   description: 'Not Found',
  // },
  // notFoundSubTitle: {
  //   id: 'shadower.notFoundSubTitle',
  //   defaultMessage: 'We can`t seem to find the page you`re looking for!',
  //   description: 'Not Found',
  // },
  // errorCode: {
  //   id: 'shadower.errorCode',
  //   defaultMessage: 'Error code: 404',
  //   description: 'Not Found',
  // },
  // linksTitle: {
  //   id: 'shadower.linksTitle',
  //   defaultMessage: 'Here are some helpful links instead:',
  //   description: 'Not Found',
  // },
  // hostingOn: {
  //   id: 'shadower.hostingOn',
  //   defaultMessage: 'Hosting on',
  //   description: 'Not Found',
  // },
  // pageError: {
  //   id: 'shadower.pageError',
  //   defaultMessage: 'Error',
  //   description: 'Page Error',
  // },
  // pageErrorInfo: {
  //   id: 'shadower.pageErrorInfo',
  //   defaultMessage: 'Sorry, a critical error occurred on this page.',
  //   description: 'Page Error',
  // },
  // messageStatus2: {
  //   id: 'shadower.messageStatus2',
  //   defaultMessage: 'Pre Approved',
  //   description: 'Message Status',
  // },
  // messageStatus3: {
  //   id: 'shadower.messageStatus3',
  //   defaultMessage: 'Declined',
  //   description: 'Message Status',
  // },
  // messageStatus4: {
  //   id: 'shadower.messageStatus4',
  //   defaultMessage: 'Approved',
  //   description: 'Message Status',
  // },
  // messageStatus5: {
  //   id: 'shadower.messageStatus5',
  //   defaultMessage: 'Pending',
  //   description: 'Message Status',
  // },
  // messageStatus6: {
  //   id: 'shadower.messageStatus6',
  //   defaultMessage: 'Cancelled by host',
  //   description: 'Message Status',
  // },
  // messageStatus7: {
  //   id: 'shadower.messageStatus7',
  //   defaultMessage: 'Cancelled by guest',
  //   description: 'Message Status',
  // },
  // messageStatus8: {
  //   id: 'shadower.messageStatus8',
  //   defaultMessage: 'Approved',
  //   description: 'Message Status',
  // },
  // messageStatus9: {
  //   id: 'shadower.messageStatus9',
  //   defaultMessage: 'Expired',
  //   description: 'Message Status',
  // },
  // messageStatus10: {
  //   id: 'shadower.messageStatus10',
  //   defaultMessage: 'Request to book',
  //   description: 'Message Status',
  // },
  // panelHeader1: {
  //   id: 'shadower.panelHeader1',
  //   defaultMessage: 'In progress',
  //   description: 'Panel Header',
  // },
  // panelHeader2: {
  //   id: 'shadower.panelHeader2',
  //   defaultMessage: 'Completed',
  //   description: 'Panel Header',
  // },
  // messageStatus11: {
  //   id: 'shadower.messageStatus11',
  //   defaultMessage: 'Cancelled',
  //   description: 'Message Status',
  // },
  // messagesNew: {
  //   id: 'shadower.messagesNew',
  //   defaultMessage: 'New',
  //   description: 'New',
  // },
  facebookLogin: {
    id: 'shadower.facebookLogin',
    defaultMessage: 'Log in with Facebook',
    description: 'Log in with Facebook',
  },
  googleLogin: {
    id: 'shadower.googleLogin',
    defaultMessage: 'Log in with Google',
    description: 'Log in with Google',
  },
  // reviewToWrite: {
  //   id: 'shadower.reviewToWrite',
  //   defaultMessage: 'Reviews to Write',
  //   description: 'Reviews Panel',
  // },
  // cancellationNote: {
  //   id: 'shadower.cancellationNote',
  //   defaultMessage: 'A personal note can help',
  //   description: 'Cancellation Notes',
  // },
  // cancellationNoteForHost: {
  //   id: 'shadower.cancellationNoteForHost',
  //   defaultMessage: 'understand why you can`t host them',
  //   description: 'Cancellation Notes',
  // },
  // saveAndContinue: {
  //   id: 'shadower.saveAndContinue',
  //   defaultMessage: 'Save & Continue',
  //   description: 'Save & Continue',
  // },
  // saveAndExit: {
  //   id: 'shadower.saveAndExit',
  //   defaultMessage: 'Save and Exit',
  //   description: 'Save and Exit',
  // },
  // reservationCancel: {
  //   id: 'shadower.reservationCancel',
  //   defaultMessage: 'The reservation will be canceled immediatly',
  //   description: 'Cancel your trip',
  // },
  // nonRefundable: {
  //   id: 'shadower.nonRefundable',
  //   defaultMessage: 'Non-Refundable',
  //   description: 'Cancel your trip',
  // },
  // refundable: {
  //   id: 'shadower.refundable',
  //   defaultMessage: 'Refundable',
  //   description: 'Cancel your trip',
  // },
  // refundCost: {
  //   id: 'shadower.refundCost',
  //   defaultMessage: 'You will be refunded with the above cost.',
  //   description: 'Cancel your trip',
  // },
  // keepReservation: {
  //   id: 'shadower.keepReservation',
  //   defaultMessage: 'Keep reservation',
  //   description: 'Keep reservation',
  // },
  // cancelYourReservation: {
  //   id: 'shadower.cancelYourReservation',
  //   defaultMessage: 'Cancel your reservation',
  //   description: 'Cancel your reservation',
  // },
  // cleaningMinusServiceFee: {
  //   id: 'shadower.cleaningMinusServiceFee',
  //   defaultMessage: 'cleaning fees, minus service fees.',
  //   description: 'Cleaning fee and service fee',
  // },
  // earnings: {
  //   id: 'shadower.earnings',
  //   defaultMessage: 'Earnings',
  //   description: 'Earnings',
  // },
  // cleaningPlusServiceFee: {
  //   id: 'shadower.cleaningPlusServiceFee',
  //   defaultMessage: 'plus cleaning fees, plus host service fees.',
  //   description: 'plus cleaning fees, plus host service fees.',
  // },
  // willBeRefund: {
  //   id: 'shadower.willBeRefund',
  //   defaultMessage: 'will be refunded the',
  //   description: 'will be refunded the',
  // },
  // reservationCost: {
  //   id: 'shadower.reservationCost',
  //   defaultMessage: 'reservation cost.',
  //   description: 'Reservation cost',
  // },
  // cancelYourTrip: {
  //   id: 'shadower.cancelYourTrip',
  //   defaultMessage: 'Cancel your Trip',
  //   description: 'Cancel your Trip',
  // },
  // consider: {
  //   id: 'shadower.consider',
  //   defaultMessage: 'Consider',
  //   description: 'Consider',
  // },
  // tripBeforeCanceling: {
  //   id: 'shadower.tripBeforeCanceling',
  //   defaultMessage: 'trip before canceling',
  //   description: 'Trip before canceling',
  // },
  // cancellingInfo: {
  //   id: 'shadower.cancellingInfo',
  //   defaultMessage:
  //     'Cancelling a guest`s reservation can disrupt their plans and have serious implications on their tripCancelling a guest`s reservation can disrupt their plans and have serious implications on their trip',
  //   description: 'Trip before canceling',
  // },
  // started: {
  //   id: 'shadower.started',
  //   defaultMessage: 'Started',
  //   description: 'Started',
  // },
  // startIn: {
  //   id: 'shadower.startIn',
  //   defaultMessage: 'Start In',
  //   description: 'Start In',
  // },
  // travelingWith: {
  //   id: 'shadower.travelingWith',
  //   defaultMessage: 'Traveling With',
  //   description: 'Traveling With',
  // },
  // needToCancel: {
  //   id: 'shadower.needToCancel',
  //   defaultMessage: 'why you need to cancel',
  //   description: 'why you need to cancel',
  // },
  // stayingFor: {
  //   id: 'shadower.stayingFor',
  //   defaultMessage: 'Staying for',
  //   description: 'Staying for',
  // },
  // propertyLocated: {
  //   id: 'shadower.propertyLocated',
  //   defaultMessage: 'property is located in',
  //   description: 'Property is located in',
  // },
  // responseFrom: {
  //   id: 'shadower.responseFrom',
  //   defaultMessage: 'Response from',
  //   description: 'Response from',
  // },
  // savingButton: {
  //   id: 'shadower.savingButton',
  //   defaultMessage: 'Saving',
  //   description: 'Saving Button',
  // },
  // night: {
  //   id: 'shadower.night',
  //   defaultMessage: 'night',
  //   description: 'Bill Details',
  // },
  // minimumStay: {
  //   id: 'shadower.minimumStay',
  //   defaultMessage: 'minimum stay',
  //   description: 'Minimum stay',
  // },
  // maximumNightStay: {
  //   id: 'shadower.maximumNightStay',
  //   defaultMessage: 'maximum stay',
  //   description: 'Maximum stay',
  // },
  // review: {
  //   id: 'shadower.review',
  //   defaultMessage: 'Review',
  //   description: 'Review',
  // },
  // verifications: {
  //   id: 'shadower.verifications',
  //   defaultMessage: 'Verifications',
  //   description: 'Verifications',
  // },
  verification: {
    id: 'shadower.verification',
    defaultMessage: 'Verification',
  },
  shadowerCapcity: {
    id: 'shadower.shadowerCapcity',
    defaultMessage: 'shadower',
  },
  shadowersCapcity: {
    id: 'shadower.shadowersCapcity',
    defaultMessage: 'shadowers',
  },
  // monthlyDiscount: {
  //   id: 'shadower.monthlyDiscount',
  //   defaultMessage: 'monthly price discount',
  //   description: 'Monthly Discount',
  // },
  // weeklyDiscount: {
  //   id: 'shadower.weeklyDiscount',
  //   defaultMessage: 'weekly price discount',
  //   description: 'Weekly Discount',
  // },
  // flexibleCheckIn: {
  //   id: 'shadower.flexibleCheckIn',
  //   defaultMessage: 'Flexible check in time',
  //   description: 'Flexible check in time',
  // },
  // cancellationNoteForGuest: {
  //   id: 'shadower.cancellationNoteForGuest',
  //   defaultMessage: 'understand why you want to cancel your reservation',
  //   description: 'Cancellation notes fot guest',
  // },
  // howManyday: {
  //   id: 'shadower.howManyday',
  //   defaultMessage: 'day',
  //   description: 'day',
  // },
  // howManydays: {
  //   id: 'shadower.howManydays',
  //   defaultMessage: 'days',
  //   description: 'days',
  // },
  // howManyGuest: {
  //   id: 'shadower.howManyGuest',
  //   defaultMessage: 'Guest',
  //   description: 'Guest',
  // },
  // reviewFor: {
  //   id: 'shadower.reviewFor',
  //   defaultMessage: 'Review for',
  //   description: 'Review for',
  // },
  // your: {
  //   id: 'shadower.your',
  //   defaultMessage: 'Your',
  //   description: 'Your',
  // },
  // chooseCancellationPolicy: {
  //   id: 'shadower.chooseCancellationPolicy',
  //   defaultMessage: 'Cancellation Policy',
  //   description: 'Cancellation Policy',
  // },
  pendingPayouts: {
    id: 'shadower.pendingPayouts',
    defaultMessage: 'Pending Payouts',
    description: 'Pending Payouts',
  },
  allPayoutMethod: {
    id: 'shadower.allPayoutMethod',
    defaultMessage: 'All payout method',
    description: 'All payout method',
  },
  // inboxCompleted: {
  //   id: 'shadower.inboxCompleted',
  //   defaultMessage: 'Completed',
  //   description: 'Message Status - Inbox Item',
  // },
  loginConfirmation: {
    id: 'shadower.loginConfirmation',
    defaultMessage:
      'Sorry, you must login to confirm your email. Please log in and try clicking the link from your email again!',
    description: 'Login confirmation',
  },
  // upcomingReservations: {
  //   id: 'shadower.upcomingReservations',
  //   defaultMessage: 'Upcoming Reservations',
  //   description: 'Upcoming Reservations',
  // },
  // previousReservations: {
  //   id: 'shadower.previousReservations',
  //   defaultMessage: 'Previous Reservations',
  //   description: 'Previous Reservations',
  // },
  // upcomingTrips: {
  //   id: 'shadower.upcomingTrips',
  //   defaultMessage: 'Upcoming Trips',
  //   description: 'Upcoming Trips',
  // },
  // previousTrips: {
  //   id: 'shadower.previousTrips',
  //   defaultMessage: 'Previous Trips',
  //   description: 'Previous Trips',
  // },
  // noUpcomingReservation: {
  //   id: 'shadower.noUpcomingReservation',
  //   defaultMessage: 'You don`t have any upcoming reservations!',
  //   description: 'noUpcomingReservation',
  // },
  // noPreviousReservation: {
  //   id: 'shadower.noPreviousReservation',
  //   defaultMessage: 'You don`t have any previous reservations!',
  //   description: 'noPreviousReservation',
  // },
  // noUpcomingTrips: {
  //   id: 'shadower.noUpcomingTrips',
  //   defaultMessage: 'You don`t have any upcoming trips!',
  //   description: 'noUpcomingTrips',
  // },
  // noPreviousTrips: {
  //   id: 'shadower.noPreviousTrips',
  //   defaultMessage: 'You don`t have any previous trips!',
  //   description: 'noPreviousTrips',
  // },
  // saved: {
  //   id: 'shadower.saved',
  //   defaultMessage: 'Saved',
  //   description: 'saved',
  // },
  // list: {
  //   id: 'shadower.list',
  //   defaultMessage: 'List',
  //   description: 'list',
  // },
  // lists: {
  //   id: 'shadower.lists',
  //   defaultMessage: 'Lists',
  //   description: 'lists',
  // },
  // yourLists: {
  //   id: 'shadower.yourLists',
  //   defaultMessage: 'Your lists',
  //   description: 'yourLists',
  // },
  // homes: {
  //   id: 'shadower.homes',
  //   defaultMessage: 'Homes',
  //   description: 'homes',
  // },
  // nameRequired: {
  //   id: 'shadower.nameRequired',
  //   defaultMessage: 'Name is required',
  //   description: 'Name Required',
  // },
  // privacySettings: {
  //   id: 'shadower.privacySettings',
  //   defaultMessage: 'Privacy Settings',
  //   description: 'Privacy Settings',
  // },
  // yourHomes: {
  //   id: 'shadower.yourHomes',
  //   defaultMessage: 'Your Homes',
  //   description: 'hoyourHomesmes',
  // },
  // public: {
  //   id: 'shadower.public',
  //   defaultMessage: 'Public',
  //   description: 'public',
  // },
  // private: {
  //   id: 'shadower.private',
  //   defaultMessage: 'Private',
  //   description: 'private',
  // },
  // goToAllLists: {
  //   id: 'shadower.goToAllLists',
  //   defaultMessage: 'Go to All Lists',
  //   description: 'Go to All Lists',
  // },
  // confirmDelete: {
  //   id: 'shadower.confirmDelete',
  //   defaultMessage: 'Confirm Delete',
  //   description: 'confirmDelete',
  // },
  // similarListings: {
  //   id: 'shadower.similarListings',
  //   defaultMessage: 'Similar Listings',
  //   description: 'similarListings',
  // },
  // overview: {
  //   id: 'shadower.overview',
  //   defaultMessage: 'Overview',
  //   description: 'Overview',
  // },
  // theHost: {
  //   id: 'shadower.theHost',
  //   defaultMessage: 'The Host',
  //   description: 'The Host',
  // },
  //
  // // ID verification
  //
  // documentverificaiton: {
  //   id: 'shadower.documentverificaiton',
  //   defaultMessage: 'Document Verification',
  //   description: 'documentverificaiton',
  // },
  // verificationdocument: {
  //   id: 'shadower.verificationdocument',
  //   defaultMessage: 'Document Verified',
  //   description: 'verificationdocument',
  // },
  // documentverificaitonDetails: {
  //   id: 'shadower.documentverificaitonDetails',
  //   defaultMessage: 'Your document has been verified',
  //   description: 'documentverificaitonDetails',
  // },
  // documentApproveRejectMessage: {
  //   id: 'shadower.documentApproveRejectMessage',
  //   defaultMessage: 'Type Your Message',
  //   description: 'documentApproveRejectMessage',
  // },
  // documentApprove: {
  //   id: 'shadower.documentApprove',
  //   defaultMessage: 'Approve',
  //   description: 'documentApprove',
  // },
  // documentReject: {
  //   id: 'shadower.documentReject',
  //   defaultMessage: 'Reject',
  //   description: 'documentReject',
  // },
  // documentAppRejRequried: {
  //   id: 'shadower.documentAppRejRequried',
  //   defaultMessage: 'Please Fill Required',
  //   description: 'documentAppRejRequried',
  // },
  // documentUploadPlaceholder: {
  //   id: 'shadower.documentUploadPlaceholder',
  //   defaultMessage: 'Click here to upload document or drag them in',
  //   description: 'Document Upload Placeholder',
  // },
  // documentVerificaitonInfo: {
  //   id: 'shadower.documentVerificaitonInfo',
  //   defaultMessage:
  //     'Please upload your documents for ID verification. Eg: Passport, Driving License',
  //   description: 'Document Verification Info',
  // },
  // searchAsIMove: {
  //   id: 'shadower.searchAsIMove',
  //   defaultMessage: 'Search as I move the map',
  //   description: 'Search as I move the map',
  // },
  // blankSpace: {
  //   id: 'shadower.blankSpace',
  //   defaultMessage: 'Input is Blank',
  //   description: 'blankSpace',
  // },
  // ContactMessage: {
  //   id: 'shadower.ContactMessage',
  //   defaultMessage: 'Message',
  //   description: 'ContactMessage',
  // },
  // contactForm: {
  //   id: 'shadower.contactForm',
  //   defaultMessage: 'Contact Us',
  //   description: 'contactForm',
  // },
  // Nameincontact: {
  //   id: 'shadower.Nameincontact',
  //   defaultMessage: 'Name',
  //   description: 'Nameincontact',
  // },
  // recaptchaRequired: {
  //   id: 'shadower.recaptchaRequired',
  //   defaultMessage: 'Recaptcha Required',
  //   description: 'recaptchaRequired',
  // },
  // sendmail: {
  //   id: 'shadower.sendmail',
  //   defaultMessage: 'Send',
  //   description: 'Send',
  // },
  // nameYourList: {
  //   id: 'shadower.nameYourList',
  //   defaultMessage: 'Name your list',
  //   description: 'List Name',
  // },
  // flexibleCheckOut: {
  //   id: 'shadower.flexibleCheckOut',
  //   defaultMessage: 'Flexible check out time',
  //   description: 'Flexible check out time',
  // },
  // helpFirstTitle: {
  //   id: 'shadower.helpFirstTitle',
  //   defaultMessage: 'Create your listing',
  //   description: 'Title',
  // },
  // helpSecondTitle: {
  //   id: 'shadower.helpSecondTitle',
  //   defaultMessage: 'Welcome your guests',
  //   description: 'Title',
  // },
  // helpThirdTitle: {
  //   id: 'shadower.helpThirdTitle',
  //   defaultMessage: 'Getting paid safely',
  //   description: 'Title',
  // },
  // helpFirstDesc1: {
  //   id: 'shadower.helpFirstDesc1',
  //   defaultMessage:
  //     'Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s.',
  //   description: 'Description',
  // },
  // helpFirstDesc2: {
  //   id: 'shadower.helpFirstDesc2',
  //   defaultMessage:
  //     'Maecenas non scelerisque purus, congue cursus arcu. Donec vel dapibus mi. Mauris maximus posuere placerat. Sed et libero eu nibh tristique mollis a eget lectus',
  //   description: 'Description',
  // },
  // helpSecondDesc1: {
  //   id: 'shadower.helpSecondDesc1',
  //   defaultMessage:
  //     'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
  //   description: 'Description',
  // },
  // helpSecondDesc2: {
  //   id: 'shadower.helpSecondDesc2',
  //   defaultMessage:
  //     'Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. ',
  //   description: 'Description',
  // },
  // helpSecondDesc3: {
  //   id: 'shadower.helpSecondDesc3',
  //   defaultMessage:
  //     'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
  //   description: 'Description',
  // },
  // helpThirdDesc1: {
  //   id: 'shadower.helpThirdDesc1',
  //   defaultMessage:
  //     ' Morbi pellentesque venenatis felis sed convallis. Nulla varius, nibh vitae placerat tempus, mauris sem elementum ipsum. ',
  //   description: 'Description',
  // },
  // helpThirdDesc2: {
  //   id: 'shadower.helpThirdDesc2',
  //   defaultMessage:
  //     'Fusce quis varius nulla. Nullam nisi nisi, suscipit ut magna quis, feugiat porta nibh. ',
  //   description: 'Description',
  // },
  // helpThirdDesc3: {
  //   id: 'shadower.helpThirdDesc3',
  //   defaultMessage:
  //     'Pellentesque sagittis vehicula sem quis luctus. Proin sodales magna in lorem hendrerit aliquam. Integer eu varius orci.',
  //   description: 'Description',
  // },
  // processFlowHeading: {
  //   id: 'shadower.processFlowHeading',
  //   defaultMessage: 'Earn Extra Money and Become a Host with Rentall',
  //   description: 'Process Flow Heading',
  // },
  // processFlowSubHeading: {
  //   id: 'shadower.processFlowSubHeading',
  //   defaultMessage:
  //     'Explorez le site à la recherche du photographe idéal et profitez des bons moments en toute sereinité',
  //   description: 'Process Flow Heading',
  // },
  // stepOneHeading: {
  //   id: 'shadower.stepOneHeading',
  //   defaultMessage: 'Why host with Rentall?',
  //   description: 'Step 1',
  // },
  // stepTwoHeading: {
  //   id: 'shadower.stepTwoHeading',
  //   defaultMessage: 'Stay in Control',
  //   description: 'Step 2',
  // },
  // stepThreeHeading: {
  //   id: 'shadower.stepThreeHeading',
  //   defaultMessage: 'Stay Confident',
  //   description: 'Step 3',
  // },
  // stepOneFlow: {
  //   id: 'shadower.stepOneFlow',
  //   defaultMessage:
  //     'Share your villa, condo or apartment and earn yourself an extra income. With over 12 years of experience, we make it simple and safe to reach guests that are looking for fun and unique Places to stay across Thailand and Bali.',
  //   description: 'Step 1',
  // },
  // stepTwoFlow: {
  //   id: 'shadower.stepTwoFlow',
  //   defaultMessage:
  //     'Stay in control with Rentall. You have full control of your availability, rates and rules. You can, block dates, set check-in times and manage your own communications directly with your guests through the system.',
  //   description: 'Step 2',
  // },
  // stepThreeFlow: {
  //   id: 'shadower.stepThreeFlow',
  //   defaultMessage:
  //     'We offer all the right tools to allow you to host in confidence. From a flexible booking system through to a secure payment system. Stay connected and confident with the latest technology.',
  //   description: 'Step 3',
  // },
  // anonymousMessage: {
  //   id: 'shadower.anonymousMessage',
  //   defaultMessage: 'Do you want to report this User?',
  //   description: 'Report User',
  // },
  // reportUserInfo: {
  //   id: 'shadower.reportUserInfo',
  //   defaultMessage: 'If so, please choose one of the following reasons.',
  //   description: 'Report User',
  // },
  // reportContent1: {
  //   id: 'shadower.reportContent1',
  //   defaultMessage: 'This profile should not be on',
  //   description: 'Report User',
  // },
  // reportContent2: {
  //   id: 'shadower.reportContent2',
  //   defaultMessage: 'Attempt to share contact information',
  //   description: 'Report User',
  // },
  // close: {
  //   id: 'shadower.close',
  //   defaultMessage: 'Close',
  //   description: 'Report User',
  // },
  // reportContent3: {
  //   id: 'shadower.reportContent3',
  //   defaultMessage: 'Inappropriate content of spam',
  //   description: 'Report User',
  // },
  // thankyouInfo1: {
  //   id: 'shadower.thankyouInfo1',
  //   defaultMessage:
  //     'Thanks for taking the time to report this user. These reports help make better for everyone, so we take them seriously. We will reach out if we have questions about your report.',
  //   description: 'Report User',
  // },
  // thankyouTitle: {
  //   id: 'shadower.thankyouTitle',
  //   defaultMessage: 'Thank You',
  //   description: 'Report User',
  // },
  // messageHistroyLabel: {
  //   id: 'shadower.messageHistroyLabel',
  //   defaultMessage: 'View',
  //   description: 'Report User',
  // },
  // titleRequiredLabel: {
  //   id: 'shadower.titleRequiredLabel',
  //   defaultMessage: 'Message History',
  //   description: 'Report User',
  // },
  // topBannerTitle: {
  //   id: 'shadower.topBannerTitle',
  //   defaultMessage: 'Its simple to become a RentALL host',
  //   description: 'Report User',
  // },
  // footerTerms: {
  //   id: 'shadower.footerTerms',
  //   defaultMessage: 'Terms, Privacy, Currency & More',
  //   description: 'Footer Button',
  // },
  // footerClose: {
  //   id: 'shadower.footerClose',
  //   defaultMessage: 'Close',
  //   description: 'Footer Button',
  // },
});

export default messages;
