/*
The built-in `JSON.stringify` throws on circular references.  There are many NPM
packages that provide a safe alternative.  When building an application, one can
choose one package and use it throughout the code base.  However, if you decide
to switch to a different package, then you may have dozens or even hundres of
places to update.  But this can be avoided by centralizing the package choice
here in this module.

It is recommended to export an object following the same structure with the same
signature as the built-in `JSON` object. This will make usage of this module as
simple as possible for those already familiar with the built-in `JSON`.
*/
import stringify from 'fast-safe-stringify';

const Json = {
  parse: (input, reviver, options) => {
    let output;
    try {
      output = JSON.parse(input, reviver);
    } catch (e) {
      if (options && options.logError) {
        console.error(e);
      }

      // Resolve default value:
      let def;
      if (
        typeof options === 'object' &&
        options !== null &&
        Object.prototype.hasOwnProperty.call(options, 'def')
      ) {
        def = options.def;
      } else {
        def = options;
      }
      if (def === undefined) {
        def = input;
      }
      output = def;
    }
    return output;
  },
  stringify,
};

export default Json;
