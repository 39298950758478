import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import FormUtils from 'qc-dom_utils/lib/cjs/form';
import ReduxFormUtils from 'qc-redux-form_utils/lib/cjs';
import React from 'react';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import { injectIntl, intlShape } from 'react-intl';
import { Field, propTypes, reduxForm, SubmissionError } from 'redux-form';

import I18nErrorBox from '../../components/I18nErrorBox';

import CircleLoader from '../../components/Loader/CircleLoader';

import messages from '../../locale/messages';

import { login } from '../../state/actions/AnonymousUserActions';

import ShadowerButton from '../ShadowerButton';

import validate from './validate';

import s from './LoginForm.scss';

class LoginForm extends React.PureComponent {
  static propTypes = {
    ...propTypes,
    error: PTs.shape({
      id: PTs.string,
    }),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  // constructor(props) {
  //   console.log('LoginForm#constructor');
  //   super(props);
  // }

  // componentDidUpdate() {
  //   console.log('LoginForm#componentDidUpdate');
  // }

  // componentWillUnmount() {
  //   console.log('LoginForm#componentWillUnmount');
  // }

  renderFormControl = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
  }) => {
    const { intl } = this.props;
    return (
      <div>
        {touched && error && (
          <span className={s.errorMessage}>{intl.formatMessage(error)}</span>
        )}
        <FormControl
          {...input}
          className={className}
          placeholder={label}
          type={type}
        />
      </div>
    );
  };

  render() {
    // console.log('LoginForm#render');
    const {
      form: formName,
      error,
      handleSubmit,
      intl,
      submitting,
    } = this.props;

    return (
      <form name={formName} onSubmit={handleSubmit}>
        {error && <I18nErrorBox message={error} />}

        {submitting && (
          <CircleLoader
            className="pull-right"
            show
            size={16}
            style={{ margin: '8px' }}
          />
        )}

        <FormGroup className={s.formGroup}>
          <Field
            className={s.formControlInput}
            component={this.renderFormControl}
            label={intl.formatMessage(messages.email)}
            name="email"
            type="text"
          />
        </FormGroup>

        <FormGroup className={s.formGroup}>
          <Field
            className={s.formControlInput}
            component={this.renderFormControl}
            label={intl.formatMessage(messages.password)}
            name="password"
            type="password"
          />
        </FormGroup>

        <div className={s.logInContainer}>
          <ShadowerButton disabled={submitting} type="submit">
            {intl.formatMessage(messages.login)}
          </ShadowerButton>
        </div>
      </form>
    );
  }
}

const FORM_NAME = 'LoginForm';

const ReduxFormLoginForm = reduxForm({
  form: FORM_NAME,
  onSubmit: async (values, dispatch, props) => {
    // console.log('LoginForm#onSubmit');
    let result = {
      status: 'invalid',
    };
    const { dirty } = props;

    if (dirty) {
      try {
        result = await dispatch(login(values.email, values.password));
      } catch (e) {
        console.error(e);
        throw new SubmissionError({ _error: messages.somethingWentWrong });
      }
      if (result.status === 'invalid') {
        throw new SubmissionError({ _error: messages.loginCredentialsInvalid });
      }
    }

    return result;
  },
  onSubmitFail: (error, dispatch_unused, submitError_unused, props_unused) => {
    // console.log('LoginForm#onSubmitFail');
    // console.log('error:', error);
    FormUtils.focusFirstInvalid(FORM_NAME, ReduxFormUtils.flattenErrors(error));
  },
  touchOnBlur: false,
  // touchOnChange: true,
  validate,
})(LoginForm);

export default injectIntl(withStyles(s)(ReduxFormLoginForm));
