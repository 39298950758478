import PTs from 'prop-types';
import React from 'react';
import Alert from 'react-bootstrap/lib/Alert';

/**
 * Renders a localized error message.
 */
function ErrorBox(props) {
  /*
  Implementation Note: We are using a `react-bootstrap` `Alert` component to
  implement this error box. But this can be changed to whatever works best.
  */
  let { message } = props;

  if (message instanceof Error) {
    message = message.message;
  }
  return <Alert bsStyle="danger">{message}</Alert>;
}

ErrorBox.propTypes = {
  message: PTs.oneOfType([PTs.instanceOf(Error), PTs.node]).isRequired,
};

export default ErrorBox;
