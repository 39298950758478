import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import FormUtils from 'qc-dom_utils/lib/cjs/form';
import ReduxFormUtils from 'qc-redux-form_utils/lib/cjs';
import React from 'react';
import { injectIntl /* , intlShape*/ } from 'react-intl';
import { propTypes, reduxForm, SubmissionError } from 'redux-form';

import ErrorBox from '../../components/ErrorBox';

import FieldGroup from '../../components/Form/FieldGroup';
import ShadowerButton from '../../components/ShadowerButton';

import messages from '../../locale/messages';

import { sendForgotPasswordEmail } from '../../state/actions/AnonymousUserActions';

import s from './ResetPasswordRequestForm.scss';

class ResetPasswordRequestForm extends React.PureComponent {
  static propTypes = {
    ...propTypes,
    error: PTs.shape({
      defaultMessage: PTs.string,
      id: PTs.string,
    }),
    handleSubmit: PTs.func.isRequired,
    // intl: intlShape.isRequired,
    submitting: PTs.bool,
  };

  static defaultProps = {
    error: null,
    submitting: false,
  };

  // constructor(props) {
  //   console.log('ResetPasswordRequestForm#constructor');
  //   super(props);
  // }

  // componentDidUpdate() {
  //   console.log('ResetPasswordRequestForm#componentDidUpdate');
  // }

  // componentWillUnmount() {
  //   console.log('ResetPasswordRequestForm#componentWillUnmount');
  // }

  renderGlobalErrors() {
    const { error } = this.props;
    return error && <ErrorBox key={error} message={error} />;
  }

  render() {
    // console.log('ResetPasswordRequestForm#render');
    const { form: formName, handleSubmit, submitting } = this.props;

    return (
      <form name={formName} onSubmit={handleSubmit}>
        {this.renderGlobalErrors()}
        <FieldGroup
          name="email"
          placeholder="Email address"
          // label={intl.formatMessage(messages.email)}
          required
          type="email"
        />
        <ShadowerButton
          className={s.resetButton}
          disabled={submitting}
          type="submit"
        >
          Send email
        </ShadowerButton>
      </form>
    );
  }
}

const FORM_NAME = 'ResetPasswordRequestForm';

const RF = reduxForm({
  form: FORM_NAME,
  /**
   * @returns {Object} result
   * @property {Object} resetPasswordRequest
   * @property {string} resetPasswordRequest.email
   * @property {'pristine'|'success'|'unregistered'} status
   */
  onSubmit: async (values, dispatch, props) => {
    let resetPasswordRequest = null;
    let st = 'pristine';
    const { dirty } = props;

    if (dirty) {
      const { email } = values;

      resetPasswordRequest = { email };
      const result = await dispatch(
        sendForgotPasswordEmail(resetPasswordRequest),
      );
      const { errors, status } = result;
      if (Array.isArray(errors)) {
        errors.forEach(error => console.error(error.message));
      }
      if (status === 'failed') {
        throw new SubmissionError({ _error: messages.somethingWentWrong });
      }
      st = status;
    }
    return { resetPasswordRequest, status: st };
  },
  onSubmitFail: (errors, dispatch_unused, submitError_unused, props_unused) => {
    FormUtils.focusFirstInvalid(
      FORM_NAME,
      ReduxFormUtils.flattenErrors(errors),
    );
  },
  validate: (values_unused, props_unused) => {
    // Nothing special to validate.
  },
})(ResetPasswordRequestForm);

export default injectIntl(withStyles(s)(RF));
