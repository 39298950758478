import PTs from 'prop-types';
import React from 'react';
import Alert from 'react-bootstrap/lib/Alert';

/**
 * Renders a localized warning message.
 */
function WarningBox(props) {
  /*
  Implementation Note: We are using a `react-bootstrap` `Alert` component to
  implement this warning box. But this can be changed to whatever works best.
  */
  return <Alert bsStyle="warning">{props.children}</Alert>;
}

WarningBox.propTypes = {
  children: PTs.node.isRequired,
};

export default WarningBox;
