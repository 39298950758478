// ============================================================================
//  _   _  ___ _____ _____
// | \ | |/ _ \_   _| ____|
// |  \| | | | || | |  _|
// | |\  | |_| || | | |___
// |_| \_|\___/ |_| |_____|
//
// No secret information should be imported/set here. We have to make sure secret
// information is not accidentally leaked to the client.
//
// In order for this module, which runs on the client side, to recognize the
// `process.env` variables, the webpack configuration for the client needs to be
// updated to include the variables used here. These are set with the DefinePlugin
// plugin.
// ============================================================================

// The port the Express web server runs under.
const port = process.env.PORT;

const appRoot = '/';
const assetsRoot = '/assets/';

// const api = {
//   graphql: {},
//   rest: {},
// };

const app = {
  /**
   * The assets root path of the URL. This is where the assets for this web app
   * will be served from. Must always end with a slash.
   *
   * Typically, this path will be a sub-path of the `app.root`.
   */
  assetsRoot,
  info: {
    name: process.env.APP__NAME,
  },
  /**
   * The app root path of the URL. This is where this web app will be served from.
   * It allows this web app to be mounted at a different location in the URL. Must
   * always end with a slash.
   *
   * Why always end with a slash? To match the behavior of `Location#pathname` such
   * as in `window.location.pathname` and `window.document.location.pathname`.
   */
  root: appRoot,

  /**
   * The URL origin is intended to be used where the absolute URL with origin info is
   * needed. URLs in emails is one such place where a complete URL is needed.
   *
   * The `urlOrigin` must never contain a path, meaning is must never end with a
   * single `/` or any other path information. Append other variables or hard-coded
   * values for the path part of the URL.
   */
  urlOrigin: process.env.APP__URL_ORIGIN || `http://localhost:${port}`,
  userProfilePhotoRoot: `${assetsRoot}images/users/profile/photo/`,
};

const thirdParty = {
  braintree: {},
  google: {
    analytics: {},
    maps: {
      apiKey: process.env.GOOGLE_MAPS__API_KEY,
    },
    tagManager: {},
  },
  paypal: {
    clientId: process.env.PAYPAL__CLIENT_ID,
    mode: process.env.PAYPAL__MODE || 'sandbox', // One of 'live' | 'sandbox'.
  },
  stripe: {
    apiKeyPublishable: process.env.STRIPE_API_KEY_PUBLISHABLE,
  },
};

export {
  // api,
  app,
  thirdParty,
};

export default {
  // api,
  app,
  thirdParty,
};
