import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';

import Link from '../../../universal/components/Link';

import s from './ShadowerLink.scss';

const CLASSNAME_LUT = {
  default: s.defaultLook,
  inverse: s.inverseLook,
  outline: s.outlineLook,
  cancel: s.cancelLook,
};

class ShadowerLink extends React.Component {
  static propTypes = {
    children: PTs.node.isRequired,
    className: PTs.string,
    look: PTs.oneOf(['default', 'inverse', 'outline', 'cancel']),
    to: PTs.string.isRequired,
  };

  static defaultProps = {
    className: '',
    look: 'default',
  };

  render() {
    const { children, className, look, to } = this.props;
    return (
      <Link className={cx(className, CLASSNAME_LUT[look])} to={to}>
        {children}
      </Link>
    );
  }
}

export default withStyles(s)(ShadowerLink);
